import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import MuiToolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { ExpandMore, AccountCircle } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import colors from 'themes/colors';
import { ref as dbRef, set, getDatabase } from "firebase/database";

import Auto100Logo from "assets/Logo.png";
import MarketAnalysisImage from "assets/svg/market-analysis";
import CompaniesImage from "assets/svg/companies";
import StockPerformanceImage from "assets/svg/stock-performance"
import { Button, Icon, Stack, TextField, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { User } from 'firebase/auth';
import Autocomplete from './Autocomplete';
import { AUTO100Ctx } from 'AUTO100DataProvider';
import { useContext } from 'react';
import Dropdown from './Dropdown';


const MarketAnalysisIcon = (
  <MarketAnalysisImage fill="#000" />
);
const CompaniesIcon = (
  <CompaniesImage fill='#fff' />
);
const StockPerformanceIcon = (
  <StockPerformanceImage />
);


const Toolbar = ({user}: {user?: User | null}) => {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const db = getDatabase();

  const location = useLocation();
  const navigate = useNavigate();
  const {
    analytics: {
      categories,
      regions,
      timeFrame,
      userPreference: {
        stockPerformanceComparisonPreferences,
        setStockPerformanceComparisonPreferences,
        universeConstituentsPreferences,
        setUniverseConstituentsPreferences,
        deepdivePreferences,
        setDeepdivePreferences,
      },
    } = {
      categories: [],
      regions: [],
      timeFrame: [],
      userPreference: {
        stockPerformanceComparisonPreferences: {},
        setStockPerformanceComparisonPreferences: () => {},
        universeConstituentsPreferences: {},
        setUniverseConstituentsPreferences: () => {},
        deepdivePreferences: {},
        setDeepdivePreferences: () => {}
      }
    },
  } = useContext(AUTO100Ctx);

  const currentPath = React.useMemo(() => {
    const { pathname } = location
    return pathname.split("/").pop()
  }, [location])

  const renderMenuButtons = () => {
    const buttons = [
      {path: "company-deepdive", label: "Market Analysis", icon: (fill?: string) => <MarketAnalysisImage fill={fill} />}, 
      {path: "universe-constituents", label: "Companies", icon: (fill?: string) => <CompaniesImage fill={fill} />},
      {path: "stock-performance-comparison", label: "Stock Performance", icon: (fill?: string) => <StockPerformanceImage fill={fill} />}
    ]

    return buttons.map((button, index) => (
      <Stack>
        <Button 
          component="label" 
          // variant={currentPath === button.path ? "contained" : undefined }
          startIcon={button.icon(currentPath === button.path ? "#000" : '#64758B')} 
          style={{ 
            minWidth: "140px", 
            backgroundColor: currentPath === button.path ? "#fff" : undefined,
            color: currentPath !== button.path ? '#94A3B8' : '#000',
            border: currentPath !== button.path ? '1px solid #F1F5F9' : undefined,
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.14px',
            textTransform: 'capitalize',
            borderRadius: "12px",
            marginRight: index != buttons.length - 1 ? "16px" : undefined
          }}
          onClick={() => navigate(`/playground/${button.path}`)}
        >
          {button.label}
        </Button>
      </Stack>
    ))

  }

  const getCategoriesAsValues = React.useMemo(() => {
    if( currentPath === 'company-deepdive' ){
      return (deepdivePreferences as {segment: Array<string>})?.segment 
    }
    if( currentPath === 'stock-performance-comparison' ){
      return (stockPerformanceComparisonPreferences as {segment: Array<string>})?.segment 
    }
    if( currentPath === 'universe-constituents' ){
      return (universeConstituentsPreferences as {segment: Array<string>})?.segment 
    }
    return []
  }, [stockPerformanceComparisonPreferences, deepdivePreferences, universeConstituentsPreferences, currentPath])
  const getCategoriesAsOptions = React.useMemo(() => {
    return categories.map((category) => ({id: category.id, name: category.name}))
  }, [categories])

  const getRegionsAsValues = React.useMemo(() => {
    if( currentPath === 'company-deepdive' ){
      return (deepdivePreferences as {region: Array<string>})?.region 
    }
    if( currentPath === 'stock-performance-comparison' ){
      return (stockPerformanceComparisonPreferences as {region: Array<string>})?.region 
    }
    if( currentPath === 'universe-constituents' ){
      return (universeConstituentsPreferences as {region: Array<string>})?.region 
    }
    return []
  }, [stockPerformanceComparisonPreferences, deepdivePreferences, universeConstituentsPreferences, currentPath])
  const getRegionsAsOptions = React.useMemo(() => {
    return regions.map((region) => ({id: region.id, name: region.name}))
  }, [regions])

  const getTimeFrameAsOptions = React.useMemo(() => {
    return timeFrame.map((quarter, index) => {
      let name = quarter.replace("_", " ")
      // if( index === 0 ) name += ` (Current Quarter)`
      if( index === 0 ) name += ` (Last Quarter)`
      return {
        id: quarter, 
        name
      }
    })
  }, [regions])

  const getStockPerformanceTimeFrameOptions = React.useMemo(() => {
    return [{id: "180", name: "6 Months"}, {id: "90", name: "3 Months"}, {id: "60", name: "2 Months"}, {id: "30", name: "30 Days"}]
  }, [])
  const getTimeFrameAsValue = React.useMemo(() => {
    return (universeConstituentsPreferences as {timeFrame: string})?.timeFrame 
  }, [universeConstituentsPreferences])

  const getStockPerformanceTimeFrameAsValue = React.useMemo(() => {
    return (stockPerformanceComparisonPreferences as {timeFrame: string})?.timeFrame 
  }, [stockPerformanceComparisonPreferences])
  const getStockPerformanceFinancialQuarterAsValue = React.useMemo(() => {
    return (stockPerformanceComparisonPreferences as {financialQuarter: string})?.financialQuarter 
  }, [stockPerformanceComparisonPreferences])

  const handleRegionsChange = async (_regions: Array<string>) => {
    if( !user ) return

    let dbPath = `AUTO100/user_preferences/${user.uid}/analytics/`
    if( currentPath === 'company-deepdive' ) dbPath += `deepdivePreference`
    if( currentPath === 'stock-performance-comparison' ) dbPath += `stockPerformanceComparisonPreferences`
    if( currentPath === 'universe-constituents' ) dbPath += `universeConstituentsPreferences`
    
    const userPreferenceRef = dbRef(db, `${dbPath}/region`);
    await set(userPreferenceRef, _regions);
    if( currentPath === 'company-deepdive' ){
      setDeepdivePreferences({
        ...(deepdivePreferences ?? {}),
        region: _regions,
      });
    }
    if( currentPath === 'stock-performance-comparison' ){
      setStockPerformanceComparisonPreferences({
        ...(stockPerformanceComparisonPreferences ?? {}),
        region: _regions,
      });
    }
    if( currentPath === 'universe-constituents' ){
      setUniverseConstituentsPreferences({
        ...(universeConstituentsPreferences ?? {}),
        region: _regions,
      });
    }
  };
  const handleCategoriesChange = async (_categories: Array<string>) => {
    if( !user ) return

    let dbPath = `AUTO100/user_preferences/${user.uid}/analytics/`
    if( currentPath === 'company-deepdive' ) dbPath += `deepdivePreference`
    if( currentPath === 'stock-performance-comparison' ) dbPath += `stockPerformanceComparisonPreferences`
    if( currentPath === 'universe-constituents' ) dbPath += `universeConstituentsPreferences`
    
    const userPreferenceRef = dbRef(db, `${dbPath}/segment`);
    await set(userPreferenceRef, _categories);

    if( currentPath === 'company-deepdive' ){
      setDeepdivePreferences({
        ...(deepdivePreferences ?? {}),
        segment: _categories,
      });
    }
    if( currentPath === 'stock-performance-comparison' ){
      setStockPerformanceComparisonPreferences({
        ...(stockPerformanceComparisonPreferences ?? {}),
        segment: _categories,
      });
    }
    if( currentPath === 'universe-constituents' ){
      setUniverseConstituentsPreferences({
        ...(universeConstituentsPreferences ?? {}),
        segment: _categories,
      });
    }
  };
  const handleTimeFrameChange = async (timeFrame: string) => {
    if( !user ) return
    if( currentPath === 'universe-constituents' ){
      let dbPath = `AUTO100/user_preferences/${user.uid}/analytics/`
      dbPath += `universeConstituentsPreferences`

      const userPreferenceRef = dbRef(db, `${dbPath}/timeFrame`);
      await set(userPreferenceRef, timeFrame);

      setUniverseConstituentsPreferences({
        ...(universeConstituentsPreferences ?? {}),
        timeFrame: timeFrame,
      });
    }
  };

  const handleStockPerformanceTimeFrameChange = async (timeFrame: string) => {
    if( !user ) return
    if( currentPath === 'stock-performance-comparison' ){
      let dbPath = `AUTO100/user_preferences/${user.uid}/analytics/`
      dbPath += `stockPerformanceComparisonPreferences`

      const userPreferenceRef = dbRef(db, `${dbPath}/timeFrame`);
      await set(userPreferenceRef, timeFrame);

      setStockPerformanceComparisonPreferences({
        ...(stockPerformanceComparisonPreferences ?? {}),
        timeFrame: timeFrame,
      });
    }
  };
  const handleStockPerformanceFinancialQuartetChange = async (financialQuarter: string) => {
    if( !user ) return
    if( currentPath === 'stock-performance-comparison' ){
      let dbPath = `AUTO100/user_preferences/${user.uid}/analytics/`
      dbPath += `stockPerformanceComparisonPreferences`

      const userPreferenceRef = dbRef(db, `${dbPath}/financialQuarter`);
      await set(userPreferenceRef, financialQuarter);

      setStockPerformanceComparisonPreferences({
        ...(stockPerformanceComparisonPreferences ?? {}),
        financialQuarter: financialQuarter,
      });
    }
  };

  return (
    <Box sx={{ flexGrow: 1, top: 200, position: "absolute" }}>
      <AppBar position="fixed" style={{ backgroundColor: colors.primaryWhite, top: 63 }}>
        <MuiToolbar>
          <div style={{ flexGrow: 1, color: "#1E293B", display: "flex", gap: 25 }}>
            <Stack sx={{ flexDirection: "row", background: "#F1F5F9", borderRadius: "12px",p: "3px", pr: "16px" , alignItems: "center" }} >
              {renderMenuButtons()}
            </Stack>
          </div>
          <div>
            <Autocomplete key={`autocomplete-category-${currentPath}`} label="Category" options={getCategoriesAsOptions} values={getCategoriesAsValues ?? []} onChange={handleCategoriesChange} />
          </div>
          <div>
            <Autocomplete key={`autocomplete-region-${currentPath}`} label="Regions" options={getRegionsAsOptions} values={getRegionsAsValues ?? []} onChange={handleRegionsChange} />
          </div>
          {
            currentPath === "universe-constituents" &&
            (<div>
              <Dropdown key={`autocomplete-region-${currentPath}`} label="Time Frame" options={getTimeFrameAsOptions} value={getTimeFrameAsValue ?? ''} onChange={handleTimeFrameChange} />
            </div>)
          }
          {
            currentPath === "stock-performance-comparison" &&
            (<div>
              <Dropdown key={`autocomplete-region-${currentPath}`} label="Time Frame" options={getStockPerformanceTimeFrameOptions} value={getStockPerformanceTimeFrameAsValue ?? ''} onChange={handleStockPerformanceTimeFrameChange} />
            </div>)
          }
          {
            currentPath === "stock-performance-comparison" &&
            (<div>
              <Dropdown key={`autocomplete-region-${currentPath}`} label="Financial Quarter" options={getTimeFrameAsOptions} value={getStockPerformanceFinancialQuarterAsValue ?? ''} onChange={handleStockPerformanceFinancialQuartetChange} />
            </div>)
          }
        </MuiToolbar>
      </AppBar>
    </Box>
  );
}

export default Toolbar