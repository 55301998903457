import { Stack, styled, Typography } from '@mui/material';

const StyledPageSection = styled(Stack)({
  position: 'relative',
  flex: 1,
  borderRadius: '15px',
  background: 'white',
  filter: 'drop-shadow(0px 0px 5px #ECECEC)',
  padding: '20px',
});

interface IPageSection {
  title: string;
  children?: JSX.Element | 0;
}
const PageSection = ({ title, children }: IPageSection) => {
  return (
    <StyledPageSection>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {children}
    </StyledPageSection>
  );
};

export default PageSection;
