import axios from "axios";
import { FIREBASE_API_URL, API_URL } from "./config";
import { headerConfig } from "./Firebase";

import { SystemFirebaseUser } from "./ifaces";
import { RebalancingOption } from "AUTO100DataProvider";
import { FinancialInformation, MarketCapital, TopGainersOrLosers } from "types";

// ADMIN ENDPOINTS

type ResponseError = {error?: string}

export const getAllUsers = async (): Promise<SystemFirebaseUser[]> => {
  const res = await axios
    .get(`${FIREBASE_API_URL}/admin/users`, await headerConfig())
    .catch((e) => e.response);
  return res?.data as SystemFirebaseUser[];
};

type DownloadRawFilesPayload = {
  data?: {
    message: string
  }
} & ResponseError
export const downloadRawFiles = async ({ year, quarter, rebalancing_option }: {year: number, quarter: number, rebalancing_option: RebalancingOption}): Promise<DownloadRawFilesPayload> => {
  const res = await axios
    .get(`${FIREBASE_API_URL}/AUTO100/raw-files/download?year=${year}&quarter=${quarter}&rebalancing_option=${rebalancing_option}`, await headerConfig())
    .catch((e) => e.response);
  if( res.status === 200  || res.status === 201 ){
    return {data: res?.data};
  }else {
    return {error: res?.data?.error}
  }
};

export const updateAnalyticsScore = async (data: {year: number, quarter: number, rebalancing_option: RebalancingOption}): Promise<Blob> => {
  const res = await axios
    .post(`${FIREBASE_API_URL}/AUTO100/analytics/update-scores`, data, await headerConfig())
    .catch((e) => e.response);
  return res?.data;
};

type MarketCapitalPayload = {
  data?: {
    [key: string]: {
      color: string
      market_capital: Array<MarketCapital>
      name: string
    }
  }
} & ResponseError
export const getMarketCapital = async (data: {start_date: string, end_date: string, isins: Array<string>, regions: Array<string>, categories: Array<string>}): Promise<MarketCapitalPayload> => {
  const {start_date, end_date, isins, regions, categories} = data
  const isinString = isins.join(",")
  const regionsString = regions.join(",")
  const categoriesString = categories.join(",")
  const res = await axios
    .get(`${FIREBASE_API_URL}/AUTO100/stock-price-market-capital?start_date=${start_date}&end_date=${end_date}&isins=${isinString}&regions=${regionsString}&categories=${categoriesString}`, await headerConfig())
    .catch((e) => e.response);
  if( res.status === 200  || res.status === 201 ){
    return {data: res?.data};
  }else {
    return {error: res?.data?.error}
  }
};


type FinancialInformationPayload = {data?: Array<FinancialInformation>} & ResponseError
export const getFinancialInformation = async (data: {quarter: string, year: string, isins: Array<string>}): Promise<FinancialInformationPayload> => {
  const {quarter, year, isins} = data
  const isinString = isins.join(",")
  const res = await axios
    .get(`${FIREBASE_API_URL}/AUTO100/financial-information?quarter=${quarter}&year=${year}&isins=${isinString}`, await headerConfig())
    .catch((e) => e.response);
  if( res.status === 200  || res.status === 201 ){
    return {data: res?.data};
  }else {
    return {error: res?.data?.error}
  }
};
type TopGainersAndLosersResponsePayload = {
  data?: {
    [key: string]: Array<TopGainersOrLosers>
  }
} & ResponseError
export const getTopGainersAndLosers= async (data: {start_date: string, end_date: string }): Promise<TopGainersAndLosersResponsePayload> => {
  const {start_date, end_date} = data
  const res = await axios
    .get(`${FIREBASE_API_URL}/AUTO100/gainers-losers?start_date=${start_date}&end_date=${end_date}`, await headerConfig())
    .catch((e) => e.response);
  if( res.status === 200  || res.status === 201 ){
    return {data: res?.data};
  }else {
    return {error: res?.data?.error}
  }
};

export const setUserPermissions = async (
  uid: string,
  permissions: string[]
): Promise<void> => {
  await axios
    .post(
      `${FIREBASE_API_URL}/admin/set-permissions`,
      { uid: uid, permissions: permissions.join(",") },
      await headerConfig()
    )
    .catch((e) => e.response);
};

export const setUserDivision = async (
  uid: string,
  division: string
): Promise<void> => {
  await axios
    .post(
      `${FIREBASE_API_URL}/admin/set-division`,
      { uid: uid, division: division },
      await headerConfig()
    )
    .catch((e) => e.response);
};

// Auto100 ENDPOINTS
export interface ComputeScoresPayloadProps {
  year: number;
  quarter: number;
}

export const computeScores = async (data: unknown): Promise<unknown> => {
  const res = await axios.post(
    `${FIREBASE_API_URL}/AUTO100/compute`,
    data,
    await headerConfig()
  );
  return res;
};

export const validateFile = async (data: unknown): Promise<unknown> => {
  const res = await axios.post(`${FIREBASE_API_URL}/validate`, data, {
    headers: {
      "Access-Token": "TEST_API_KEY",
    },
  });
  return res;
};
