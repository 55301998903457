import { styled } from '@mui/material';
import { animated, useSpring } from '@react-spring/web';

const Background = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
`;

const AnimatedBackground = () => {
  const gradient = useSpring({
    from: { background: 'radial-gradient(at 100% 100%, #014275 100px, #0b2940 20%)' },
    to: { background: 'radial-gradient(at 100% 0%, #014275 100px, #0b2940 20%)' },
    config: { duration: 15000, delay: 1000 },
  });

  return <Background style={gradient} />;
};

export default AnimatedBackground;
