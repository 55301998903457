import {
  Alert,
  Box,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useContext, useCallback, useMemo } from "react";
import { toPng } from "html-to-image";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// CUSTOM
import { AUTO100Ctx, RebalancingOption } from "../../AUTO100DataProvider";
import { RadarChart } from "pages/Playground/RadarChart";
import { Datasets } from "types";
import { mixColors } from "utils";
import ColorBadge from "components/color-badge";
import SectionDescription from "components/section-description";
import SectionTitle from "components/section-title/index";
import Section from "components/section/Index";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ 
        display: "flex",
        flex: 1,
        width: "100%",
       }}
      {...other}
    >
      {value === index && (
          children
      )}
    </div>
  );
}

type IRadarChartContainerProps = {
  id: string;
  title: string;
  description: string;
  onExport: (id: string) => void;
  children: JSX.Element;
};
function RadarChartContainer({
  id,
  title,
  description,
  onExport,
  children,
}: IRadarChartContainerProps) {
  return (
    <Stack sx={{ flex: 1 }}>
      <OpenInNewIcon
        onClick={() => onExport("aggregate-company-deepdive-radar-chart")}
        fontSize={"small"}
        sx={{ color: "gray", cursor: "pointer", position: "absolute", right: "16px", top: "16px" }}
      />
      <Stack
        flex={1}
        id="aggregate-company-deepdive-radar-chart"
      >
        {children}
      </Stack>
    </Stack>
  );
}

function CompanyDeepdiveRadarCharts() {
  const [value, setValue] = React.useState(0);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const {
    analytics: {
      categories,
      regions,
      scores,
      companies,
      userPreference: { deepdivePreferences },
    },
    permissions,
  } = useContext(AUTO100Ctx);
  const quarter = useMemo(() => "2022_Q4", []);

  const scoreKeys = {
    aggregate: ["Relationship_score", "Sentiment_score", "Strategy_score"],
    relationship: [
      "by_relationship_r-oi-1",
      "by_relationship_r-oi-2",
      "by_relationship_r-om-1",
      "by_relationship_r-om-2",
      "by_relationship_r-or-1",
      "by_relationship_r-or-2",
      "by_relationship_r-or-3",
      "by_relationship_r-so-1",
      "by_relationship_r-so-2",
      "by_relationship_r-so-3",
    ],
    strategy: [
      "by_strategy_st-1",
      "by_strategy_st-10",
      "by_strategy_st-11",
      "by_strategy_st-12",
      "by_strategy_st-2",
      "by_strategy_st-3",
      "by_strategy_st-4",
      "by_strategy_st-5",
      "by_strategy_st-6",
      "by_strategy_st-7",
    ],
    sentiment: ["by_sentiment_se-f-1", "by_sentiment_se-l-1", ""],
  };
  const friendlyNames : {[key: string]: string} = {
    "by_strategy_st-1":"Strategy Communication",
    "by_strategy_st-2":"Brand Value",
    "by_strategy_st-3":"Gross Margin",
    "by_strategy_st-4":"EV Share - sales",
    "by_strategy_st-5":"EV Share - portfolio",
    "by_strategy_st-6":"Share of Charging Network",
    "by_strategy_st-7":"Highly Innovative Domains",
    "by_strategy_st-10":"Berylls Sustainability Evaluation",
    "by_strategy_st-11":"Berylls Top 100 Suppliers Performance",
    "by_strategy_st-12":"Macroeconomic Exposure",
    "by_sentiment_se-f-1": "Twitter Score",
    "by_sentiment_se-l-1": "Reddit Score"
  }

  const average = (arr: Array<number>) =>
    arr.reduce((p, c) => p + c, 0) / arr.length;

  type ArrayElement<ArrayType extends readonly unknown[]> =
    ArrayType extends readonly (infer ElementType)[] ? ElementType : never;
  const labelRenderer = useCallback(
    (label: string, dataset: ArrayElement<Datasets>) => {
      if (!scores) return "";
      const result = {
        [RebalancingOption.Solactive]: 0,
        [RebalancingOption.WisdomTree]: 0,
      };

      if (dataset.id.includes("Category") && dataset.id.includes("Region")) {
        const ids = dataset.id.split("-");
        const regionId = ids[0].includes("Region") ? ids[0] : ids[1];
        const categoryId = ids[0].includes("Category") ? ids[0] : ids[1];

        const category = categories.find(
          (_category) => _category.id === categoryId
        );
        const region = regions.find((_region) => _region.id === regionId);

        if (category && region) {
          const _companies = companies.filter(
            (company) =>
              company.Category === category.name.toLowerCase() &&
              company.Region === region.name.toLowerCase()
          );
          let collectiveScores: { [key: string]: Array<number> } = {
            [RebalancingOption.Solactive]: [],
            [RebalancingOption.WisdomTree]: [],
          };
          _companies.forEach((company) => {
            if (
              scores[RebalancingOption.Solactive]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== undefined &&
              scores[RebalancingOption.Solactive]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== null
            ) {
              collectiveScores[RebalancingOption.Solactive].push(
                scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                  quarter
                ]?.[label]
              );
            }
            if (
              scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== undefined &&
              scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== null
            ) {
              collectiveScores[RebalancingOption.WisdomTree].push(
                scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[
                  quarter
                ]?.[label]
              );
            }
          });
          result[RebalancingOption.Solactive] = average(
            collectiveScores[RebalancingOption.Solactive]
          );
          result[RebalancingOption.WisdomTree] = average(
            collectiveScores[RebalancingOption.WisdomTree]
          );
        } else {
          result[RebalancingOption.Solactive] = NaN;
          result[RebalancingOption.WisdomTree] = NaN;
        }
      } else if (dataset.id.includes("Category")) {
        const ids = dataset.id.split("-");
        const categoryId = ids[0].includes("Category") ? ids[0] : ids[1];

        const category = categories.find(
          (_category) => _category.id === categoryId
        );

        if (category) {
          const _companies = companies.filter(
            (company) => company.Category === category.name.toLowerCase()
          );
          let collectiveScores: { [key: string]: Array<number> } = {
            [RebalancingOption.Solactive]: [],
            [RebalancingOption.WisdomTree]: [],
          };
          _companies.forEach((company) => {
            if (
              scores[RebalancingOption.Solactive]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== undefined &&
              scores[RebalancingOption.Solactive]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== null
            ) {
              collectiveScores[RebalancingOption.Solactive].push(
                scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                  quarter
                ]?.[label]
              );
            }
            if (
              scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== undefined &&
              scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== null
            ) {
              collectiveScores[RebalancingOption.WisdomTree].push(
                scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[
                  quarter
                ]?.[label]
              );
            }
          });
          result[RebalancingOption.Solactive] = average(
            collectiveScores[RebalancingOption.Solactive]
          );
          result[RebalancingOption.WisdomTree] = average(
            collectiveScores[RebalancingOption.WisdomTree]
          );
        } else {
          result[RebalancingOption.Solactive] = NaN;
          result[RebalancingOption.WisdomTree] = NaN;
        }
      } else if (dataset.id.includes("Region")) {
        const ids = dataset.id.split("-");
        const regionId = ids[0].includes("Region") ? ids[0] : ids[1];

        const region = regions.find((_region) => _region.id === regionId);

        if (region) {
          const _companies = companies.filter(
            (company) => company.Region === region.name.toLowerCase()
          );
          let collectiveScores: { [key: string]: Array<number> } = {
            [RebalancingOption.Solactive]: [],
            [RebalancingOption.WisdomTree]: [],
          };
          _companies.forEach((company) => {
            if (
              scores[RebalancingOption.Solactive]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== undefined &&
              scores[RebalancingOption.Solactive]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== null
            ) {
              collectiveScores[RebalancingOption.Solactive].push(
                scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                  quarter
                ]?.[label]
              );
            }
            if (
              scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== undefined &&
              scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== null
            ) {
              collectiveScores[RebalancingOption.WisdomTree].push(
                scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[
                  quarter
                ]?.[label]
              );
            }
          });
          result[RebalancingOption.Solactive] = average(
            collectiveScores[RebalancingOption.Solactive]
          );
          result[RebalancingOption.WisdomTree] = average(
            collectiveScores[RebalancingOption.WisdomTree]
          );
        } else {
          result[RebalancingOption.Solactive] = NaN;
          result[RebalancingOption.WisdomTree] = NaN;
        }
      } else {
        result[RebalancingOption.Solactive] = NaN;
        result[RebalancingOption.WisdomTree] = NaN;
      }

      return [
        ` ${label} (${dataset.label})`,
        // `Solactive: ${result[RebalancingOption.Solactive].toFixed(3)}`,
        // `WisdomTree: ${result[RebalancingOption.WisdomTree].toFixed(3)}`,
      ];
    },
    [categories, companies, quarter, regions, scores]
  );
  const parsedScores = useCallback(
    (companyScoreKeys: Array<string>) => {
      if (scores) {
        const _scores: Datasets = [];

        (deepdivePreferences?.company ?? []).forEach((company) => {
          _scores.push({
            label: company.Name,
            data: companyScoreKeys.map(
              (scoreKey) =>
                scores[RebalancingOption.Solactive]?.[company?.ISIN]?.[
                  quarter
                ]?.[scoreKey] ?? 0
            ),
            borderColor: company.Color,
            backgroundColor: "transparent",
            borderWidth: 2,
            id: company.ISIN,
          });
        });

        const bawaRegions = regions
          .filter((region) =>
            (deepdivePreferences?.region ?? []).includes(region.id)
          )
          .map((region) => region.name.toLowerCase());
        const bawaCategories = categories
          .filter((category) =>
            (deepdivePreferences?.segment ?? []).includes(category.id)
          )
          .map((category) => category.name.toLowerCase());

        if (bawaRegions.length && !bawaCategories.length) {
          const regionScores: { [id: string]: Array<number> } = {};
          regions
            .filter((region) =>
              (deepdivePreferences?.region ?? []).includes(region.id)
            )
            .forEach((region) => {
              const _companies = companies.filter(
                (company) => company.Region === region.name.toLowerCase()
              );
              companyScoreKeys.forEach((scoreKey) => {
                let quarterScores: Array<number> = [];
                _companies.forEach((company) => {
                  if (
                    scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                      quarter
                    ]?.[scoreKey] !== undefined &&
                    scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                      quarter
                    ]?.[scoreKey] !== null
                  ) {
                    quarterScores.push(
                      scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                        quarter
                      ]?.[scoreKey]
                    );
                  }
                });
                if (!regionScores[region.id]) {
                  regionScores[region.id] = [];
                }
                if (quarterScores.length) {
                  regionScores[region.id].push(average(quarterScores));
                }
              });
              _scores.push({
                label: region.name,
                data: regionScores[region.id],
                borderColor: region.Color,
                backgroundColor: "transparent",
                borderWidth: 2,
                id: region.id,
              });
            });
        } else if (!bawaRegions.length && bawaCategories.length) {
          const categoryScores: { [id: string]: Array<number> } = {};
          categories
            .filter((category) =>
              (deepdivePreferences?.segment ?? []).includes(category.id)
            )
            .forEach((category) => {
              const _companies = companies.filter(
                (company) => company.Category === category.name.toLowerCase()
              );

              companyScoreKeys.forEach((scoreKey) => {
                let quarterScores: Array<number> = [];
                _companies.forEach((company) => {
                  if (
                    scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                      quarter
                    ]?.[scoreKey] !== undefined &&
                    scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                      quarter
                    ]?.[scoreKey] !== null
                  ) {
                    quarterScores.push(
                      scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                        quarter
                      ]?.[scoreKey]
                    );
                  }
                });
                if (!categoryScores[category.id]) {
                  categoryScores[category.id] = [];
                }
                if (quarterScores.length) {
                  categoryScores[category.id].push(average(quarterScores));
                }
              });
              _scores.push({
                label: category.name,
                data: categoryScores[category.id],
                borderColor: category.Color,
                backgroundColor: "transparent",
                borderWidth: 2,
                id: category.id,
              });
            });
        } else if (bawaRegions.length && bawaCategories.length) {
          const combinedScores: { [id: string]: Array<number> } = {};
          categories
            .filter((category) =>
              (deepdivePreferences?.segment ?? []).includes(category.id)
            )
            .forEach((category) => {
              regions
                .filter((region) =>
                  (deepdivePreferences?.region ?? []).includes(region.id)
                )
                .forEach((region) => {
                  const _companies = companies.filter(
                    (company) =>
                      company.Category === category.name.toLowerCase() &&
                      company.Region === region.name.toLowerCase()
                  );
                  const id = `${region.id}-${category.id}`;

                  companyScoreKeys.forEach((scoreKey) => {
                    let quarterScores: Array<number> = [];
                    _companies.forEach((company) => {
                      if (
                        scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                          quarter
                        ]?.[scoreKey] !== undefined &&
                        scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                          quarter
                        ]?.[scoreKey] !== null
                      ) {
                        quarterScores.push(
                          scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                            quarter
                          ]?.[scoreKey]
                        );
                      }
                    });
                    if (!combinedScores[id]) {
                      combinedScores[id] = [];
                    }
                    if (quarterScores.length) {
                      combinedScores[id].push(average(quarterScores));
                    }
                  });
                  _scores.push({
                    label: `${category.label} (${region.label})`,

                    data: combinedScores[id],
                    borderColor: mixColors(category.Color, region.Color),
                    backgroundColor: "transparent",
                    borderWidth: 2,
                    id: id,
                  });
                });
            });
        }

        return _scores;
      }
      return [];
    },
    [
      categories,
      companies,
      deepdivePreferences?.company,
      deepdivePreferences?.region,
      deepdivePreferences?.segment,
      quarter,
      regions,
      scores,
    ]
  );

  const friendlyLabels = useMemo(() => {
    return {
      "aggregate": scoreKeys.aggregate,
      "relationship": scoreKeys.relationship,
      "strategy": scoreKeys.strategy.map(value => friendlyNames[value]),
      "sentiment": scoreKeys.sentiment.map(value => friendlyNames[value]),
    }
  }, [scoreKeys, friendlyNames])

  if (!permissions) {
    return <>Loading</>;
  }

  const displayHeader = () => {
    switch(value){
      case 0:
        return <Stack>
          <SectionTitle text="Strategy Score" />
          <SectionDescription text="Quantitative Outside-in 360° strategy assessment of positioning on key automotive topics, clarity of strategy communication and per-vehicle earning power." />
        </Stack>
      case 1:
        return <Stack>
          <SectionTitle text="Relationship Score" />
          <SectionDescription text="Data driven up and downstream value chain assessment as well as relationship strength and connections within the industry." />
        </Stack>
      case 2:
        return <Stack>
          <SectionTitle text="Sentiment Score" />
          <SectionDescription text="Investor perception assessment across relevant channels as well as sustainability perception." />

        </Stack>
      default: 
        return null
    }
  }

  const handleExport = async (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const link = document.createElement("a");
      link.download = `${quarter}_Company Performance Radar`;
      link.href = await toPng(element);
      link.click();
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Section style={{flex: 3}}>
      {displayHeader()}
      <Tabs
        color="inherit"
        value={value}
        onChange={handleChange}
        sx={{
          my: 3,
          backgroundColor: "#F1F5F9",
          flex: 1,
          width: "100%",
          padding: "4px",
          height: 'fit-content',
          maxHeight: "20px",
          borderRadius: "12px",
          "& .Mui-selected": {
            borderRadius: "12px",
            color: "#000",
            backgroundColor: "#fff"
          }
        }}
        TabIndicatorProps={{
          style: {
            display: 'none'
          }
        }}
      >
        <Tab sx={{
          flex: 1,
          display: "flex",
          color: "#64758B",
          fontWeight: 600,
          textTransform: "none",
          height: "40px",
          minHeight: "40px",
          width: "100%"
        }}label="Strategy" {...a11yProps(0)} />
        <Tab color="inherit" sx={{
          flex: 1,
          display: "flex",
          color: "#64758B",
          fontWeight: 600,
          textTransform: "none",
          height: "40px",
          minHeight: "40px",
          width: "100%"
        }} label="Relationship" {...a11yProps(1)} />
        <Tab sx={{
          flex: 1,
          display: "flex",
          color: "#64758B",
          fontWeight: 600,
          textTransform: "none",
          height: "40px",
          minHeight: "40px",
          width: "100%"
        }} label="Sentiment" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <RadarChartContainer
          id={"strategy-company-deepdive-radar-chart"}
          title={"Strategy Scores"}
          description="Quantitative Outside-in 360° strategy assessment of positioning on key automotive topics, clarity of strategy communication and per-vehicle earning power."
          onExport={handleExport}
        >
          <RadarChart
            labelRenderer={(label: string, dataset) =>
              labelRenderer(label, dataset)
            }
            labels={friendlyLabels.strategy}
            title={"Strategy Scores"}
            datasets={parsedScores(scoreKeys.strategy)}
            isAggregate={false}
          />
        </RadarChartContainer>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RadarChartContainer
          id={"relationship-company-deepdive-radar-chart"}
          title={"Relationship Scores"}
          description="Data driven up and downstream value chain assessment as well as relationship strength and connections within the industry."
          onExport={handleExport}
        >
          <RadarChart
            labelRenderer={(label: string, dataset) =>
              labelRenderer(label, dataset)
            }
            labels={friendlyLabels.relationship}
            title={"Relationship Scores"}
            datasets={parsedScores(scoreKeys.relationship)}
            isAggregate={false}
          />
        </RadarChartContainer>
      </TabPanel>
      
      <TabPanel value={value} index={2}>
        <RadarChartContainer
          id={"sentiment-company-deepdive-radar-chart"}
          title={"Sentiment Scores"}
          description="Investor perception assessment across relevant channels as well as sustainability perception."
          onExport={handleExport}
        >
          <RadarChart
            labelRenderer={(label: string, dataset) =>
              labelRenderer(label, dataset)
            }
            labels={friendlyLabels.sentiment}
            title={"Sentiment Scores"}
            datasets={parsedScores(scoreKeys.sentiment)}
            isAggregate={false}
          />
        </RadarChartContainer>
      </TabPanel>
      <Stack flexDirection="row" flexWrap="wrap" gap={1} mt={1}>
        {
          parsedScores(scoreKeys.aggregate).map(dataset => (
              <ColorBadge color={dataset.borderColor} title={dataset.label} />
          ))
        }
      </Stack>
    </Section>
  );
}

export default CompanyDeepdiveRadarCharts;
