import { FC, useEffect, useState } from "react";
// MUI
import {
  Container as MuiContainer,
  Stack,
  styled,
  Typography,
} from "@mui/material";

// Third Party
import { collection, onSnapshot } from "firebase/firestore";
import { doc, setDoc } from "firebase/firestore";

// Custom
import { getAllUsers } from "../API";
import { SystemFirebaseUser } from "../ifaces";
import { Auto100Permissions } from "../AUTO100DataProvider";
import { firestore } from "../Firebase";

const HomePageText = styled(Typography)({
  fontFamily: "Rajdhani",
});
const Container = styled(MuiContainer)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// const TableRowPermission = ({
//   permission,
//   text,
//   onClick,
// }: {
//   permission: boolean;
//   text: string;
//   onClick: () => void;
// }) => {
//   return (
//     <span
//       style={{
//         color: permission ? 'blue' : 'crimson',
//         marginLeft: '5px',
//         cursor: 'pointer',
//       }}
//       onClick={onClick}
//     >
//       {text}
//     </span>
//   );
// };

const TableHeader = () => {
  return (
    <Stack
      direction={"row"}
      mt={2}
      sx={{ fontWeight: 600, fontSize: "0.8rem" }}
      spacing={2}
    >
      <Stack style={{ flex: 1, justifyContent: "center" }}>UID</Stack>
      <Stack style={{ flex: 1, justifyContent: "center" }}>Email</Stack>
      <Stack style={{ flex: 1, justifyContent: "center" }}>Name</Stack>
      <Stack
        style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
      >
        Permisisions
      </Stack>
    </Stack>
  );
};

interface TableRowProps {
  user: SystemFirebaseUser;
  permissions: { [key: string]: Auto100Permissions };
  onPermissionChange: (uid: string, permissions: Auto100Permissions) => void;
}

const TableRow: FC<TableRowProps> = ({
  user,
  permissions,
  onPermissionChange,
}) => {
  // const updatePermissions = (uid: string, permissionKey: RebalancingOption | 'PLAYGROUND') => {
  //   const _permissions = { ...(permissions[uid] ?? {}), [permissionKey]: !permissions?.[uid]?.[permissionKey] };
  //   onPermissionChange(uid, _permissions);
  // };

  return (
    <Stack
      direction={"row"}
      sx={{ fontWeight: 400, fontSize: "0.8rem" }}
      spacing={2}
    >
      <Stack style={{ flex: 1, justifyContent: "center" }}>{user.uid}</Stack>
      <Stack style={{ flex: 1, justifyContent: "center" }}>{user.email}</Stack>
      <Stack style={{ flex: 1, justifyContent: "center" }}>
        {user.displayName}
      </Stack>
      {/* <Stack direction={'row'} style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <TableRowPermission
          permission={permissions?.[user.uid]?.[RebalancingOption.Solactive]}
          text={RebalancingOption.Solactive}
          onClick={() => updatePermissions(user.uid, RebalancingOption.Solactive)}
        />
        <TableRowPermission
          permission={permissions?.[user.uid]?.[RebalancingOption.WisdomTree]}
          text={RebalancingOption.WisdomTree}
          onClick={() => updatePermissions(user.uid, RebalancingOption.WisdomTree)}
        />
        <TableRowPermission
          permission={permissions?.[user.uid]?.['PLAYGROUND']}
          text={'PLAYGROUND'}
          onClick={() => updatePermissions(user.uid, 'PLAYGROUND')}
        />
      </Stack> */}
    </Stack>
  );
};

const PermissionManager: FC = () => {
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState<{
    [key: string]: Auto100Permissions;
  }>();
  const [users, setUsers] = useState<SystemFirebaseUser[]>();

  const fetchData = async () => {
    setLoading(true);
    const users = ((await getAllUsers()) ?? []).filter((user) =>
      (
        user.customClaims?.permissions?.split(",") ?? ([] as Array<string>)
      ).includes("AUTO100")
    );
    setUsers(users);
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const unsub = onSnapshot(
      collection(firestore, "auto100_permissions"),
      (snapshot) => {
        const _permissions: { [key: string]: Auto100Permissions } = {};
        (snapshot.docs ?? []).forEach((_snap) => {
          _permissions[_snap.id] = _snap.data() as Auto100Permissions;
        }, {} as { [key: string]: Auto100Permissions });
        setPermissions(_permissions);
      },
      (error) => {
        //  TODO: Show error notification
        console.error(error);
      }
    );
    return unsub;
  }, []);

  const handlePermissionsChange = async (
    uid: string,
    permissions: Auto100Permissions
  ) => {
    const cityRef = doc(firestore, "auto100_permissions", uid);
    await setDoc(cityRef, permissions, { merge: true });
  };

  if (loading) {
    return (
      <Container maxWidth={"lg"} sx={{ height: "100%" }}>
        <Typography variant="h6">Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth={"lg"} sx={{ flexDirection: "column" }}>
      <Stack
        sx={{
          flex: 1,
          borderRadius: "15px",
          background: "white",
          filter: "drop-shadow(0px 0px 5px #ECECEC)",
          padding: "30px",
          width: "100%",
        }}
        direction="column"
      >
        <HomePageText variant="h5">USERS</HomePageText>
        <Stack spacing={1}>
          <TableHeader />
          {permissions &&
            users &&
            users.map((user, index) => {
              return (
                <TableRow
                  key={"user_" + index}
                  user={user}
                  permissions={permissions}
                  onPermissionChange={handlePermissionsChange}
                />
              );
            })}
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: "100%",
        }}
        direction="column"
      >
        <Typography sx={{ fontSize: 12, mt: 1 }} textAlign={"right"}>
          NOTE: The updates will be saved automatically.
        </Typography>
      </Stack>

      <Stack spacing={1}></Stack>
    </Container>
  );
};

export default PermissionManager;
