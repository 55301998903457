import { Typography } from "@mui/material"

type SectionDescriptionProps = {
    text: string
    textAlign?: string
    color?: string
    fontSize?: string
    fontWeight?: number
    lineHeight?: string
    letterSpacing?: string
}
const SectionDescription = ({text, textAlign = "left", color = "#64758B", fontSize = "14px", fontWeight = 600, lineHeight = "20px", letterSpacing = "0.14px"}: SectionDescriptionProps) => {
    return <Typography sx={{
        textAlign,
        color,
        fontSize,
        fontWeight,
        lineHeight,
        letterSpacing,
      }}>
        {text}
      </Typography>
}

export default SectionDescription