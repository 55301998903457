import { useEffect, useMemo, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";

//  CUSTOM
import { RebalancingOption } from "AUTO100DataProvider";
import { Datasets } from "../../types";
import { Stack } from "@mui/system";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;
interface ILineChartProps {
  datasets?: Datasets;
  defaultRebalancingOption: RebalancingOption;
  quarters: Array<string>;
  labelRenderer: (
    label: string,
    dataset: ArrayElement<Datasets>
  ) => string | string[];
}

export function LineChart({
  datasets,
  quarters,
  labelRenderer,
}: ILineChartProps) {

  useEffect(() => {
    return () => {
        // componentwillunmount in functional component.
        // Anything in here is fired on component unmount.
        var tooltipEl = document.getElementById("chartjs-tooltip");
        if( tooltipEl ){
          document.body.removeChild(tooltipEl);
        }
    }
}, [])

  const options: ChartOptions = useMemo(
    () => ({
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        eventPlugin: {
          events: ['mouseenter', 'mouseleave']
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          external: function (context) {
            var tooltipModel = context.tooltip;

            // Tooltip Element
            var tooltipEl = document.getElementById("chartjs-tooltip");

            // Create element on first render
            if (!tooltipEl) {

              tooltipEl = document.createElement("div");
              tooltipEl.id = "chartjs-tooltip";
              tooltipEl.innerHTML = "<table></table>";
              // tooltipEl.classList.add("scrollbar");
              document.body.appendChild(tooltipEl);
            }

            // Set Text
            if (tooltipModel.body) {
              var titleLines = tooltipModel.title || [];
              var bodyLines = tooltipModel.body.map((item) => item.lines);

              var innerHtml = "<thead>";

              titleLines.forEach(function (title) {
                innerHtml += "<tr><th>" + title + "</th></tr>";
              });
              innerHtml += "</thead><tbody >";

              bodyLines.forEach((body, i) => {
                var colors = tooltipModel.labelColors[i];
                var style = "background:" + colors.backgroundColor;
                style += "; border-color:" + colors.borderColor;
                style += "; border-width: 2px !important";
                style += "; width: 10px !important";
                style += "; height: 10px !important";
                style += "; display: inline-block !important";
                style += "; margin-right: 3px !important";

                var box = `<span style="${style}"></span>`;
                innerHtml += `<tr><td>${box}${body}</td></tr>`;
              });

              innerHtml += "</tbody>";

              var tableRoot = tooltipEl.querySelector("table");
              if (tableRoot) tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this.chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = "1";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.left =
              position.left + window.pageXOffset + tooltipModel.caretX + "px";
            tooltipEl.style.top =
              position.top + window.pageYOffset + tooltipModel.caretY + "px";
            tooltipEl.style.pointerEvents = "onhover"; // Use when need to stop mouse events such as Onhover and Scrolling

            tooltipEl.style.borderColor = "blue";
            tooltipEl.style.backgroundColor = "white";
            tooltipEl.style.maxHeight = "100px";
            tooltipEl.style.overflowY = "auto";
            tooltipEl.style.scrollBehavior = "smooth";

            tooltipEl.style.borderRadius = "8px";
            tooltipEl.style.border = "2px solid #D8DEE6";
            tooltipEl.style.boxShadow = "0px 4px 8px 0px rgba(0, 0, 0, 0.08)";

            tooltipEl.style.fontSize = "12px";
            tooltipEl.style.fontWeight = "700";
            tooltipEl.style.lineHeight = "16px";
            tooltipEl.style.letterSpacing = "0.24px";

            tooltipEl.style.padding = "8px 16px";
          },
          enabled: false,
          intersect: false,
          callbacks: {
            title: function (data) {
              return "";
            },
            label: function ({ label, dataset }) {
              return labelRenderer(
                label,
                dataset as unknown as ArrayElement<Datasets>
              );
            },
          },
        },
      },
      // scales: {
      //   y: {
      //     grid: {
      //       display: false,
      //     },
      //     min: 0,
      //     max: 10,
      //   },
      // },

      scales: {
        x: {
          border: {
            dash: [3,3]
          },
          
        },
        y: {
          border: {
            dash: [3,3]
          },
        },
        
      }
    }),
    [labelRenderer]
  );

  if (!datasets) return <h4>Loading...</h4>;
  return (
    <Stack
      direction="column"
      flex={1}
      sx={{
        display: "flex",
        alignItems: "flex-end",
      }}
    >
      <Line
        options={options as any}
        data={{ labels: quarters, datasets }}
        plugins={[
          {
            id: "myChart",
            beforeDraw: (chart: any) => {
              const ctx = chart.canvas.getContext("2d");
              ctx.save();
              ctx.globalCompositeOperation = "destination-over";
              ctx.fillStyle = "white";
              ctx.fillRect(0, 0, chart.canvas.width, chart.canvas.height);
              ctx.restore();
            },
          },
        ]}
      />
      <div id="imgWrap"></div>
    </Stack>
  );
}
