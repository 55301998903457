import React, { useContext, useEffect, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import "./App.css";

// Auth Context
import { AuthCtx } from "./AuthProvider";

// Custom Components
import LoadingPage from "./pages/General/LoadingPage";
import UnauthorizedPage from "./pages/General/UnauthorizedPage";
import NavigationBar from "components/NavigationBar/Index";
import Sidebar from "components/Sidebar/Index";
import { Box, Stack } from "@mui/material";
import Toolbar from "components/Toolbar/Index";

export interface Activity {
  type: string;
  code: string;
}

export interface ItemMatrix {
  code: string;
  type: string;
  item: number[];
}

export interface SecureLayoutProps {
  title?: string;
  adminRequired?: boolean;
  permissionRequired?: boolean;
  permissionName?: string;
}

function SecureLayout({
  children,
  adminRequired,
  permissionRequired,
  permissionName,
}: { children: React.ReactNode } & SecureLayoutProps) {
  const {
    user,
    customClaims,
    loading: loadingUser,
    permissions,
  } = useContext(AuthCtx);

  let location = useLocation();

  const isTempPath = useMemo(() => {
    const { pathname } = location
    return user && ( pathname === "/" || pathname === "/playground" )
  }, [user, location])

  const currentPath = React.useMemo(() => {
    const { pathname } = location
    return pathname.split("/").pop()
  }, [location])

  const isScrollDisabled = useMemo(() => {
    return currentPath === 'universe-constituents'
  }, [currentPath])

  if (loadingUser) {
    return <LoadingPage />;
  } else if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if( isTempPath ){
    return <Navigate to="/playground/company-deepdive" state={{ from: location }} replace />;
  }

  if (adminRequired && (!customClaims || !customClaims.isAdmin)) {
    return <UnauthorizedPage />;
  }

  if (
    permissionRequired &&
    permissionName &&
    permissions.indexOf(permissionName) === -1
  ) {
    return <UnauthorizedPage />;
  }

  return <Box sx={{ display: 'flex', flex: 1, overflow: isScrollDisabled ? "hidden" : undefined }}>
      {children}
    </Box>
}

export default SecureLayout;
