import { Stack } from "@mui/material";
import React, { useMemo, useContext, useEffect, useState, useCallback } from "react";

// Firebase
import { ref as dbRef, getDatabase, onValue, set } from "firebase/database";

// MUI
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// CUSTOM
import { AUTO100Ctx, RebalancingOption, WorkEnvironment } from "../AUTO100DataProvider";
import { AuthCtx } from "../AuthProvider";
import { getClosestQuarter, getCurrentQuarter, getQuarters } from "utils";
import { QuarterStatus } from "./ComputeScoreResult";

function PageFilters() {
  const rebalancingOptions = useMemo(
    () => [RebalancingOption.Solactive, RebalancingOption.WisdomTree],
    []
  );

  const availableQuarters = {
    [RebalancingOption.Solactive]: [1,2,3,4],
    [RebalancingOption.WisdomTree]: [1,3]
  }

  const {
    setSelectedQuarter,
    setSelectedYear,
    setSelectedRebalancingOption,
    selectedYear,
    selectedQuarter,
    selectedRebalancingOption,
  } = useContext(AUTO100Ctx);

  const db = getDatabase();

  const { user } = useContext(AuthCtx);
  const [isRebalancingSelectionUpdated, setRebalancingSelectionUpdated] = useState(false)
  const [quartersStatus, setQuartersStatus] = useState<{[key: string]:{"quarter_status": QuarterStatus}}>()

  const quarterDifference = useMemo(() => {
    const _quarterDifference = {
      [RebalancingOption.Solactive]: 1,
      [RebalancingOption.WisdomTree]: 2
    }

    if( !selectedRebalancingOption ) return _quarterDifference[RebalancingOption.Solactive]
    return _quarterDifference[selectedRebalancingOption] 

  },[selectedRebalancingOption])

  useEffect(() => {
    if (selectedYear && selectedQuarter) {
      
      //  Get Quarter Status
      let path = `AUTO100/${WorkEnvironment.Production}/${selectedRebalancingOption}`;
      let ref = dbRef(db, path);
      const quarter_status_unsubscribe = onValue(ref, (snapshot) => {
        const data = snapshot.val() as {[key: string]:{"quarter_status": QuarterStatus}} | undefined;
        setQuartersStatus({...data} ?? undefined)
      });

      const unsub = () => {
        quarter_status_unsubscribe();
      };

      return unsub;
    }
  }, [ db, selectedRebalancingOption, selectedYear, selectedQuarter]);

  //  disabling quarters and disabled quarters
  // const getPreviousValidQuarter = useCallback((quarter: number) => {
  //   if( selectedRebalancingOption ){
  //     const index = availableQuarters[selectedRebalancingOption].findIndex( _quarter => _quarter === quarter )
  //     if( index > 0 ){
  //       //  get previous to the given quarter
  //       return availableQuarters[selectedRebalancingOption][index-1]
  //     }
  //     //  return first quarter
  //     return availableQuarters[selectedRebalancingOption][0]
  //   }
  //   return null
  // },[selectedRebalancingOption])

  //  Purpose for this event is to be Called When Year Changes or page loads
  //  We need to check if the currently selected Quarter was selectable in the first place.
  //  The idea is, a quarter is selectable if it's previous quarter has been frozen.
  useEffect(() => {

    if( !selectedRebalancingOption ) return

    if( !quartersStatus && selectedRebalancingOption ){
      updateQuarter(availableQuarters[selectedRebalancingOption][0])
    }else if( 
      selectedQuarter &&
      selectedQuarter > 1 &&
      !quartersStatus?.[`${selectedYear}_Q${selectedQuarter-quarterDifference}`]?.quarter_status?.is_frozen
    ){
      //  get last frozen quarter
      let quarterFound = false
      let _currentQuarter = selectedQuarter - 1
      while(!quarterFound){
        if( _currentQuarter === 1 || quartersStatus?.[`${selectedYear}_Q${_currentQuarter-quarterDifference}`]?.quarter_status?.is_frozen ){
          //  valid quarter found
          quarterFound = true
        }else {
          _currentQuarter--;
        }
      }
      updateQuarter(_currentQuarter)
    }
  }, [quartersStatus, selectedYear, selectedQuarter])
  //  This function is used for dropdown to check which quarters are selectable.
  //  The idea is, a quarter is selectable if it's previous quarter has been frozen.
  const isQuarterDisabled = useCallback((quarter: number) => {
    if( quarter === 1) return false

    if( !quartersStatus ) return true
    return !( quartersStatus?.[`${selectedYear}_Q${quarter-quarterDifference}`]?.quarter_status?.is_frozen )

    // if( !_quartersStatus ) return true
    // const previousQuarter = quartersData[index-1]
    // console.log({ previousQuarter, quarter, quartersData, index, _quartersStatus })
    // return ( quarter > 1 && !_quartersStatus?.[`${selectedYear}_Q${previousQuarter}`]?.quarter_status?.is_frozen )
  },[quartersStatus, selectedYear])
  // useEffect(() => {
  //   //  Rebalancing Option was Changed. Update Quarter as well.
  //   if( selectedRebalancingOption && isRebalancingSelectionUpdated ){
  //     // const quarter = getCurrentQuarter()
  //     // const closestQuarter = getClosestQuarter(availableQuarters[selectedRebalancingOption], quarter)
  //     // if( closestQuarter ){
  //     //   updateQuarter( closestQuarter )
  //     // }
  //   }
  // },[isRebalancingSelectionUpdated, selectedRebalancingOption])
  // console.log(quartersStatus)

  //  HANDLERS
  const handleSelectedRebalancingOption = async (event: SelectChangeEvent) => {
    const val = event.target.value as RebalancingOption;
    setSelectedRebalancingOption(val);
    setRebalancingSelectionUpdated(true);
    if (user) {
      const userPreferenceRef = dbRef(
        db,
        `AUTO100/user_preferences/${user.uid}/rebalancing_option`
      );
      await set(userPreferenceRef, val);
    }
  };

  const handleYearChange = async (event: SelectChangeEvent) => {
    const val = parseInt(event.target.value);
    if (!isNaN(val)) {
      setSelectedYear(val);
    }
    if (user) {
      const userPreferenceRef = dbRef(
        db,
        `AUTO100/user_preferences/${user.uid}/selected_year`
      );
      await set(userPreferenceRef, val);
    }
  };

  const handleQuarterChange = async (event: SelectChangeEvent) => {
    const val = parseInt(event.target.value);
    updateQuarter(val)
  };
  const updateQuarter = async (value: number) => {
    const val = value;
    if (!isNaN(val)) {
      setSelectedQuarter(val);
    }
    if (user) {
      const userPreferenceRef = dbRef(
        db,
        `AUTO100/user_preferences/${user.uid}/selected_quarter`
      );
      await set(userPreferenceRef, val);
    }
  }

  return (
    <Stack direction={"row"} spacing={1}>
      <FormControl fullWidth sx={{ minWidth: "300px" }}>
        <InputLabel id="demo-simple-select-label">
          Rebalancing Option
        </InputLabel>
        <Select
          value={`${selectedRebalancingOption}`}
          defaultValue={selectedRebalancingOption}
          label="Rebalancing Option"
          onChange={handleSelectedRebalancingOption}
          sx={{
            textTransform: "capitalize",
          }}
        >
          {rebalancingOptions.map((rebalancingOption) => (
            <MenuItem
              key={`select-menu-item-${rebalancingOption}`}
              sx={{ textTransform: "capitalize" }}
              value={rebalancingOption}
            >
              {rebalancingOption}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ minWidth: "150px" }}>
        <InputLabel id="demo-simple-select-label">Year</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={`${selectedYear}`}
          label="Year"
          onChange={handleYearChange}
        >
          {getQuarters({ yearsOnly: true }).map((selectYearOption) => (
            <MenuItem key={`${selectYearOption}`} value={selectYearOption}>
              {selectYearOption}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ minWidth: "150px" }}>
        <InputLabel id="demo-simple-select-label">Quarter</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={`${selectedQuarter}`}
          label="Quarter"
          onChange={handleQuarterChange}
        >
          {selectedRebalancingOption && availableQuarters[selectedRebalancingOption].map((value, index) => (
            <MenuItem disabled={isQuarterDisabled(value)} key={`page-filter-quarter-${value}`} value={value}>
              Q{value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}

export default PageFilters;
