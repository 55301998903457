import {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { Box, CircularProgress, Stack } from "@mui/material";
import moment from "moment";

// Firebase
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../Firebase";

// CUSTOM
import { WhiteButton } from "./Buttons";
import { AUTO100Ctx, WorkEnvironment } from "../AUTO100DataProvider";
import { ComputedFilesOptions } from "pages/Production/ComputedFilesOptions";
import { ConfirmationModal } from "./CustomModal";

function usePrevious<T extends unknown>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export enum ComputeStatusTypes {
  Idle = "IDLE",
  ComputeScoresInProgress = "COMPUTE_SCORES_IN_PROGRESS",
  GeneratingScoresZipFile = "GENERATING_SCORES_ZIP_FILE",
  ComputeDaviationsInProgress = "COMPUTE_DEVIATIONS_IN_PROGRESS",
  Error = "ERROR",
}
export interface QuarterStatus {
  frozen_timestamp?: number;
  frozen_by?: string;
  is_frozen: boolean;
}
export interface ComputeScoreStatus {
  end_timestamp: number;
  start_timestamp: number;
  status: ComputeStatusTypes;
}
interface ComputeScoreResultProps {
  workEnvironment: WorkEnvironment;
  computeStatus?: ComputeScoreStatus;
  isFrozen?: boolean;
  readyToCompute?: boolean;
  onError?: (errorMsg: string) => void;
  onCompute: () => void;
  onFreezeQuarter: () => void;
}

const ComputeScoreResult: FC<ComputeScoreResultProps> = ({
  workEnvironment,
  onCompute,
  onFreezeQuarter,
  computeStatus,
  isFrozen = false,
  readyToCompute = false,
}) => {
  // Freeze Quarter Confirmation Modal
  const [
    freezeQuarterConfirmationModalOpen,
    setFreezeQuarterConfirmationModalOpen,
  ] = useState(false);
  
  const [downloadURL, setDownloadURL] = useState<string>();
  const [isLoading, setLoading] = useState(false);
  const [isDownloadPending, setDownloadPending] = useState(false);
  const { selectedYear, selectedQuarter, selectedRebalancingOption } =
    useContext(AUTO100Ctx);

  const DownloadName = `Auto100_Industry_Score.zip`;

  const prevComputeStatus = usePrevious(computeStatus);

  const [isDownloading, setDownloadingStatus] = useState(false);

  const download = useCallback(async () => {
    if (downloadURL) {
      setDownloadingStatus(true);
      //  create fake link
      const link = document.createElement("a");
      link.download = DownloadName;
      link.href = downloadURL;
      link.click();
      setDownloadingStatus(false);
    }
  }, [DownloadName, downloadURL]);
  const handleDownloadClick = async () => {
    await download();
  };
  const handleCompute = async () => {
    // await handleOnDownloadClick();
    onCompute();
  };
  const handleSelectedOption = (option: string) => {
    if (option === "freeze_quarter") {
      setFreezeQuarterConfirmationModalOpen(true)
    }
  };


  useEffect(() => {
    if (computeStatus?.status) {
      setLoading(
        computeStatus?.status !== ComputeStatusTypes.Idle &&
          computeStatus?.status !== ComputeStatusTypes.Error
      );
    }
  }, [computeStatus]);
  useEffect(() => {
    if (isDownloadPending && downloadURL) {
      download();
    }
  }, [download, downloadURL, isDownloadPending]);

  useEffect(() => {
    console.log({ computeStatus, prevComputeStatus, selectedQuarter, selectedYear, selectedRebalancingOption, workEnvironment })
    if (
      selectedQuarter &&
      selectedYear &&
      prevComputeStatus?.status !== ComputeStatusTypes.Idle &&
      prevComputeStatus?.status !== ComputeStatusTypes.Error &&
      computeStatus?.status === ComputeStatusTypes.Idle
    ) {
      const fetchDownloadURL = async () => {
        try {
          const _downloadURL = await getDownloadURL(
            ref(
              storage,
              `AUTO100/computed_files/${selectedRebalancingOption}/${selectedYear}_Q${selectedQuarter}/industry_scores.zip`
            )
          );
          console.log({ _downloadURL })
          setDownloadURL(_downloadURL);
          if (prevComputeStatus) {
            setDownloadPending(true);
          }
        } catch (error) {
          setDownloadURL(undefined);
        }
      };
      fetchDownloadURL();
    }
  }, [
    computeStatus,
    prevComputeStatus,
    selectedQuarter,
    selectedRebalancingOption,
    selectedYear,
    setDownloadPending,
    workEnvironment,
  ]);

  return isLoading ? (
    <Stack
      sx={{
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "white",
        height: "150px",
      }}
      p={2}
    >
      <CircularProgress
        id={"ComputeScoreResultCircularProgress"}
        size={70}
        thickness={2}
        sx={{ color: "#DDD" }}
      />
    </Stack>
  ) : (
    <Stack>
      <Stack
        direction="row"
        sx={{ position: "absolute", right: 20, top: 20, borderRadius: 2 }}
      >
        <ComputedFilesOptions onOptionSelected={handleSelectedOption} isFrozen={isFrozen || !downloadURL || !readyToCompute} />
      </Stack>
      <Stack
        direction={"row"}
        justifyContent="center"
        alignItems={"center"}
        spacing={2}
        p={2}
        height={"150px"}
      >
        <Box sx={{ border: "1px solid #CCC", padding: "20px" }}>
          Industry Scores <br />
          {moment(computeStatus?.start_timestamp).fromNow()}
        </Box>
        <Stack direction={"row"} justifyContent="flex-end" marginTop={2}>
          <WhiteButton
            disabled={!readyToCompute || isFrozen}
            sx={{
              borderColor: isLoading ? "#DDD" : "#666",
              color: isLoading ? "#DDD" : "#666",
            }}
            onClick={handleCompute}
          >
            {isLoading ? "Computing..." : "Compute"}
          </WhiteButton>
        </Stack>
      </Stack>
      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                         CONFIRMATION MODAL                     //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <ConfirmationModal
        title="Are you sure?"
        description={`This action will freeze the Q${selectedQuarter} ${selectedYear} of ${selectedRebalancingOption}. `}
        open={freezeQuarterConfirmationModalOpen}
        onClose={() => {
          setFreezeQuarterConfirmationModalOpen(false);
        }}
        onConfirm={async () => {
          onFreezeQuarter();
          setFreezeQuarterConfirmationModalOpen(false);
        }}
        onCancel={() => {
          setFreezeQuarterConfirmationModalOpen(false);
        }}
      />
      <Stack direction={"row"} justifyContent="flex-end" marginTop={2}>
        <WhiteButton disabled={!downloadURL} onClick={handleDownloadClick}>
          {isDownloading ? "Downloading..." : "Download"}
        </WhiteButton>
      </Stack>
    </Stack>
  );
};

export default ComputeScoreResult;
