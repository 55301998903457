import React, { useEffect, useMemo, useState } from "react";
import { Button,
    Checkbox,
    Chip,
    ClickAwayListener,
    FormControlLabel,
    FormGroup,
    IconButton,
    Stack,
    Tooltip,
    TooltipProps,
    Typography, 
    styled,
    tooltipClasses } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 1)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        padding: 0,
        borderRadius: "12px",
        border: "1px solid var(--neutrals-l-ight-grey, #D8DEE6)",
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
        minHeight: 200,
        minWidth: 320

    },
}));

type AutocompleteProps = {
    label: string
    options: Array<{id: string, name: string}>
    value: string
    onChange: (id: string) => void
}

const Dropdown = ({label, options, value, onChange} : AutocompleteProps) => {
    const [selection, setSelection] = React.useState<string>(value);
    const [isListOpen, setListOpen] = useState(false)

    //  update selected values
    useEffect(() => {
        setSelection(value)
    },[value])

    const displaySelectedOptionsAsChips = useMemo(() => {
        // const chips : Array<JSX.Element> = values.slice(0, 2).map((value) => {
        //     const option = options.find((_option) => _option.id === value)

        //     return <Chip id={`chip-selection-${option?.id}`} size="small" style={{ 
        //         borderRadius: '8px', 
        //         border: '1px solid', 
        //         borderColor: '#E2E8F0', 
        //         backgroundColor: '#fff',
        //         fontWeight: 600 
        //     }} label={option?.name} />
        // })

        // if( values.length > 2 ){
        //     chips.push(<Chip id={`chip-selection-extra`} size="small" style={{ 
        //         borderRadius: '8px', 
        //         border: '1px solid', 
        //         borderColor: '#E2E8F0', 
        //         backgroundColor: '#fff',
        //         fontWeight: 600 
        //     }} label={`+${values.length - 2}`} />)
        // }
        // return chips
        return []
    }, [value])
    
    const displaySelectedValue = useMemo(() => {
        return options.find(option => option.id === value)?.name
    }, [value])

    const parsedOptions = useMemo(() => {
        return options.map(option => ({
            ...option,
            checked: selection.includes(option.id)
        }))
    }, [options, selection])

    const handleChange = (id: string) => {
        if( selection !== id ){
            setSelection(id)
        }
    }
    const handleClearAll = () => {
        setSelection('')
    }
    const handleApply = () => {
        onChange(selection)
        setListOpen(false)
    }

    return (
        <ClickAwayListener onClickAway={() => setListOpen(false)}>
            <Stack sx={{ 
                background: "#FFF",
                padding: "8px",
                borderRadius: "12px",
                border: "1px solid #D8DEE6",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                mr: 2,
                minWidth: "100px",

            }} flexDirection="row">
                <Typography style={{ fontSize: '15px', fontWeight: 600, marginRight: "10px" }} color="black">{displaySelectedValue || label}</Typography>
                {displaySelectedOptionsAsChips}

                <HtmlTooltip
                    open={isListOpen}
                    title={
                        <React.Fragment>
                            <Stack sx={{pr: "16px", height: 200, overflow: "scroll" }}>
                                {
                                    parsedOptions.map((option) => (
                                        <Stack
                                            sx={{ 
                                                background: selection === option.id ? "#F1F5F9" : "#FFF",
                                                fontSize: "600",
                                                borderRadius: "8px",
                                                position: "relative",
                                                left: 10,
                                                width: "100%",
                                                mt: 1,
                                                padding: 1,
                                                cursor: "pointer",
                                            }}
                                            onClick={() => handleChange(option.id)}
                                        >
                                            <Typography sx={{ fontSize: "14px", fontWeight: "600" }} variant="body2">{option.name}</Typography>
                                        </Stack>
                                    ))
                                }
                            </Stack>
                            <Stack sx={{ flexDirection: "row", justifyContent: "space-between", borderTop: "1px solid #D8DEE6", p: "8px", background: "#F1F5F9", borderRadius: "0 0 12px 12px" }}>
                                <Button 
                                    sx={{ fontSize: "12px", fontWeight: "600", textTransform: "none" }}
                                    variant="text"
                                    color="inherit"
                                    onClick={handleClearAll}
                                >Clear</Button>
                                <Button sx={{
                                    width: "43px",
                                    height: "40px",
                                    background: "#fff",
                                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
                                    borderRadius: "10px",
                                    zIndex: 2,
                                    textDecoration: "none",
                                    fontWeight: "600",
                                }} variant="contained" color="inherit" onClick={handleApply}>Apply</Button>
                            </Stack>
                        </React.Fragment>
                    }
                >
                    <IconButton sx={{ p: "2px" }} onClick={() => setListOpen(true)}>
                        <KeyboardArrowDownIcon />
                    </IconButton>
                </HtmlTooltip>


            </Stack>
        </ClickAwayListener>
        // <Autocomplete
        //   multiple
        //   id="tags-filled"
        //   options={options}
        //   value={value}
        //   onChange={(event, newValue) => {
        //     setValue(newValue);
        //   }}
        //   renderInput={(params) => (
        //     <TextField {...params} variant="filled" label="Tags" />
        //   )}
        //   renderTags={(value, getTagProps) =>
        //     value.map((option, index) => (
        //       <Chip
        //         label={option}
        //         {...getTagProps({ index })}
        //         onDelete={() => {
        //           const newValue = [...value];
        //           newValue.splice(index, 1);
        //           setValue(newValue);
        //         }}
        //       />
        //     ))
        //   }
        //   renderOption={(props, option, { selected }) => (
        //     <li {...props}>
        //       {option}
        //     </li>
        //   )}
        //   isOptionEqualToValue={(option, value) =>
        //     option === value || value.includes(option)
        //   }
        //   popupIcon={<></>}
        //   ListboxProps={{
        //     style: { maxHeight: 200, overflowY: "auto" }
        //   }}
        // />
    );
}

export default Dropdown;
