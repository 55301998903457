import {FC} from "react";
import {
    Grid,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody,
    styled,
    TableCell,
    tableCellClasses,
    Stack,
    Typography,
} from "@mui/material";
import { TopGainersOrLosers } from "types";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Section from "components/section/Index";
  
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 12,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));
  
interface CustomizedTablesProps {
    title: string
    performers: Array<TopGainersOrLosers>
}
  
const CustomizedTables: FC<CustomizedTablesProps> = ({
    title = "",
    performers,
}) => {

    const getChangePercentageContainer = (changeValue: number) => {
      if( changeValue < 0 ){
        // <Typography sx={{
        //   background: '#ffc6c6',
        //   color: '#ce0203',
        //   fontWeight: 900,
        //   borderRadius: '5px',
        // }} variant="body1">{changeValue.toFixed(2)}</Typography>
        return <TableCell style={{ color: "#F73D48" }} align="left">{changeValue.toFixed(2)}</TableCell>
      }
      if( changeValue > 0 ){
        return <TableCell style={{ color: '#4ABA6B' }}>{changeValue.toFixed(2)}</TableCell>
      }
      return <TableCell style={{ fontWeight: 600, color: '#000' }} align="center">-</TableCell>
    }

    return (
      <Section>
        <Typography sx={{ textAlign: "left" }} variant="h6">
          {title}
        </Typography>
        <TableContainer component={Paper} sx={{ maxHeight: 350, mt: 2, borderRadius: "12px" }}>
            <Table sx={{ borderRadius: "12px", }}>
                <TableHead sx={{
                backgroundColor: "#fff",
                position: "sticky",
                top: 0,
                borderBottom: "1px solid #D8DEE6",
                background: "#88923a",
                borderRadius: "12px"
              }}>
                    <TableRow sx={{
                          borderBottom: "1px solid #D8DEE6",
                          backgroundColor: "#F1F5F9",
                          borderRadius: "12px",
                      }}
                    >
                        <TableCell sx={{ fontWeight: "bold", background: "transparent" }} align="left">ISIN</TableCell>
                        <TableCell sx={{ fontWeight: "bold", background: "transparent" }} align="left">Name</TableCell>
                        <TableCell sx={{ fontWeight: "bold", background: "transparent" }} align="left">Start<br/>Price</TableCell>
                        <TableCell sx={{ fontWeight: "bold", background: "transparent" }} align="left">End<br/>Price</TableCell>
                        <TableCell sx={{ fontWeight: "bold", background: "transparent" }} align="left">Gain<br/>Percentage</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(performers).map((performer) => (
                        <TableRow key={`performer-${performer.ISIN}`} sx={{
                          "& td": {
                            border: 0,
                          }
                        }}>
                            <TableCell align="left">{performer.ISIN}</TableCell>
                            <TableCell sx={{ width: "10px", whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {performer.Name}
                            </TableCell>
                            <TableCell align="left">
                              ${performer.start_price.toFixed(1)}
                            </TableCell>
                            <TableCell align="left">
                              ${performer.end_price.toFixed(1)}
                            </TableCell>
                            {getChangePercentageContainer(performer.price_change_percentage)}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
      </Section>
    );
  };
  
export default CustomizedTables;  