import { geoEqualEarth } from "d3-geo";

import moment from "moment";

export const projection = geoEqualEarth()
  .scale(160)
  .translate([800 / 2, 450 / 2]);

/**
 * Get extension from filename
 * @param extension: string
 * @return string
 */
export const getExtension = (extension: string): string => {
  //  pluck out summary object and sentence array
  return (extension.split(".") ?? []).pop() ?? "";
};

////////////////////////////////////////////////////////////////////////
////                             COOKIES                            ////
////////////////////////////////////////////////////////////////////////

export const setCookie = (name: string, value: unknown, days: number) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name: string) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const eraseCookie = (name: string) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

////////////////////////////////////////////////////////////////////////
////                           DATE UTILS                           ////
////////////////////////////////////////////////////////////////////////

/**
 *
 * https://github.com/commenthol/weeknumber/blob/master/src/index.js
 *
 * ISO 8601 week numbering.
 *
 * New week starts on mondays.
 * Used by most European countries, most of Asia and Oceania.
 *
 * 1st week contains 4-7 days of the new year
 * @param {Date} [date] - local date
 * @return {number} week number in ISO 8601 format
 * @example
 * weekNumber(new Date(2016, 0, 3, 12)) // Sun
 * //> 53
 * weekNumber(new Date(2016, 0, 4, 12)) // Mon
 * //> 1
 */
export const getWeekNumber = (date = new Date()): number => {
  const MINUTE = 60000;
  const WEEK = 604800000; // = 7 * 24 * 60 * 60 * 1000 = 7 days in ms

  /**
   * Get the difference in milliseconds between the timezone offsets of 2 dates
   */
  const tzDiff = (first: Date, second: Date) =>
    (first.getTimezoneOffset() - second.getTimezoneOffset()) * MINUTE;

  // day 0 is monday
  const day = (date.getDay() + 6) % 7;
  // get thursday of present week
  const thursday = new Date(date);
  thursday.setDate(date.getDate() - day + 3);
  // set 1st january first
  const firstThursday = new Date(thursday.getFullYear(), 0, 1);
  // if Jan 1st is not a thursday...
  if (firstThursday.getDay() !== 4) {
    firstThursday.setMonth(
      0,
      1 + ((11 /* 4 + 7 */ - firstThursday.getDay()) % 7)
    );
  }
  const weekNumber =
    1 +
    Math.floor(
      (thursday.valueOf() -
        firstThursday.valueOf() +
        tzDiff(firstThursday, thursday)) /
        WEEK
    );
  return weekNumber;
};

/**
 *
 * https://github.com/commenthol/weeknumber/blob/master/src/index.js
 *
 * ISO 8601 calendar weeks in a given year
 *
 * New week starts on mondays.
 * Used by most European countries, most of Asia and Oceania.
 *
 * @param {number} year
 * @returns {number} weeks in year
 */
export const weeksPerYear = (year: number) => {
  let weeks = getWeekNumber(new Date(year, 11, 31, 12));
  if (weeks === 1) {
    weeks = getWeekNumber(new Date(year, 11, 31 - 3, 12));
  }
  return weeks;
};

/**
 * To calculate the date of the start of a given ISO8601 week
 * (which will always be a Monday)
 *
 * @param week
 * @param year
 * @returns
 */
export function getDateOfISOWeek(week: number, year: number): Date {
  var simple = new Date(year, 0, 1 + (week - 1) * 7);
  var dow = simple.getDay();
  var ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}

/** Return a given string in the title case. For example: `sOMEE text/sentence` would be returned as `Some Text/sentence` */
export function toTitleCase(str: string): string {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

/** Return a given string in the title case. For example: `sOMEE text/sentence` would be returned as `Some Text/sentence` */
export const formattedLabels: { [key: string]: string } = {
  oem: "OEM",
  row: "RoW",
  nafta: "NAFTA",
};

/**
 * Get the start date and month for the current quarter.
 *
 * @returns A string representing the start date and month of the current quarter in the format "MM-DD".
 */
export const getCurrentQuarterStartDate =(): string => {
  // Get the current quarter start date
  const startDate = moment().startOf('quarter');
  // Format the result as "MM-DD"
  return startDate.format('MM-DD');
}


/**
 * Get the start date and month for a given quarter.
 *
 * @param quarter - The quarter for which to get the start date (1 to 4).
 * @returns A string representing the start date and month in the format "MM-DD".
 * @throws Throws an error if the quarter is not between 1 and 4.
 */
export const getStartDateForQuarter = (quarter: number) : string => {
  // Validate quarter input
  if (quarter < 1 || quarter > 4) {
    return getCurrentQuarterStartDate();
  }
  // Calculate the start date based on the quarter
  const startDate = moment().quarter(quarter).startOf('quarter');
  // Format the result as "MM-DD"
  return startDate.format('MM-DD');
}

/**
 * Create array of Quarters in the given format.
 *
 * @param {*} { startYear = 2021, yearsOnly = false, format = '[Q]Q_Y' }
 * @return {*}  {Array<string>}
 */
export const getQuarters = ({
  startYear = 2021,
  yearsOnly = false,
  endYear = new Date().getFullYear(),
  endQuarter = moment().quarter() 
}): Array<string> => {
  const currentDate = new Date(`${getStartDateForQuarter(endQuarter)}-${endYear}`);

  if (yearsOnly) {
    return Array.from(Array(currentDate.getFullYear() - (startYear - 1))).map(
      (_, i) => moment(currentDate).subtract(i, "year").format("Y")
    );
  }
  // Here we are getting number of quarters between (and including) currentQuarter of currentYear and firstQuarter of startYear
  const quartersToGoBack =
    (currentDate.getFullYear() - startYear) * 4 + moment(currentDate).quarter();
  return Array.from(Array(quartersToGoBack)).map((_, i) =>
    moment(currentDate).subtract(i, "Q").format("Y_[Q]Q")
  );
};

/**
 * Returns current quarter of the given year. Default year is current year.
 *
 * @param {*} { currentDate = new Date() }
 * @return {*}  {number}
 */
export const getCurrentQuarter = (currentDate = new Date()) =>  Math.ceil((currentDate.getMonth() + 1) / 3)

/**
 * Returns current year.
 *
 * @param {*} {}
 * @return {*}  {number}
 */
export const getCurrentYear = () =>  new Date().getFullYear()


/**
 * Returns closes quarter from array of quarters against another given quarter. Returns 0 if given array of quarters is empty/not provided.
 *
 * @param {*} { quarters = [], quarter = - }
 * @return {*}  {number}
 */
export const getClosestQuarter = (quarters : number[] = [], quarter: number = 0) =>  {
  if( !quarters.length ) return quarter
  const closest = quarters.reduce((a, b) => {
    return Math.abs(b - quarter) < Math.abs(a - quarter) ? b : a;
  });
  return closest
}

/**
 *
 * @param array
 * @param element
 * @returns Array<T>
 */
export const removeAllBefore = <T>(array: Array<T>, element: T): Array<T> => {
  const indexOfElement = array.indexOf(element);
  if (indexOfElement !== -1) {
    return array.splice(indexOfElement, array.length - 1);
  }
  return array;
};

/**
 * Returns a date in DD.MM.YYYY format again a given timestamp
 *
 * @param {*} { timestamp }
 * @return {*}  {string}
 */
export const getDateByTimestamp = <T>(timestamp: number): string => {
  const date = new Date(timestamp)
  const day = `${date.getDay() < 10 && '0'}${date.getDay()}`
  const month = `${date.getMonth() < 10 && '0'}${date.getMonth()}`
  const year = date.getFullYear()
  return `${day}.${month}.${year}`
};


/**
 *
 * @param color1
 * @param color2
 * @returns string
 */
export const mixColors = (color1: string, color2: string): string => {
  // Convert hex color codes to RGB values
  const r1 = parseInt(color1.substring(1, 3), 16);
  const g1 = parseInt(color1.substring(3, 5), 16);
  const b1 = parseInt(color1.substring(5, 7), 16);

  const r2 = parseInt(color2.substring(1, 3), 16);
  const g2 = parseInt(color2.substring(3, 5), 16);
  const b2 = parseInt(color2.substring(5, 7), 16);

  // Calculate the average of each RGB component
  const avgR = Math.round((r1 + r2) / 2);
  const avgG = Math.round((g1 + g2) / 2);
  const avgB = Math.round((b1 + b2) / 2);

  // Convert the average RGB values back to a hex color code
  const mixedColor = `#${avgR.toString(16).padStart(2, "0")}${avgG
    .toString(16)
    .padStart(2, "0")}${avgB.toString(16).padStart(2, "0")}`;

  return mixedColor;
};
