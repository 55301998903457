import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
  } from "chart.js";
  import { Radar } from "react-chartjs-2";
  import { Container, Grid, Stack, Typography } from "@mui/material";
import { Datasets } from "types";
import { useEffect } from "react";
  
  //  CUSTOM
  
  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  );
  
  type ArrayElement<ArrayType extends readonly unknown[]> =
    ArrayType extends readonly (infer ElementType)[] ? ElementType : never;
  interface ISingleRadarChartProps {
    labels: Array<string>;
    datasets: Datasets;
    isAggregate: boolean;
  }
  type IRadarChartProps = ISingleRadarChartProps;
  
  export function RadarChart({
    labels,
    datasets,
    isAggregate,
  }: IRadarChartProps) {
    useEffect(() => {
      return () => {
          // componentwillunmount in functional component.
          // Anything in here is fired on component unmount.
          var tooltipEl = document.getElementById("chartjs-tooltip");
          if( tooltipEl ){
            document.body.removeChild(tooltipEl);
          }
      }
    }, [])
 
    if (!datasets) return <h4>Loading...</h4>;
  
    return (
      <Stack sx={{ height: "100%", width: "100%", mt: 2 }}>
        <Radar
          options={{
            plugins: {
              legend: {
                  display: false
              },
              tooltip: {
                external: function (context) {
                  var tooltipModel = context.tooltip;
      
                  // Tooltip Element
                  var tooltipEl = document.getElementById("chartjs-tooltip");
      
                  // Create element on first render
                  if (!tooltipEl) {
      
                    tooltipEl = document.createElement("div");
                    tooltipEl.id = "chartjs-tooltip";
                    tooltipEl.innerHTML = "<table></table>";
                    // tooltipEl.classList.add("scrollbar");
                    document.body.appendChild(tooltipEl);
                  }
      
                  // Set Text
                  if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [];
                    var bodyLines = tooltipModel.body.map((item) => item.lines);
      
                    var innerHtml = "<thead>";
      
                    titleLines.forEach(function (title) {
                      innerHtml += "<tr><th>" + title + "</th></tr>";
                    });
                    innerHtml += "</thead><tbody >";
      
                    bodyLines.forEach((body, i) => {
                      var colors = tooltipModel.labelColors[i];
                      var style = "background:" + colors.backgroundColor;
                      style += "; border-color:" + colors.borderColor;
                      style += "; border-width: 2px !important";
                      style += "; width: 10px !important";
                      style += "; height: 10px !important";
                      style += "; display: inline-block !important";
                      style += "; margin-right: 3px !important";
      
                      var box = `<span style="${style}"></span>`;
                      innerHtml += `<tr><td>${box}${body}</td></tr>`;
                    });
      
                    innerHtml += "</tbody>";
      
                    var tableRoot = tooltipEl.querySelector("table");
                    if (tableRoot) tableRoot.innerHTML = innerHtml;
                  }
      
                  // `this` will be the overall tooltip
                  var position = this.chart.canvas.getBoundingClientRect();
      
                  // Display, position, and set styles for font
                  tooltipEl.style.opacity = "1";
                  tooltipEl.style.position = "absolute";
                  tooltipEl.style.left =
                    position.left + window.pageXOffset + tooltipModel.caretX + "px";
                  tooltipEl.style.top =
                    position.top + window.pageYOffset + tooltipModel.caretY + "px";
                  tooltipEl.style.pointerEvents = "onhover"; // Use when need to stop mouse events such as Onhover and Scrolling
      
                  tooltipEl.style.borderColor = "blue";
                  tooltipEl.style.backgroundColor = "white";
                  tooltipEl.style.maxHeight = "100px";
                  tooltipEl.style.overflowY = "auto";
                  tooltipEl.style.scrollBehavior = "smooth";
      
                  tooltipEl.style.borderRadius = "8px";
                  tooltipEl.style.border = "2px solid #D8DEE6";
                  tooltipEl.style.boxShadow = "0px 4px 8px 0px rgba(0, 0, 0, 0.08)";
      
                  tooltipEl.style.fontSize = "12px";
                  tooltipEl.style.fontWeight = "700";
                  tooltipEl.style.lineHeight = "16px";
                  tooltipEl.style.letterSpacing = "0.24px";
      
                  tooltipEl.style.padding = "8px 16px";
                },
                enabled: false,
                bodyAlign: "center",
                callbacks: {
                  labelPointStyle: function (context) {
                    return {
                      pointStyle: "triangle",
                      rotation: 0,
                    };
                  },
                  title: function (data) {
                    return "";
                  },
                  labelColor: function ({ dataset }) {
                    return {
                      borderColor: `${dataset.borderColor}` || "rgb(0, 0, 255)",
                      backgroundColor:
                        `${dataset.borderColor}` || "rgb(255, 0, 0)",
                      borderWidth: 2,
                      borderRadius: 2,
                    };
                  },
                  afterLabel: function () {
                    return [""];
                  },
                },
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              r: {
                pointLabels: {
                  padding: 20,
                  borderRadius: 10,
                },
                beginAtZero: true,
                grid: {
                  circular: true,
                },
                ticks: {
                  display: false // Hides the labels in the middel (numbers)
                },
              },
            },
          }}
          data={{ labels, datasets: datasets }} //  TODO: Fix this any
        />
      </Stack>
    );
  }
  