import { Alert, Container, Grid, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";

// Firebase
import { database, storage } from "../../Firebase";
import { ref as dbRef, onValue, getDatabase, set } from "firebase/database";

// CUSTOM
import ComputeScoreResult, {
  ComputeScoreStatus, QuarterStatus,
} from "components/ComputeScoreResult";
import {
  AUTO100Ctx,
  AUTO100ScoreFileCategories,
  AUTO100ScoreFiles,
  FileUploadStatus,
  WorkEnvironment,
} from "../../AUTO100DataProvider";
import SubScoreWeights from "../../components/SubScoreWeights";
import PermissionDenied from "pages/General/AccessDenied";

import PageSection from "components/PageSection";
import PageFilters from "components/PageFilters";
import CustomizedTables from "components/CustomizedTable";
import BasicBreadcrumbs from "components/BreadCrumbsNavigation";
import { computeScores, downloadRawFiles as downloadRawFilesPayload, updateAnalyticsScore } from "API";
import { AuthCtx } from "AuthProvider";
import { getDateByTimestamp } from "utils";
import { CustomizedTableOptions } from "./CustomizedTableOptions";
import { getDownloadURL, ref } from "firebase/storage";

interface FileStatus {
  [key: string]: {
    [key: string]: {
      [key: string]: FileUploadStatus
    }
  };
}

function Production() {
  const db = getDatabase();
  const {
    permissions,
    selectedYear,
    selectedQuarter,
    selectedRebalancingOption,
  } = useContext(AUTO100Ctx);
  const { user } = useContext(AuthCtx);

  const [quarterStatus, setQuarterStatus] = useState<QuarterStatus>();
  const [computeStatus, setComputeStatus] = useState<ComputeScoreStatus>();

  const [availableFiles, setAvailableFiles] = useState<string[]>([]);
  const [readyToCompute, setReadyToCompute] = useState(false);

  const [filesStatus, setFilesStatus] = useState<FileStatus>({});
  useEffect(() => {
    if (selectedYear && selectedQuarter) {
      // setScoreFilesStatus({});
      let path = `AUTO100/${WorkEnvironment.Production}/${selectedYear}_Q${selectedQuarter}/preprocess_status`;
      let ref = dbRef(db, path);

      //  Get Quarter Status
      path = `AUTO100/${WorkEnvironment.Production}/${selectedRebalancingOption}/${selectedYear}_Q${selectedQuarter}/quarter_status`;
      ref = dbRef(db, path);
      const quarter_status_unsubscribe = onValue(ref, (snapshot) => {
        const data = snapshot.val() as QuarterStatus | undefined;
        if (data) {
          setQuarterStatus(data);
        } else {
          setQuarterStatus({ is_frozen: false });
        }
      });

      //  Get Validation Status of all the files
      path = `AUTO100/${WorkEnvironment.Production}/${selectedRebalancingOption}/${selectedYear}_Q${selectedQuarter}/compute_status`;
      ref = dbRef(db, path);
      const compute_unsubscribe = onValue(ref, (snapshot) => {
        const data = snapshot.val() as ComputeScoreStatus | undefined;
        if (data) {
          setComputeStatus(data);
        } else {
          setComputeStatus(undefined);
        }
      });

      //  Check which non-root files are uploaded
      path = `AUTO100/raw_files/${selectedRebalancingOption}/${selectedYear}_Q${selectedQuarter}/`;
      ref = dbRef(database, path);
      const score_files_status_unsubscribe = onValue(ref, (snapshot) => {
        const data = snapshot.val();
        const status = data as FileStatus | undefined;

        if (status) {
          setFilesStatus({ ...status });

          const _availableFiles: string[] = [];

          for (const scoreFile of AUTO100ScoreFiles) {
            const fileName = scoreFile.fileNameBuilder(
              selectedYear,
              selectedQuarter
            );
            const fileWithoutExtension = fileName.split(".")[0];
            if (
              fileWithoutExtension in status &&
              status[fileWithoutExtension].valid
            ) {
              _availableFiles.push(scoreFile.id);
            }
          }
          setAvailableFiles((_prevValue) => [..._availableFiles]);
        } else {
          setFilesStatus({});
          setReadyToCompute(false);
          setAvailableFiles([]);
        }
      });

      //  Check which root files are uploaded
      path = `AUTO100/raw_files/`;
      ref = dbRef(database, path);
      const root_score_files_status_unsubscribe = onValue(ref, (snapshot) => {
        const data = snapshot.val();
        const status = data as FileStatus | undefined;

        if (status) {
          setFilesStatus({ ...status });

          const _availableFiles: string[] = [];

          for (const scoreFile of AUTO100ScoreFiles) {
            const fileName = scoreFile.fileNameBuilder(
              selectedYear,
              selectedQuarter
            );
            const fileWithoutExtension = fileName.split(".")[0];
            if (
              fileWithoutExtension in status &&
              status[fileWithoutExtension].valid
            ) {
              _availableFiles.push(scoreFile.id);
            }
          }
          setAvailableFiles((_prevValue) => [..._prevValue, ..._availableFiles]);
        } else {
          setReadyToCompute(false);
        }
      });

      const unsub = () => {
        quarter_status_unsubscribe();
        compute_unsubscribe();
        score_files_status_unsubscribe();
        root_score_files_status_unsubscribe();
      };

      return unsub;
    }
  }, [selectedYear, selectedQuarter, db, selectedRebalancingOption]);

  useEffect(() => {
    if (
      selectedYear && 
      selectedQuarter && 
      selectedRebalancingOption && 
      filesStatus[selectedRebalancingOption] &&
      filesStatus[selectedRebalancingOption][`${selectedYear}_Q${selectedQuarter}`]
    ) {
      let _readyToCompute = true;
      for (const scoreFile of AUTO100ScoreFiles) {
        const fileWithoutExtension = scoreFile
          .fileNameBuilder(selectedYear, selectedQuarter)
          .split(".")[0] as string;
        if( scoreFile.isRootFile ){
          if (
            !filesStatus[fileWithoutExtension] ||
            !filesStatus[fileWithoutExtension].valid
          ) {
            _readyToCompute = false;
          }
        }else {
          if (
            !filesStatus[selectedRebalancingOption][`${selectedYear}_Q${selectedQuarter}`][fileWithoutExtension] ||
            !filesStatus[selectedRebalancingOption][`${selectedYear}_Q${selectedQuarter}`][fileWithoutExtension].valid
          ) {
            _readyToCompute = false;
          }
        }

      }
      setReadyToCompute(_readyToCompute);
    }
  }, [filesStatus, selectedYear, selectedQuarter, selectedRebalancingOption]);


  const downloadRawFiles = async () => {
    if( !selectedYear || !selectedQuarter || !selectedRebalancingOption ){
      return
    }
    const response = await downloadRawFilesPayload({year: selectedYear, quarter: selectedQuarter, rebalancing_option: selectedRebalancingOption})
    if( response?.error ) return

    const DownloadName = `solactive_${selectedYear}_Q${selectedQuarter}_Raw.zip`;
    const _downloadURL = await getDownloadURL(
      ref(
        storage,
        `AUTO100/raw_files/${selectedRebalancingOption}/${selectedYear}_Q${selectedQuarter}/${DownloadName}`
      )
    );
    if (_downloadURL) {
      //  create fake link
      const link = document.createElement("a");
      link.download = DownloadName;
      link.href = _downloadURL;
      link.click();
    }
  }

  // HANDLERS
  const handleSelectedOption = (option: string) => {
    if (option === "download_raw_files") {
      downloadRawFiles();
    }
  };
  const handleComputeScores = async () => {
    // setComputeStatus(ComputeStatusTypes.ComputeScoresInProgress);
    await computeScores({
      year: selectedYear,
      quarter: selectedQuarter,
      work_environment: WorkEnvironment.Production,
      rebalancing_option: selectedRebalancingOption,
    });
  };
  const freezeQuarter = async () => {
    if( !selectedRebalancingOption || !selectedQuarter || !selectedYear ) return
    const freezeInfoRef = dbRef(
      db,
      `AUTO100/${WorkEnvironment.Production}/${selectedRebalancingOption}/${selectedYear}_Q${selectedQuarter}/quarter_status`
    );
    await set(freezeInfoRef, {
      is_frozen: true,
      frozen_by: user?.uid,
      frozen_timestamp: Date.now()
    });

    //  update analytics
    await updateAnalyticsScore({
      rebalancing_option: selectedRebalancingOption,
      quarter: selectedQuarter,
      year: selectedYear})
  };

  if (!permissions) {
    return null;
  }

  if (!permissions[WorkEnvironment.Production]) {
    return <PermissionDenied />;
  }

  return (
    <Container maxWidth={"lg"} component={Stack} spacing={2} py={2}>
      {/* {permissions && <NavigationBar links={links} />} */}
      {/* <Stack sx={{ mb: 10 }} direction={"row"} spacing={1}>
        <BasicBreadcrumbs
          currentPage="Production"
          pathInfo={[{ label: "Auto100 Home", url: "/" }]}
        />
      </Stack> */}

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                     SCORE & DATES SELECTION                    //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}
      <PageSection title="Start Rebalancing for:">
        <PageFilters />
      </PageSection>

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                           ALERT BOX                            //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {quarterStatus?.is_frozen && quarterStatus.frozen_timestamp && <Alert
        severity="info"
        icon={false}
        sx={{ width: "100%", borderRadius: 0 }}
      >
        <Typography sx={{ textAlign: "left" }}>
          This quarter had been frozen on {getDateByTimestamp(quarterStatus.frozen_timestamp)}
        </Typography>
      </Alert>}

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                           INPUT FILES                          //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {
        <PageSection title="Input Data">
          {selectedYear && selectedQuarter && selectedRebalancingOption && (
            <Grid container spacing={2} marginTop={0}>
              <Stack
                direction="row"
                sx={{ position: "absolute", right: 20, top: 20, borderRadius: 2 }}
              >
                <CustomizedTableOptions onOptionSelected={handleSelectedOption} />
              </Stack>
              {AUTO100ScoreFileCategories.map((category, index) => (
                <CustomizedTables
                  key={"grid_item_" + index}
                  year={selectedYear}
                  quarter={selectedQuarter}
                  workEnvironment={WorkEnvironment.Production}
                  rebalancingOption={selectedRebalancingOption}
                  scoreFiles={AUTO100ScoreFiles.filter(
                    (file) => file.category === category
                  )}
                  availableFiles={[...availableFiles]}
                  isFrozen={quarterStatus?.is_frozen}
                />
              ))}
            </Grid>
          )}
        </PageSection>
      }

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                           SCORE WEIGHTS                        //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}
      <PageSection title="Weights">
        <SubScoreWeights workEnvironment={WorkEnvironment.Production} isFrozen={quarterStatus?.is_frozen} />
      </PageSection>

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                          COMPUTE SCORES                        //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <PageSection title="Compute Scores">
        <ComputeScoreResult
          workEnvironment={WorkEnvironment.Production}
          computeStatus={computeStatus}
          onCompute={handleComputeScores}
          readyToCompute={readyToCompute}
          onFreezeQuarter={freezeQuarter}
          isFrozen={quarterStatus?.is_frozen}
        />
      </PageSection>
    </Container>
  );
}

export default Production;
