import {
  Alert,
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useCallback, useMemo } from "react";
import { toPng } from "html-to-image";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// CUSTOM
import { AUTO100Ctx, RebalancingOption } from "../../AUTO100DataProvider";
import { RadarChart } from "pages/Playground/RadarChart";
import { Datasets } from "types";
import { mixColors } from "utils";
import ColorBadge from "components/color-badge";
import SectionDescription from "components/section-description";
import SectionTitle from "components/section-title";
import Section from "components/section/Index";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

type IRadarChartContainerProps = {
  id: string;
  title: string;
  description: string;
  onExport: (id: string) => void;
  children: JSX.Element;
};
function RadarChartContainer({
  id,
  title,
  description,
  onExport,
  children,
}: IRadarChartContainerProps) {
  return (
    <Stack sx={{ flex: 1 }}>
      <OpenInNewIcon
        onClick={() => onExport("aggregate-company-deepdive-radar-chart")}
        fontSize={"small"}
        sx={{ color: "gray", cursor: "pointer", position: "absolute", right: "16px", top: "16px" }}
      />

      <Stack
        flex={1}
        id="aggregate-company-deepdive-radar-chart"
      >
        <SectionTitle text={title} />
        <SectionDescription text={description} />
        {children}
      </Stack>
    </Stack>
  );
}

function CompanyDeepdiveAggregateRadarCharts() {

  const {
    analytics: {
      categories,
      regions,
      scores,
      companies,
      userPreference: { deepdivePreferences },
    },
    permissions,
  } = useContext(AUTO100Ctx);
  const quarter = useMemo(() => "2022_Q4", []);

  const scoreKeys = {
    aggregate: ["Relationship_score", "Sentiment_score", "Strategy_score"],
    relationship: [
      "by_relationship_r-oi-1",
      "by_relationship_r-oi-2",
      "by_relationship_r-om-1",
      "by_relationship_r-om-2",
      "by_relationship_r-or-1",
      "by_relationship_r-or-2",
      "by_relationship_r-or-3",
      "by_relationship_r-so-1",
      "by_relationship_r-so-2",
      "by_relationship_r-so-3",
    ],
    strategy: [
      "by_strategy_st-1",
      "by_strategy_st-10",
      "by_strategy_st-11",
      "by_strategy_st-12",
      "by_strategy_st-2",
      "by_strategy_st-3",
      "by_strategy_st-4",
      "by_strategy_st-5",
      "by_strategy_st-6",
      "by_strategy_st-7",
    ],
    sentiment: ["by_sentiment_se-f-1", "by_sentiment_se-l-1", ""],
  };
  const friendlyNames : {[key: string]: string} = {
    "Relationship_score": "Relationship",
    "Sentiment_score": "Sentiment",
    "Strategy_score": "Strategy",
  }
  const friendlyLabels = useMemo(() => {
    return {
      "aggregate": scoreKeys.aggregate.map(value => friendlyNames[value]),
    }
  }, [scoreKeys, friendlyNames])

  const average = (arr: Array<number>) =>
    arr.reduce((p, c) => p + c, 0) / arr.length;

  type ArrayElement<ArrayType extends readonly unknown[]> =
    ArrayType extends readonly (infer ElementType)[] ? ElementType : never;
  const labelRenderer = useCallback(
    (label: string, dataset: ArrayElement<Datasets>) => {
      if (!scores) return "";
      const result = {
        [RebalancingOption.Solactive]: 0,
        [RebalancingOption.WisdomTree]: 0,
      };

      if (dataset.id.includes("Category") && dataset.id.includes("Region")) {
        const ids = dataset.id.split("-");
        const regionId = ids[0].includes("Region") ? ids[0] : ids[1];
        const categoryId = ids[0].includes("Category") ? ids[0] : ids[1];

        const category = categories.find(
          (_category) => _category.id === categoryId
        );
        const region = regions.find((_region) => _region.id === regionId);

        if (category && region) {
          const _companies = companies.filter(
            (company) =>
              company.Category === category.name.toLowerCase() &&
              company.Region === region.name.toLowerCase()
          );
          let collectiveScores: { [key: string]: Array<number> } = {
            [RebalancingOption.Solactive]: [],
            [RebalancingOption.WisdomTree]: [],
          };
          _companies.forEach((company) => {
            if (
              scores[RebalancingOption.Solactive]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== undefined &&
              scores[RebalancingOption.Solactive]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== null
            ) {
              collectiveScores[RebalancingOption.Solactive].push(
                scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                  quarter
                ]?.[label]
              );
            }
            if (
              scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== undefined &&
              scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== null
            ) {
              collectiveScores[RebalancingOption.WisdomTree].push(
                scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[
                  quarter
                ]?.[label]
              );
            }
          });
          result[RebalancingOption.Solactive] = average(
            collectiveScores[RebalancingOption.Solactive]
          );
          result[RebalancingOption.WisdomTree] = average(
            collectiveScores[RebalancingOption.WisdomTree]
          );
        } else {
          result[RebalancingOption.Solactive] = NaN;
          result[RebalancingOption.WisdomTree] = NaN;
        }
      } else if (dataset.id.includes("Category")) {
        const ids = dataset.id.split("-");
        const categoryId = ids[0].includes("Category") ? ids[0] : ids[1];

        const category = categories.find(
          (_category) => _category.id === categoryId
        );

        if (category) {
          const _companies = companies.filter(
            (company) => company.Category === category.name.toLowerCase()
          );
          let collectiveScores: { [key: string]: Array<number> } = {
            [RebalancingOption.Solactive]: [],
            [RebalancingOption.WisdomTree]: [],
          };
          _companies.forEach((company) => {
            if (
              scores[RebalancingOption.Solactive]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== undefined &&
              scores[RebalancingOption.Solactive]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== null
            ) {
              collectiveScores[RebalancingOption.Solactive].push(
                scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                  quarter
                ]?.[label]
              );
            }
            if (
              scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== undefined &&
              scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== null
            ) {
              collectiveScores[RebalancingOption.WisdomTree].push(
                scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[
                  quarter
                ]?.[label]
              );
            }
          });
          result[RebalancingOption.Solactive] = average(
            collectiveScores[RebalancingOption.Solactive]
          );
          result[RebalancingOption.WisdomTree] = average(
            collectiveScores[RebalancingOption.WisdomTree]
          );
        } else {
          result[RebalancingOption.Solactive] = NaN;
          result[RebalancingOption.WisdomTree] = NaN;
        }
      } else if (dataset.id.includes("Region")) {
        const ids = dataset.id.split("-");
        const regionId = ids[0].includes("Region") ? ids[0] : ids[1];

        const region = regions.find((_region) => _region.id === regionId);

        if (region) {
          const _companies = companies.filter(
            (company) => company.Region === region.name.toLowerCase()
          );
          let collectiveScores: { [key: string]: Array<number> } = {
            [RebalancingOption.Solactive]: [],
            [RebalancingOption.WisdomTree]: [],
          };
          _companies.forEach((company) => {
            if (
              scores[RebalancingOption.Solactive]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== undefined &&
              scores[RebalancingOption.Solactive]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== null
            ) {
              collectiveScores[RebalancingOption.Solactive].push(
                scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                  quarter
                ]?.[label]
              );
            }
            if (
              scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== undefined &&
              scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[quarter]?.[
                label
              ] !== null
            ) {
              collectiveScores[RebalancingOption.WisdomTree].push(
                scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[
                  quarter
                ]?.[label]
              );
            }
          });
          result[RebalancingOption.Solactive] = average(
            collectiveScores[RebalancingOption.Solactive]
          );
          result[RebalancingOption.WisdomTree] = average(
            collectiveScores[RebalancingOption.WisdomTree]
          );
        } else {
          result[RebalancingOption.Solactive] = NaN;
          result[RebalancingOption.WisdomTree] = NaN;
        }
      } else {
        result[RebalancingOption.Solactive] = NaN;
        result[RebalancingOption.WisdomTree] = NaN;
      }

      return [
        ` ${label} (${dataset.label})`,
      ];
    },
    [categories, companies, quarter, regions, scores]
  );
  const parsedScores = useCallback(
    (companyScoreKeys: Array<string>) => {
      if (scores) {
        const _scores: Datasets = [];

        (deepdivePreferences?.company ?? []).forEach((company) => {
          _scores.push({
            label: company.Name,
            data: companyScoreKeys.map(
              (scoreKey) =>
                scores[RebalancingOption.Solactive]?.[company?.ISIN]?.[
                  quarter
                ]?.[scoreKey] ?? 0
            ),
            borderColor: company.Color,
            backgroundColor: "transparent",
            borderWidth: 2,
            id: company.ISIN,
          });
        });

        const bawaRegions = regions
          .filter((region) =>
            (deepdivePreferences?.region ?? []).includes(region.id)
          )
          .map((region) => region.name.toLowerCase());
        const bawaCategories = categories
          .filter((category) =>
            (deepdivePreferences?.segment ?? []).includes(category.id)
          )
          .map((category) => category.name.toLowerCase());

        if (bawaRegions.length && !bawaCategories.length) {
          const regionScores: { [id: string]: Array<number> } = {};
          regions
            .filter((region) =>
              (deepdivePreferences?.region ?? []).includes(region.id)
            )
            .forEach((region) => {
              const _companies = companies.filter(
                (company) => company.Region === region.name.toLowerCase()
              );
              companyScoreKeys.forEach((scoreKey) => {
                let quarterScores: Array<number> = [];
                _companies.forEach((company) => {
                  if (
                    scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                      quarter
                    ]?.[scoreKey] !== undefined &&
                    scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                      quarter
                    ]?.[scoreKey] !== null
                  ) {
                    quarterScores.push(
                      scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                        quarter
                      ]?.[scoreKey]
                    );
                  }
                });
                if (!regionScores[region.id]) {
                  regionScores[region.id] = [];
                }
                if (quarterScores.length) {
                  regionScores[region.id].push(average(quarterScores));
                }
              });
              _scores.push({
                label: region.name,
                data: regionScores[region.id],
                borderColor: region.Color,
                backgroundColor: "transparent",
                borderWidth: 2,
                id: region.id,
              });
            });
        } else if (!bawaRegions.length && bawaCategories.length) {
          const categoryScores: { [id: string]: Array<number> } = {};
          categories
            .filter((category) =>
              (deepdivePreferences?.segment ?? []).includes(category.id)
            )
            .forEach((category) => {
              const _companies = companies.filter(
                (company) => company.Category === category.name.toLowerCase()
              );

              companyScoreKeys.forEach((scoreKey) => {
                let quarterScores: Array<number> = [];
                _companies.forEach((company) => {
                  if (
                    scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                      quarter
                    ]?.[scoreKey] !== undefined &&
                    scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                      quarter
                    ]?.[scoreKey] !== null
                  ) {
                    quarterScores.push(
                      scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                        quarter
                      ]?.[scoreKey]
                    );
                  }
                });
                if (!categoryScores[category.id]) {
                  categoryScores[category.id] = [];
                }
                if (quarterScores.length) {
                  categoryScores[category.id].push(average(quarterScores));
                }
              });
              _scores.push({
                label: category.name,
                data: categoryScores[category.id],
                borderColor: category.Color,
                backgroundColor: "transparent",
                borderWidth: 2,
                id: category.id,
              });
            });
        } else if (bawaRegions.length && bawaCategories.length) {
          const combinedScores: { [id: string]: Array<number> } = {};
          categories
            .filter((category) =>
              (deepdivePreferences?.segment ?? []).includes(category.id)
            )
            .forEach((category) => {
              regions
                .filter((region) =>
                  (deepdivePreferences?.region ?? []).includes(region.id)
                )
                .forEach((region) => {
                  const _companies = companies.filter(
                    (company) =>
                      company.Category === category.name.toLowerCase() &&
                      company.Region === region.name.toLowerCase()
                  );
                  const id = `${region.id}-${category.id}`;

                  companyScoreKeys.forEach((scoreKey) => {
                    let quarterScores: Array<number> = [];
                    _companies.forEach((company) => {
                      if (
                        scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                          quarter
                        ]?.[scoreKey] !== undefined &&
                        scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                          quarter
                        ]?.[scoreKey] !== null
                      ) {
                        quarterScores.push(
                          scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                            quarter
                          ]?.[scoreKey]
                        );
                      }
                    });
                    if (!combinedScores[id]) {
                      combinedScores[id] = [];
                    }
                    if (quarterScores.length) {
                      combinedScores[id].push(average(quarterScores));
                    }
                  });
                  _scores.push({
                    label: `${category.label} (${region.label})`,

                    data: combinedScores[id],
                    borderColor: mixColors(category.Color, region.Color),
                    backgroundColor: "transparent",
                    borderWidth: 2,
                    id: id,
                  });
                });
            });
        }

        return _scores;
      }
      return [];
    },
    [
      categories,
      companies,
      deepdivePreferences?.company,
      deepdivePreferences?.region,
      deepdivePreferences?.segment,
      quarter,
      regions,
      scores,
    ]
  );

  if (!permissions) {
    return <>Loading</>;
  }

  const handleExport = async (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const link = document.createElement("a");
      link.download = `${quarter}_Company Performance Radar`;
      link.href = await toPng(element);
      link.click();
    }
  };

  return (
    <Section style={{flex: 2}}>
      <RadarChartContainer
          id={"aggregate-company-deepdive-radar-chart"}
          title={"Aggregate Scores"}
          description="We assess our A100 universe companies through a combination of our 3 Berylls scores (Strategy, Relationship and Sentiment)."
          onExport={handleExport}
        >
          <RadarChart
            labelRenderer={(label: string, dataset) =>
              labelRenderer(label, dataset)
            }
            labels={friendlyLabels.aggregate}
            title={"Aggregate Scores"}
            datasets={parsedScores(scoreKeys.aggregate)}
            isAggregate={true}
          />
        </RadarChartContainer>
        <Stack flexDirection="row" flexWrap="wrap" gap={1} mt={1}>
          {
            parsedScores(scoreKeys.aggregate).map(dataset => (
                <ColorBadge color={dataset.borderColor} title={dataset.label} />
            ))
          }
        </Stack>
    </Section>
  );
}

export default CompanyDeepdiveAggregateRadarCharts;
