import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { ExpandMore, AccountCircle } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import colors from "themes/colors";

import Auto100Logo from "assets/Logo.png";
import { Stack, Typography } from "@mui/material";
import { User, signOut, getAuth } from "firebase/auth";
import { AUTO100Ctx, WorkEnvironment } from "AUTO100DataProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const NavigationBar = ({ user }: { user: User | null }) => {
  const {
    permissions,
    selectedYear,
    selectedQuarter,
    selectedRebalancingOption,
  } = React.useContext(AUTO100Ctx);

  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const storage = getStorage();

  const [imageURL, setImageURL] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const currentRootPath = React.useMemo(() => {
    const { pathname } = location;
    if (pathname.split("/")[0] === "") {
      return pathname.split("/")[1];
    }
    return pathname.split("/")[0];
  }, [location]);

  const fetchImageURL = async (uid: string) => {
    try {
      const downloadURL = await getDownloadURL(
        ref(
          storage,
          `user_images/${auth.currentUser?.uid}/${auth.currentUser?.uid}.jpg`
        )
      );
      setImageURL(downloadURL);
    } catch (error) {
      return "";
    }
  };
  React.useEffect(() => {
    if (auth.currentUser) {
      fetchImageURL(auth.currentUser.uid);
    }
  }, []);

  const getAdminNavigationOption = () => {
    if (!permissions?.[WorkEnvironment.Production]) return;

    if (currentRootPath === "playground") {
      return (
        <MenuItem onClick={() => navigate("/production")}>Production</MenuItem>
      );
    } else if (currentRootPath === "production") {
      return (
        <MenuItem onClick={() => navigate("/playground/company-deepdive")}>
          Playground
        </MenuItem>
      );
    }
    return null;
  };

  const handleMenuSelect = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = async (event: React.MouseEvent<HTMLElement>) => {
    await signOut(auth);
    handleClose();
    navigate("/login");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        style={{ backgroundColor: colors.neutrals.extraDarkGray }}
      >
        <Toolbar>
          <div style={{ flexGrow: 1 }}>
            <img src={Auto100Logo} style={{ width: "120px", height: "20px" }} />
          </div>
          <Stack flexDirection="row">
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              {imageURL ? (
                <img
                  height="30"
                  width="30"
                  style={{ borderRadius: "48px", }}
                  src={imageURL}
                  alt={"Profile"}
                />
              ) : (
                <AccountCircle sx={{ height: 35, width: 35 }} />
              )}
            </IconButton>
            <Stack justifyContent="center" alignItems="flex-start">
              <Typography
                style={{
                  color: "#fff",
                  fontSize: 14,
                  fontWeight: "600",
                  lineHeight: "16px",
                }}
              >
                {user?.displayName}
              </Typography>
              <Typography
                style={{
                  color: "#64758B",
                  fontSize: "10px",
                  fontWeight: "600",
                  lineHeight: "16px",
                  letterSpacing: "0.1px",
                }}
              >
                {user?.email}
              </Typography>
            </Stack>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenuSelect}
              color="inherit"
            >
              <ExpandMore style={{ color: "#64758B" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {getAdminNavigationOption()}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavigationBar;
