import { Stack, Typography } from "@mui/material"
import { getFinancialInformation } from "API"
import { useEffect, useMemo, useState } from "react"
import { RadarChart } from "./RadarChart"
import { FinancialInformation as FinancialInformationData } from "types"
import Section from "components/section/Index"
import ColorBadge from "components/color-badge"

type FinancialInformationProps = {
  selectedYear: string,
  selectedQuarter: string
  isins: Array<string>
}

export const FinancialInformation = ({isins, selectedYear, selectedQuarter }: FinancialInformationProps) => {

    const [financialInformation, setFinancialInformation] = useState<Array<FinancialInformationData>>()

    const datasets = useMemo(() => {
        return (financialInformation ?? []).map(finance => {
            // ebitda: string
            // ebitdaratio: number
            // eps: number
            // grossProfit: number
            // netIncome: number
            // revenue: number
            return {
                backgroundColor: "transparent",
                borderColor: finance.color,
                borderWidth: 2,
                data: [finance.ebitda,finance.ebitdaratio,finance.eps,finance.grossProfit,finance.netIncome, finance.revenue],

                id: finance.ISIN,
                label: finance.Name,
            }
        })
    }, [financialInformation])

    const getData = async () => {
        if( isins ){
            const result = await getFinancialInformation({quarter: `Q${selectedQuarter}`, year: `${selectedYear}`, isins: isins})
            setFinancialInformation(result.data ?? [])
        }
    }

    useEffect(() => {
        getData()
    },[isins, selectedQuarter, selectedYear])

    return (
        <Section style={{ justifyContent: "flex-start" }}>
          <Typography sx={{ textAlign: "left" }} variant="h6">
            Financial Radar Chart
          </Typography>

          <Stack flexDirection="row" flex={1} sx={{minHeight: '300px', width: "100%"}}>
              <Stack sx={{ flex: 5 }}>
                  <RadarChart 
                    labels={["ebitda", "ebitdaratio", "eps", "grossProfit", "netIncome", "revenue"]} 
                    datasets={datasets}
                    isAggregate
                  />
              </Stack>
          </Stack>
          <Stack flexDirection="row" flexWrap="wrap" gap={1} mt={1}>
              {
                  datasets.map(dataset => (
                      <ColorBadge color={dataset.borderColor} title={dataset.label} />
                  ))
              }
          </Stack>
        </Section>
    )
}