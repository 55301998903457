import { Alert, Grid, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useCallback, useMemo } from "react";
import { toPng } from "html-to-image";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// CUSTOM
import { AUTO100Ctx, RebalancingOption } from "../../AUTO100DataProvider";
import { LineChart } from "pages/Playground/LineChart";
import { Datasets } from "types";
import { getQuarters, mixColors, removeAllBefore } from "utils";
import ColorBadge from "components/color-badge";
import SectionDescription from "components/section-description";
import SectionTitle from "components/section-title";
import Section from "components/section/Index";

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;
function CompanyDeepdiveLineChart() {
  const {
    analytics: {
      categories,
      regions,
      scores,
      companies,
      userPreference: { deepdivePreferences },
    },
    permissions,
  } = useContext(AUTO100Ctx);
  const quarters = removeAllBefore(
    getQuarters({ startYear: 2021 }).reverse(),
    "2021_Q4"
  );
  const average = (arr: Array<number>) =>
    arr.reduce((p, c) => p + c, 0) / arr.length;

  const labelRenderer = useCallback(
    (label: string, dataset: ArrayElement<Datasets>) => {
      if (!scores) return "";
      const result = {
        [RebalancingOption.Solactive]: 0,
        [RebalancingOption.WisdomTree]: 0,
      };

      if (dataset.id.includes("Category") && dataset.id.includes("Region")) {
        const ids = dataset.id.split("-");
        const regionId = ids[0].includes("Region") ? ids[0] : ids[1];
        const categoryId = ids[0].includes("Category") ? ids[0] : ids[1];

        const category = categories.find(
          (_category) => _category.id === categoryId
        );
        const region = regions.find((_region) => _region.id === regionId);

        if (category && region) {
          const _companies = companies.filter(
            (company) =>
              company.Category === category.name.toLowerCase() &&
              company.Region === region.name.toLowerCase()
          );
          let collectiveScores: { [key: string]: Array<number> } = {
            [RebalancingOption.Solactive]: [],
            [RebalancingOption.WisdomTree]: [],
          };
          _companies.forEach((company) => {
            if (
              scores[RebalancingOption.Solactive]?.[company.ISIN]?.[label]
                ?.Industry_score !== undefined &&
              scores[RebalancingOption.Solactive]?.[company.ISIN]?.[label]
                ?.Industry_score !== null
            ) {
              collectiveScores[RebalancingOption.Solactive].push(
                scores[RebalancingOption.Solactive]?.[company.ISIN]?.[label]
                  ?.Industry_score
              );
            }
            if (
              scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[label]
                ?.Industry_score !== undefined &&
              scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[label]
                ?.Industry_score !== null
            ) {
              collectiveScores[RebalancingOption.WisdomTree].push(
                scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[label]
                  ?.Industry_score
              );
            }
          });
          result[RebalancingOption.Solactive] = average(
            collectiveScores[RebalancingOption.Solactive]
          );
          result[RebalancingOption.WisdomTree] = average(
            collectiveScores[RebalancingOption.WisdomTree]
          );
        } else {
          result[RebalancingOption.Solactive] = NaN;
          result[RebalancingOption.WisdomTree] = NaN;
        }
      } else if (dataset.id.includes("Category")) {
        const ids = dataset.id.split("-");
        const categoryId = ids[0].includes("Category") ? ids[0] : ids[1];

        const category = categories.find(
          (_category) => _category.id === categoryId
        );

        if (category) {
          const _companies = companies.filter(
            (company) => company.Category === category.name.toLowerCase()
          );
          let collectiveScores: { [key: string]: Array<number> } = {
            [RebalancingOption.Solactive]: [],
            [RebalancingOption.WisdomTree]: [],
          };
          _companies.forEach((company) => {
            if (
              scores[RebalancingOption.Solactive]?.[company.ISIN]?.[label]
                ?.Industry_score !== undefined &&
              scores[RebalancingOption.Solactive]?.[company.ISIN]?.[label]
                ?.Industry_score !== null
            ) {
              collectiveScores[RebalancingOption.Solactive].push(
                scores[RebalancingOption.Solactive]?.[company.ISIN]?.[label]
                  ?.Industry_score
              );
            }
            if (
              scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[label]
                ?.Industry_score !== undefined &&
              scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[label]
                ?.Industry_score !== null
            ) {
              collectiveScores[RebalancingOption.WisdomTree].push(
                scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[label]
                  ?.Industry_score
              );
            }
          });
          result[RebalancingOption.Solactive] = average(
            collectiveScores[RebalancingOption.Solactive]
          );
          result[RebalancingOption.WisdomTree] = average(
            collectiveScores[RebalancingOption.WisdomTree]
          );
        } else {
          result[RebalancingOption.Solactive] = NaN;
          result[RebalancingOption.WisdomTree] = NaN;
        }
      } else if (dataset.id.includes("Region")) {
        const ids = dataset.id.split("-");
        const regionId = ids[0].includes("Region") ? ids[0] : ids[1];

        const region = regions.find((_region) => _region.id === regionId);

        if (region) {
          const _companies = companies.filter(
            (company) => company.Region === region.name.toLowerCase()
          );
          let collectiveScores: { [key: string]: Array<number> } = {
            [RebalancingOption.Solactive]: [],
            [RebalancingOption.WisdomTree]: [],
          };
          _companies.forEach((company) => {
            if (
              scores[RebalancingOption.Solactive]?.[company.ISIN]?.[label]
                ?.Industry_score !== undefined &&
              scores[RebalancingOption.Solactive]?.[company.ISIN]?.[label]
                ?.Industry_score !== null
            ) {
              collectiveScores[RebalancingOption.Solactive].push(
                scores[RebalancingOption.Solactive]?.[company.ISIN]?.[label]
                  ?.Industry_score
              );
            }
            if (
              scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[label]
                ?.Industry_score !== undefined &&
              scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[label]
                ?.Industry_score !== null
            ) {
              collectiveScores[RebalancingOption.WisdomTree].push(
                scores[RebalancingOption.WisdomTree]?.[company.ISIN]?.[label]
                  ?.Industry_score
              );
            }
          });
          result[RebalancingOption.Solactive] = average(
            collectiveScores[RebalancingOption.Solactive]
          );
          result[RebalancingOption.WisdomTree] = average(
            collectiveScores[RebalancingOption.WisdomTree]
          );
        } else {
          result[RebalancingOption.Solactive] = NaN;
          result[RebalancingOption.WisdomTree] = NaN;
        }
      } else {
        result[RebalancingOption.Solactive] = NaN;
        result[RebalancingOption.WisdomTree] = NaN;
      }

      return [
        ` ${label} (${dataset.label})`,
      ];
    },
    [categories, companies, regions, scores]
  );
  const parsedScores = useMemo(() => {
    if (scores) {
      const _scores: Datasets = [];

      (deepdivePreferences?.company ?? []).forEach((company) => {
        _scores.push({
          label: company.Name,
          data: quarters.map(
            (quarter) =>
              scores[RebalancingOption.Solactive]?.[company?.ISIN]?.[quarter]
                ?.Industry_score ?? 0
          ),
          borderColor: company.Color,
          backgroundColor: company.Color,
          borderWidth: 2,
          pointRadius: 2,
          id: company.ISIN,
        });
      });

      const collectiveRegions = regions
        .filter((region) =>
          (deepdivePreferences?.region ?? []).includes(region.id)
        )
        .map((region) => region.name.toLowerCase());
      const collectiveCategories = categories
        .filter((category) =>
          (deepdivePreferences?.segment ?? []).includes(category.id)
        )
        .map((category) => category.name.toLowerCase());

      if (collectiveRegions.length && !collectiveCategories.length) {
        const regionScores: { [id: string]: Array<number> } = {};
        regions
          .filter((region) =>
            (deepdivePreferences?.region ?? []).includes(region.id)
          )
          .forEach((region) => {
            const _companies = companies.filter(
              (company) => company.Region === region.name.toLowerCase()
            );

            quarters.forEach((quarter) => {
              let quarterScores: Array<number> = [];
              _companies.forEach((company) => {
                if (
                  scores[RebalancingOption.Solactive]?.[company.ISIN]?.[quarter]
                    ?.Industry_score
                ) {
                  quarterScores.push(
                    scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                      quarter
                    ]?.Industry_score
                  );
                }
              });
              if (!regionScores[region.id]) {
                regionScores[region.id] = [];
              }
              regionScores[region.id].push(average(quarterScores));
            });
            _scores.push({
              label: region.label,
              data: regionScores[region.id],
              borderColor: region.Color,
              backgroundColor: region.Color,
              borderWidth: 2,
              pointRadius: 2,
              id: region.id,
            });
          });
      } else if (!collectiveRegions.length && collectiveCategories.length) {
        const categoryScores: { [id: string]: Array<number> } = {};
        categories
          .filter((category) =>
            (deepdivePreferences?.segment ?? []).includes(category.id)
          )
          .forEach((category) => {
            const _companies = companies.filter(
              (company) => company.Category === category.name.toLowerCase()
            );

            quarters.forEach((quarter) => {
              let quarterScores: Array<number> = [];
              _companies.forEach((company) => {
                if (
                  scores[RebalancingOption.Solactive]?.[company.ISIN]?.[quarter]
                    ?.Industry_score
                ) {
                  quarterScores.push(
                    scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                      quarter
                    ]?.Industry_score
                  );
                }
              });
              if (!categoryScores[category.id]) {
                categoryScores[category.id] = [];
              }
              categoryScores[category.id].push(average(quarterScores));
            });
            _scores.push({
              label: category.label,
              data: categoryScores[category.id],
              borderColor: category.Color,
              backgroundColor: category.Color,
              borderWidth: 2,
              pointRadius: 2,
              id: category.id,
            });
          });
      } else if (collectiveRegions.length && collectiveCategories.length) {
        const combinedScores: { [id: string]: Array<number> } = {};
        categories
          .filter((category) =>
            (deepdivePreferences?.segment ?? []).includes(category.id)
          )
          .forEach((category) => {
            regions
              .filter((region) =>
                (deepdivePreferences?.region ?? []).includes(region.id)
              )
              .forEach((region) => {
                const _companies = companies.filter(
                  (company) =>
                    company.Category === category.name.toLowerCase() &&
                    company.Region === region.name.toLowerCase()
                );

                const id = `${region.id}-${category.id}`;

                quarters.forEach((quarter) => {
                  let quarterScores: Array<number> = [];
                  _companies.forEach((company) => {
                    if (
                      scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                        quarter
                      ]?.Industry_score
                    ) {
                      quarterScores.push(
                        scores[RebalancingOption.Solactive]?.[company.ISIN]?.[
                          quarter
                        ]?.Industry_score
                      );
                    }
                  });
                  if (!combinedScores[id]) {
                    combinedScores[id] = [];
                  }
                  combinedScores[id].push(average(quarterScores));
                });
                _scores.push({
                  label: `${category.label} (${region.label})`,
                  data: combinedScores[id],
                  borderColor: mixColors(category.Color, region.Color),
                  backgroundColor: mixColors(category.Color, region.Color),
                  borderWidth: 2,
                  pointRadius: 2,
                  id: id,
                });
              });
          });
      }

      return _scores;
    }
    return [];
  }, [
    categories,
    companies,
    deepdivePreferences?.company,
    deepdivePreferences?.region,
    deepdivePreferences?.segment,
    quarters,
    regions,
    scores,
  ]);

  useEffect(() => {}, []);

  if (!permissions) {
    return <>Loading</>;
  }

  const handleExport = async () => {
    const element = document.getElementById("company-deepdive-line-chart");
    if (element) {
      const link = document.createElement("a");
      link.download = `Company Performance`;
      link.href = await toPng(element);
      link.click();
    }
  };

  return (
    <Section 
      style={{ flex: 1 }}
      rightIcon={<OpenInNewIcon />}
      onRightIconClick={handleExport}
      title="Berylls Industry Score"
      description="We believe important decisions need to be data-driven. Often enough, though, data is still substituted by gut feeling. 
      Our automotive industry score solves this problem: By quantifying strategy, value networks, and sentiment of the most relevant industry players."
    >
      {/* <OpenInNewIcon
        onClick={handleExport}
        fontSize={"small"}
        sx={{ color: "gray", marginRight: 2, cursor: "pointer", position: "absolute", right: "16px", top: "16px" }}
      /> */}
      <Stack width="100%" flex={1} sx={{ mt: "24px" }}>
        <LineChart
          labelRenderer={(label: string, dataset) =>
            labelRenderer(label, dataset)
          }
          defaultRebalancingOption={RebalancingOption.Solactive}
          datasets={parsedScores}
          quarters={quarters}
        />
        <Stack flexDirection="row" flexWrap="wrap" gap={1} mt={1}>
          {
            parsedScores.map(dataset => (
              <ColorBadge color={dataset.borderColor} title={dataset.label} />
          ))}
        </Stack>
      </Stack>
    </Section>
  );
}

export default CompanyDeepdiveLineChart;
