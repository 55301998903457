import { Typography } from "@mui/material"

type SectionSectionTitleProps = {
    text: string
    textAlign?: string
}

const SectionTitle = ({text, textAlign = "left" }: SectionSectionTitleProps) => {
    return <Typography sx={{ textAlign, fontWeight: 400, mb: "8px" }} variant="h6">{text}</Typography>
}

export default SectionTitle