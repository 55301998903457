import { createTheme, responsiveFontSizes } from "@mui/material/styles";
// import RajdhaniMedium from "../assets/fonts/Rajdhani-Medium.ttf";
// import RajdhaniBold from "../assets/fonts/Rajdhani-Bold.ttf";
import InterThin from "../assets/fonts/inter/Inter-Thin.ttf";
import InterExtraLight from "../assets/fonts/inter/Inter-ExtraLight.ttf";
import InterLight from "../assets/fonts/inter/Inter-Light.ttf";
import InterRegular from "../assets/fonts/inter/Inter-Regular.ttf";
import InterMedium from "../assets/fonts/inter/Inter-Medium.ttf";
import InterSemiBold from "../assets/fonts/inter/Inter-SemiBold.ttf";
import InterBold from "../assets/fonts/inter/Inter-Bold.ttf";
import InterExtraBold from "../assets/fonts/inter/Inter-ExtraBold.ttf";
import InterBlack from "../assets/fonts/inter/Inter-Black.ttf";

const fontNames = [
  'InterBlack',
  'InterBold',
  'InterExtraBold',
  'InterExtraLight',
  'InterLight',
  'InterMedium',
  'InterRegular',
  'InterSemiBold',
  'InterThin',
];

let theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 100;
          src: local('InterThin'), url(${InterThin}) format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 200;
          src: local('InterExtraLight'), url(${InterExtraLight}) format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 300;
          src: local('InterLight'), url(${InterLight}) format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          src: local('InterRegular'), url(${InterRegular}) format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          src: local('InterMedium'), url(${InterMedium}) format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          src: local('InterSemiBold'), url(${InterSemiBold}) format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 700;
          src: local('InterBold'), url(${InterBold}) format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 800;
          src: local('InterExtraBold'), url(${InterExtraBold}) format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 900;
          src: local('InterBlack'), url(${InterBlack}) format('truetype');
        }
      `,
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
});

theme = responsiveFontSizes(theme);

export default theme;

// --------------------------FIXED COLORS--------------------------//
export const FixedColors = {
  almostBlack: "#080808",
  strokes: "#E3E3E3",
  inputField: "#F5F5F5",
  white: "#FFFFFF",
  blue: "#0000DB",
};
