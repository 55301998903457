const MarketAnalysisSVG = ({fill="white"}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill={`${fill}`} xmlns="http://www.w3.org/2000/svg">
            <path d="M9.16666 1.70801L9.16675 4.22592C6.33983 4.63028 4.16675 7.06146 4.16675 10.0002C4.16675 13.2218 6.77842 15.8335 10.0001 15.8335C11.3103 15.8335 12.5197 15.4015 13.4936 14.6722L15.2742 16.4525C13.8376 17.6282 12.0012 18.3335 10.0001 18.3335C5.39771 18.3335 1.66675 14.6026 1.66675 10.0002C1.66675 5.67909 4.9556 2.12615 9.16666 1.70801ZM18.2922 10.8336C18.1258 12.5094 17.463 14.0392 16.4528 15.2737L14.6721 13.4937C15.2439 12.7302 15.633 11.8218 15.7743 10.8335L18.2922 10.8336ZM10.8343 1.70809C14.7709 2.09937 17.9013 5.2301 18.2922 9.16674L15.7743 9.16666C15.4088 6.61229 13.3885 4.59178 10.8342 4.22604L10.8343 1.70809Z" fill={`${fill}`}/>
        </svg>

    )
}

MarketAnalysisSVG.defaultProps = {
    fill: "none"
}

export default MarketAnalysisSVG