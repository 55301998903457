import { Button, Grid, Stack, SxProps, Theme, Typography, styled } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Variant } from '@mui/material/styles/createTypography';

const ButtonText = styled(Typography)({
  fontFamily: 'Rajdhani',
  fontSize: 20,
  fontWeight: 900,
  borderRadius: 0,
});

interface IMenuButtonProps {
  text: string;
  subText?: string;
  buttonSx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
  buttonTextVariant?: Variant;
  onClick: () => void;
  isDisabled?: boolean;
}
const MenuButton = ({
  text,
  subText,
  buttonSx,
  containerSx,
  buttonTextVariant = 'h4',
  onClick: handleClick,
  isDisabled = false,
}: IMenuButtonProps) => (
  <Grid item xs={6} sx={{ color: '#454545', background: '#fff', width: '400px', marginBottom: 10, ...containerSx }}>
    <Button
      fullWidth
      variant="outlined"
      color="inherit"
      disableElevation
      onClick={handleClick}
      disabled={isDisabled}
      sx={{
        height: '60px',
        borderRadius: 0,
        px: 10,
        py: 2,
        '& .MuiButton-endIcon svg': {
          fontSize: '50px',
        },
        ...buttonSx,
      }}
      endIcon={<ChevronRightIcon sx={{ fontSize: '50px' }} />}
    >
      <Stack direction="column">
        <ButtonText variant={buttonTextVariant}>{text}</ButtonText>
        {subText && <b style={{ fontSize: 10 }}>{subText}</b>}
      </Stack>
    </Button>
  </Grid>
);

export default MenuButton;
