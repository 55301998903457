import {
  Box,
  Checkbox,
  Chip,
  Container,
  Grid,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo } from "react";
import { ref as dbRef, set, getDatabase } from "firebase/database";

// MUI
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";

// CUSTOM
import { AUTO100Ctx, Category, Company, Region } from "AUTO100DataProvider";
import PageSection from "components/PageSection";
import { AuthCtx } from "../../AuthProvider";
import BasicBreadcrumbs from "components/BreadCrumbsNavigation";
import CompanyDeepdiveLineChart from "pages/Playground/CompanyDeepdiveLineChart";
import CompanyDeepdiveRadarCharts from "pages/Playground/CompanyDeepdiveRadarCharts";
import Virtualize from "components/Autocomplete";
import CompanyDeepdiveAggregateRadarCharts from "./CompanyDeepdiveAggregateRadarCharts";

const NothingSelected = () => (
  <Paper
    elevation={0}
    sx={{
      height: 500,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Typography variant="h4" sx={{ fontWeight: 100, fontFamily: "Rajdhani" }}>
      Nothing selected for display
    </Typography>
  </Paper>
);
type ISelectInputProps<K> = {
  showChips?: boolean;
  inputLabel: string;
  valueKey: keyof K;
  lableKey: keyof K;
  labelId: string;
  availableValues: Array<K>;
  selectedValues: Array<keyof K>;
} & Exclude<SelectProps, "renderValue">;

function SelectInput<K>({
  showChips = false,
  inputLabel,
  valueKey,
  lableKey,
  labelId,
  availableValues,
  selectedValues,
  ...props
}: ISelectInputProps<K>) {
  return (
    <FormControl fullWidth sx={{ minWidth: "300px" }}>
      <InputLabel
        id={labelId}
        sx={{ background: "#fff", px: "7px", py: "1px" }}
      >
        {inputLabel}
      </InputLabel>
      <Select
        labelId={labelId}
        value={selectedValues}
        renderValue={(valuesToRender) => (
          <Box sx={{ display: "flex", flexWrap: "no-wrap", gap: 0.5 }}>
            {(valuesToRender as Array<K>).map((valueToRender) => (
              <Chip
                key={`select-value-${valueToRender[valueKey]}`}
                label={valueToRender?.[lableKey]}
              />
            ))}
          </Box>
        )}
        {...props}
      >
        {(availableValues ?? []).map((availableValue) => (
          <MenuItem
            key={`select-menu-${inputLabel}-${availableValue[valueKey]}`}
            value={availableValue[valueKey] as unknown as string | number}
          >
            <Checkbox
              checked={selectedValues.includes(
                availableValue[valueKey] as keyof K
              )}
            />
            <ListItemText primary={availableValue[lableKey]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function CompanyDeepdive() {
  const {
    analytics: {
      userPreference: { deepdivePreferences },
    },
    permissions,
  } = useContext(AUTO100Ctx);

  useEffect(() => {}, []);

  if (!permissions) {
    return <>Loading</>;
  }

  return (
    <Stack py={2}>
      {!deepdivePreferences ? (
        <NothingSelected />
      ) : (
        <Stack>
          <Stack flex={1} minHeight={500} mb={2}>
            <CompanyDeepdiveLineChart />
          </Stack>
          <Stack flexDirection="row" gap={2} flex={1}>
            <CompanyDeepdiveAggregateRadarCharts />
            <CompanyDeepdiveRadarCharts />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export default CompanyDeepdive;
