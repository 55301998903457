import {
  Stack,
  styled,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { FC, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { AUTO100Ctx, CompanyTypes, Weights } from "../AUTO100DataProvider";

const StyledNumberFieldProps = {
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& input": {
      flex: 1,
      padding: 0,
      margin: 0,
      textAlign: "center",
      fontSize: "0.7rem",
    },
    "& fieldset": {
      borderColor: "#CCC",
      flex: 1,
      padding: 0,
      margin: 0,
    },
    "&:hover fieldset": {
      padding: 0,
      borderColor: "#999",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#444",
    },
  },
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
};
export const NumberInput: FC<TextFieldProps> = (props) => {
  const quantityInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const ignoreScroll = (ev: WheelEvent) => {
      ev.preventDefault();
    };
    quantityInputRef.current &&
      quantityInputRef.current.addEventListener("wheel", ignoreScroll);
  }, [quantityInputRef]);

  return <TextField ref={quantityInputRef} type="number" {...props} />;
};
const WeightTile = styled(NumberInput)(StyledNumberFieldProps);
const TitleTile: FC<{ score?: number; tooltip?: string }> = ({
  children,
  score,
  tooltip,
}) => {
  return (
    <Stack
      direction={"row"}
      sx={{
        flex: 1,
        fontSize: "0.6rem",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        lineHeight: "11px",
        overflow: "hidden",
        background:
          score === undefined ? undefined : score === 1 ? "#cbffc9" : "#ffcdc9",
        borderRadius: "5px",
      }}
    >
      <span>{children}</span>
      {tooltip && (
        <Tooltip title={tooltip}>
          <InfoIcon
            sx={{
              width: "10px",
              height: "10px",
              color: "#AAA",
              ml: "2px",
              mb: "1px",
            }}
          />
        </Tooltip>
      )}
    </Stack>
  );
};

const WeightSum: FC<{
  weightType: Weights[keyof Weights];
  companyType: CompanyTypes;
}> = ({ weightType, companyType }) => {
  const sum: number | undefined = useMemo(() => {
    let initial: number | undefined = undefined;
    for (const weight of Object.values(weightType)) {
      if (initial) {
        initial += weight[companyType] ?? 0;
      } else {
        initial = weight[companyType] ?? 0;
      }
    }
    if (initial !== undefined) {
      return Number(initial?.toFixed(2));
    } else {
      return undefined;
    }
  }, [weightType, companyType]);

  return <TitleTile score={sum}>{sum}</TitleTile>;
};

export interface IPropsWeightsTable {
  isSubScore?: false;
  title?: string;
  weights?: Weights[keyof Weights];
  scoreKeys: Array<{ id: string; tooltip: string }>;
  onChange: (weights: Weights[keyof Weights]) => void;
  isSubscore?: boolean;
  isFrozen?: boolean;
}
export default function WeightsTable({
  title,
  weights,
  scoreKeys,
  onChange,
  isSubscore = false,
  isFrozen = false,
}: IPropsWeightsTable) {
  const {
    analytics: { categories },
  } = useContext(AUTO100Ctx);

  const getCompanyLabel = useCallback(
    (name: string) => {
      const category = categories.find((category) => category.name === name);
      return category?.label ?? name;
    },
    [categories]
  );

  //  CONSTANTS
  const companyTypes: Array<CompanyTypes> = [
    "supplier",
    "oem",
    "dealer",
    "mobility",
    "infrastructure",
  ];

  const handleWeightChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    scoreKey: string,
    companyType: CompanyTypes
  ) => {
    const val = event.target.value === "" ? 0 : parseFloat(event.target.value);
    if (!isNaN(val) && weights !== undefined) {
      const newWeights = { ...weights };
      let oldWeight = newWeights[scoreKey][companyType];
      if (oldWeight !== null || oldWeight !== undefined) {
        newWeights[scoreKey][companyType] = val;
      }
      onChange(newWeights);
    } else if (weights) {
      const newWeights = { ...weights };
      let oldWeight = newWeights[scoreKey][companyType];
      if (oldWeight !== null || oldWeight !== undefined) {
        newWeights[scoreKey][companyType] = event.target
          .value as unknown as number;
      }
      onChange(newWeights);
    }
  };

  return (
    <Stack>
      <Typography sx={{ fontSize: "0.8rem" }}>
        <strong>{title}</strong> Weights
      </Typography>
      <Stack
        direction={"row"}
        justifyContent="flex-end"
        sx={{
          background: "#000000",
          color: "#FFFFFF",
          height: "20px",
          borderRadius: "5px",
          fontWeight: 600,
        }}
        spacing={"2px"}
      >
        <TitleTile></TitleTile>
        {scoreKeys.map((score, index) => {
          return (
            <TitleTile key={"TitleTile-" + index} tooltip={score.tooltip}>
              {" "}
              {score.id}
            </TitleTile>
          );
        })}
        <TitleTile></TitleTile>
      </Stack>
      {!isSubscore &&
        companyTypes.map((companyType, index) => {
          return (
            <Stack
              key={`CompanyType-${index}`}
              direction={"row"}
              justifyContent="flex-end"
              marginTop={1}
              sx={{ height: "20px" }}
              spacing={"2px"}
            >
              <TitleTile>{getCompanyLabel(companyType)}</TitleTile>
              {scoreKeys.map((scoreKey, index) => {
                return (
                  <WeightTile
                    key={"WeightTile-" + index}
                    defaultValue={0}
                    onChange={(e) => {
                      handleWeightChange(e, scoreKey.id, companyType);
                    }}
                    disabled={isFrozen}
                    value={
                      weights !== undefined &&
                      weights[scoreKey.id] &&
                      weights[scoreKey.id][companyType]
                        ? weights[scoreKey.id][companyType]
                        : 0
                    }
                    sx={{ flex: 1, display: "flex", justifyContent: "center" }}
                  />
                );
              })}
              {
                <WeightSum
                  weightType={weights ?? {}}
                  companyType={companyType}
                />
              }
            </Stack>
          );
        })}

      {isSubscore && (
        <Stack
          direction={"row"}
          justifyContent="flex-end"
          marginTop={1}
          sx={{ height: "20px" }}
          spacing={"2px"}
        >
          <TitleTile>Sub-</TitleTile>
          {scoreKeys.map((scoreKey, index) => {
            return (
              <WeightTile
                key={"WeightTile-" + index}
                defaultValue={0}
                onChange={(e) => {
                  handleWeightChange(e, scoreKey.id, "subscore");
                }}
                disabled={isFrozen}
                value={
                  weights !== undefined &&
                  weights[scoreKey.id] &&
                  weights[scoreKey.id]["subscore"]
                    ? weights[scoreKey.id]["subscore"]
                    : 0
                }
                sx={{ flex: 1, display: "flex", justifyContent: "center" }}
              />
            );
          })}
          {<WeightSum weightType={weights ?? {}} companyType={"subscore"} />}
        </Stack>
      )}
    </Stack>
  );
}
