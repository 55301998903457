import { Stack, SxProps, SvgIcon } from "@mui/material"
import SectionDescription from "components/section-description"
import SectionTitle from "components/section-title"

type SectionProps = {
    title?: string
    description?: string
    style?: SxProps
    rightIcon?: React.ReactNode
    onRightIconClick?: () => void
    children: React.ReactNode
}

const Section = ({title, description, style, rightIcon, onRightIconClick = () => {}, children}: SectionProps) => {
    return (
        <Stack sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: 3,
            flexDirection: "column",
            alignItems: "flex-start",
            background: "#fff",
            borderRadius: "12px",
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
            position: "relative",
            flex: 1,
            ...style
        }}>
            {title && <SectionTitle text={title} />}
            {description && <SectionDescription text={description} />}
                <SvgIcon sx={{ color: "gray", position: "absolute", marginRight: 2, cursor: "pointer", right: "16px", top: "16px" }} onClick={onRightIconClick}>
                    {rightIcon}
                </SvgIcon>
            {children}
        </Stack>
    )
}

export default Section