import { Button, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// CUSTOM
import { AUTO100Ctx, WorkEnvironment } from "../../AUTO100DataProvider";
import AnimatedText from "../../components/Animations/AnimatedText";
import AnimatedBackground from "../../components/Animations/AnimatedBackground";
import MenuButton from "../../components/MenuButton";

export default function AUTO100HomePage() {
  const navigate = useNavigate();

  const { permissions } = useContext(AUTO100Ctx);

  //  HANDLE
  const handleMenuItemClick = (path: string) => {
    navigate(`/${path}`);
    // else if (permissions && permissions[path]) {
    //   navigate(`/auto100/${path}`);
    // }
  };

  if (!permissions) {
    return <>Loading</>;
  }

  return (
    <Stack sx={{ height: "100%" }} p={0} direction={"row"}>
      <AnimatedBackground />
      <Stack
        justifyContent={"center"}
        alignItems={"flex-start"}
        sx={{
          display: "flex",
          flex: 3,
          p: 10,
          backgroundImage: `linear-gradient(90deg, rgba(11, 41, 64, 0.3), rgba(11, 41, 64, 1)), url("https://www.berylls.com/wp-content/uploads/2023/03/parallax_car-2.jpg")`,
          backgroundSize: "cover",
        }}
      >
        <AnimatedText from={-2000} to={0} orientation="X">
          <Typography
            sx={{
              fontSize: "80px",
              color: "#f1f1f1",
              fontWeight: 900,
              fontFamily: "Rajdhani",
            }}
          >
            Welcome to AUTO100
          </Typography>
        </AnimatedText>

        <AnimatedText from={2000} to={0} orientation="X">
          <Typography
            sx={{
              fontSize: "30px",
              color: "#f1f1f1",
              fontWeight: 500,
              fontFamily: "Rajdhani",
            }}
          >
            The BDV Automobility Index (AMX), a real-time picture of the
            automobility supply chain across all core global regions and already
            an industry benchmark (find out more about AMX here).
          </Typography>
        </AnimatedText>
      </Stack>
      <Stack
        sx={{ display: "flex", flex: 2 }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Stack flex={4} justifyContent={"end"}>
          {permissions[WorkEnvironment.Playground] && (
            <MenuButton
              isDisabled={!permissions[WorkEnvironment.Playground]}
              subText={
                !permissions[WorkEnvironment.Playground]
                  ? "PERMISSION REQUIRED"
                  : undefined
              }
              text={WorkEnvironment.Playground}
              onClick={() => handleMenuItemClick(WorkEnvironment.Playground)}
              buttonSx={{
                "& .MuiButton-endIcon svg": {
                  fontSize: "40px",
                },
              }}
            />
          )}
          {permissions[WorkEnvironment.Production] && (
            <MenuButton
              isDisabled={!permissions[WorkEnvironment.Production]}
              subText={
                !permissions[WorkEnvironment.Production]
                  ? "PERMISSION REQUIRED"
                  : undefined
              }
              text={WorkEnvironment.Production}
              onClick={() => handleMenuItemClick(WorkEnvironment.Production)}
              buttonSx={{
                "& .MuiButton-endIcon svg": {
                  fontSize: "40px",
                },
              }}
            />
          )}
        </Stack>

        <Stack
          direction="row"
          alignItems="end"
          justifyContent="center"
          flex={1}
          padding={5}
          sx={{
            color: "#fff",
            width: "100%",
            fontSize: "30px",
            fontWeight: 500,
          }}
        >
          {/* <Button
            size="large"
            color="inherit"
            endIcon={<ArrowForwardIcon />}
            fullWidth
            sx={{
              fontWeight: 900,
              fontSize: '25px',
              '& .MuiButton-endIcon svg': {
                fontSize: '30px',
                fontWeight: 900,
              },
            }}
          >
            Request Access
          </Button> */}
        </Stack>
      </Stack>
    </Stack>
  );
}
