import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { ref, get, getDatabase } from "firebase/database";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "./Firebase";
// --------------------------------------------------------

import { AuthCtx } from "./AuthProvider";
import moment from "moment";
import NavigationBar from "components/NavigationBar/Index";
import Toolbar from "components/Toolbar/Index";
import { Box, Stack } from "@mui/material";
import Sidebar from "components/Sidebar/Index";
import { getQuarters } from "utils";
import { useLocation } from "react-router-dom";

export interface ScoreFile {
  id: string;
  displayName: string;
  fileNameBuilder: (year: number, quarter: number) => string;
  category: string;
  dependencies: string[];
  isRootFile?: boolean;
  friendlyName?: string;
}

export const AUTO100ScoreFileCategories = ["TYPE1", "TYPE2", "TYPE3", "TYPE4"];

export const AUTO100ScoreFiles: ScoreFile[] = [
  {
    id: "AUTO100_UNIVERSE_ISIN",
    displayName: "Universe ISIN",
    friendlyName: "Universe of Companies",
    fileNameBuilder: (year: number, quarter: number) =>
      `${year}_q${quarter}_universe_isin.xlsx`,
    category: "TYPE1",
    dependencies: ["AUTO100_COUNTRY_CODES"],
  },
  {
    id: "AUTO100_ST1",
    displayName: "ST1",
    friendlyName: "Strategy Communication",
    fileNameBuilder: (year: number, quarter: number) =>
      `${year}_q${quarter}_st1.xlsx`,
    category: "TYPE1",
    dependencies: ["AUTO100_UNIVERSE_ISIN"],
  },
  {
    id: "AUTO100_ST2",
    displayName: "ST2",
    friendlyName: "Brand Value",
    fileNameBuilder: (year: number, quarter: number) =>
      `${year}_q${quarter}_st2.xlsx`,
    category: "TYPE1",
    dependencies: ["AUTO100_UNIVERSE_ISIN"],
  },
  {
    id: "AUTO100_ST3",
    displayName: "ST3",
    friendlyName: "Strategy Communication",
    fileNameBuilder: (year: number, quarter: number) =>
      `${year}_q${quarter}_st3.xlsx`,
    category: "TYPE1",
    dependencies: ["AUTO100_UNIVERSE_ISIN"],
  },
  {
    id: "AUTO100_ST4",
    displayName: "ST4",
    friendlyName: "EV Share - sales",
    fileNameBuilder: (year: number, quarter: number) =>
      `${year}_q${quarter}_st4.xlsx`,
    category: "TYPE1",
    dependencies: ["AUTO100_UNIVERSE_ISIN", "AUTO100_4_5_12_MATCHING"],
  },
  {
    id: "AUTO100_ST5",
    displayName: "ST5",
    friendlyName: "EV Share - portfolio",
    fileNameBuilder: (year: number, quarter: number) =>
      `${year}_q${quarter}_st5.xlsx`,
    category: "TYPE1",
    dependencies: ["AUTO100_UNIVERSE_ISIN", "AUTO100_4_5_12_MATCHING"],
  },
  {
    id: "AUTO100_ST6",
    displayName: "ST6",
    friendlyName: "EV Share - portfolio",
    fileNameBuilder: (year: number, quarter: number) =>
      `${year}_q${quarter}_st6.xlsx`,
    category: "TYPE2",
    dependencies: ["AUTO100_UNIVERSE_ISIN", "AUTO100_COUNTRY_CODES"],
  },
  {
    id: "AUTO100_ST7",
    displayName: "ST7",
    friendlyName: "Highly Innovative Domains",
    fileNameBuilder: (year: number, quarter: number) =>
      `${year}_q${quarter}_st7.xlsx`,
    category: "TYPE2",
    dependencies: ["AUTO100_UNIVERSE_ISIN"],
  },
  {
    id: "AUTO100_ST10",
    displayName: "ST10",
    friendlyName: "Berylls Sustainability Evaluation",
    fileNameBuilder: (year: number, quarter: number) =>
      `${year}_q${quarter}_st10.xlsx`,
    category: "TYPE2",
    dependencies: ["AUTO100_UNIVERSE_ISIN"],
  },
  {
    id: "AUTO100_ST11_LONGLIST",
    displayName: "st11 longlist",
    friendlyName: "Berylls Top 100 Suppliers Performance",
    fileNameBuilder: (year: number, quarter: number) =>
      `${year}_q${quarter}_st11_longlist.xlsx`,
    category: "TYPE2",
    dependencies: ["AUTO100_ST11_MATCHING", "AUTO100_UNIVERSE_ISIN"],
  },
  {
    id: "AUTO100_ST11_MATCHING",
    displayName: "ST11 Matching",
    friendlyName: "Matching file 2",
    fileNameBuilder: (year: number, quarter: number) =>
      `${year}_q${quarter}_st11_matching.xlsx`,
    category: "TYPE2",
    dependencies: [],
  },
  {
    id: "AUTO100_ST12",
    displayName: "ST12",
    friendlyName: "Macroeconomic Exposure",
    fileNameBuilder: (year: number, quarter: number) =>
      `${year}_q${quarter}_st12.xlsx`,
    category: "TYPE2",
    dependencies: [
      "AUTO100_4_5_12_MATCHING",
      "AUTO100_ST12_GDP",
      "AUTO100_UNIVERSE_ISIN",
    ],
  },
  {
    id: "AUTO100_ST12_GDP",
    displayName: "ST12 GDP",
    friendlyName: "Matching File for gdp",
    fileNameBuilder: (year: number, quarter: number) =>
      `${year}_q${quarter}_st12_gdp.xlsx`,
    category: "TYPE3",
    dependencies: [],
  },
  {
    id: "AUTO100_4_5_12_MATCHING",
    displayName: "4 5 12 matching",
    friendlyName: "Matching file 1",
    fileNameBuilder: (year: number, quarter: number) =>
      `${year}_q${quarter}_4_5_12_matching.xlsx`,
    category: "TYPE3",
    dependencies: [],
  },
  {
    id: "AUTO100_SENTIMENT_OEMS",
    displayName: "sentiment oems",
    friendlyName: "Public Sentiment",
    fileNameBuilder: (year: number, quarter: number) =>
      `${year}_q${quarter}_sentiment_oems.xlsx`,
    category: "TYPE3",
    dependencies: ["AUTO100_UNIVERSE_ISIN"],
  },
  {
    id: "AUTO100_SUPPLY_CHAIN",
    displayName: "supply chain",
    friendlyName: "Relationship",
    fileNameBuilder: (year: number, quarter: number) =>
      `${year}_q${quarter}_supply_chain.csv`,
    category: "TYPE3",
    dependencies: ["AUTO100_UNIVERSE_ISIN"],
  },
  {
    id: "AUTO100_SUSTAINABILITY_SENTIMENT_OEMS",
    displayName: "sustainability sentiments oems",
    friendlyName: "Sustainability Sentiments OEMS",
    fileNameBuilder: (year: number, quarter: number) =>
      `${year}_q${quarter}_sustainability_sentiment_oems.xlsx`,
    category: "TYPE3",
    dependencies: ["AUTO100_UNIVERSE_ISIN"],
  },
  // {
  //   id: 'AUTO100_ST7_DOMAIN',
  //   displayName: 'ST7 Domain',
  //   fileNameBuilder: (year: number, quarter: number) => `st7_domain.xlsx`,
  //   category: 'TYPE4',
  //   dependencies: [],
  //   isRootFile: true,
  // },
  {
    id: "AUTO100_COUNTRY_CODES",
    displayName: "Country Codes",
    fileNameBuilder: (year: number, quarter: number) => `country_codes.xlsx`,
    category: "TYPE4",
    dependencies: [],
    isRootFile: true,
  },
];

export interface FileUploadStatus {
  uploaded: boolean;
  uploadedBy?: string;
  uploadTimestamp: number; //
  validation_status: string;
  validation_error?: string;
  valid?: boolean;
}

export type ScoreWeights = {
  [key: string]: { [key: string]: number };
};
export enum WeightTypes {
  Relationship = "relationship",
  Sentiment = "sentiment",
  Strategy = "strategy",
  Subscore = "subscore",
}
export type Weights = {
  [key in WeightTypes]: {
    [key: string]: {
      dealer?: number;
      infrastructure?: number;
      mobility?: number;
      oem?: number;
      supplier?: number;
      subscore?: number;
    };
  };
};
export type CompanyTypes = Exclude<
  keyof Weights[keyof Weights][keyof Weights],
  "default"
>;

export enum WorkEnvironment {
  Production = "production",
  Playground = "playground",
}

export enum RebalancingOption {
  Solactive = "solactive",
  WisdomTree = "wisdomtree",
}

export type CompanyDeepdivePreference = {
  company?: Array<Company>;
  segment?: Array<Category["id"]>;
  region?: Array<Region["id"]>;
};

export type UniverseConstituentsPreference = {
  timeFrame?: string;
  segment?: Array<Category["id"]>;
  region?: Array<Region["id"]>;
};

export type StockPerformanceComparisonPreferences = {
  company?: Array<Company>;
  segment?: Array<Category["id"]>;
  region?: Array<Region["id"]>;
  metric?: Array<Metric["id"]>;
  timeFrame?: string;
  financialQuarter?: string
};

type UserPreference = {
  rebalancing_option?: RebalancingOption;
  selected_year?: number;
  selected_quarter?: number;
  analytics?: {
    deepdivePreference?: CompanyDeepdivePreference;
    universeConstituentsPreferences?: UniverseConstituentsPreference;
    stockPerformanceComparisonPreferences?: StockPerformanceComparisonPreferences
  };
};

export type Auto100Permissions = {
  [key in string]: boolean;
};

interface AUTO100CtxBaseProps {
  isLoading: boolean;
  setLoading: (state: boolean) => void;
  permissions: Auto100Permissions | undefined;
  selectedRebalancingOption: RebalancingOption | undefined;
  setSelectedRebalancingOption: (option: RebalancingOption) => void;
  selectedYear: number | undefined;
  setSelectedYear: (year: number) => void;
  selectedQuarter: number | undefined;
  setSelectedQuarter: (year: number) => void;
}

export interface Company {
  ISIN: string;
  Name: string;
  Color: string;
  Category: string;
  Region: string;
  Country: string;
  Annual_Revenue: string;
  Stock_Price: string;
  Market_Capital: string;
  Head_Count: string;
  Founded_In: string;
}
export interface Category {
  id: string;
  name: string;
  label: string;
  Color: string;
}
export interface Region {
  id: string;
  name: string;
  label: string;
  Color: string;
}
export interface Metric {
  id: string;
  name: string;
  label: string;
  Color: string;
}
export interface Scores {
  [rebalancing_option: string]: {
    [isin: string]: {
      [year_quarter: string]: {
        [key: string]: number;
      };
    };
  };
}
export interface UniverseConstituentsRanking {
  [isin: string]: {
    [quarter: string]: {
      [key: string]: number;
    };
  };
}
interface AUTO100CtxAnalyticsProps {
  analytics: {
    companies: Array<Company>;
    setCompanies: (companies: Array<Company>) => void;

    categories: Array<Category>;
    setCategories: (categories: Array<Category>) => void;

    regions: Array<Region>;
    setRegions: (regions: Array<Region>) => void;

    metrics: Array<Metric>;
    setMetrics: (metrics: Array<Metric>) => void;

    scores: Scores | undefined;

    timeFrame: Array<string>

    universeConstituentsRanking: UniverseConstituentsRanking | undefined;

    userPreference: {
      deepdivePreferences?: CompanyDeepdivePreference;
      setDeepdivePreferences: (preference: CompanyDeepdivePreference) => void;
      universeConstituentsPreferences?: UniverseConstituentsPreference,
      setUniverseConstituentsPreferences: (preference: UniverseConstituentsPreference) => void;
      stockPerformanceComparisonPreferences?: StockPerformanceComparisonPreferences;
      setStockPerformanceComparisonPreferences: (preferences: StockPerformanceComparisonPreferences) => void
    };
  };
}

type AUTO100CtxProps = AUTO100CtxBaseProps & AUTO100CtxAnalyticsProps;

export const AUTO100Ctx = createContext({} as AUTO100CtxProps);

export const AUTO100DataProvider: React.FC = ({ children }) => {
  const db = getDatabase();
  const { user } = useContext(AuthCtx);
  const [isLoading, setLoading] = useState<boolean>(true);

  const location = useLocation();

  //  User Preferences : Production
  const [selectedYear, setSelectedYear] = useState<number>();
  const [selectedQuarter, setSelectedQuarter] = useState<number>();
  const [permissions, setPermissions] = useState<Auto100Permissions>();
  const [selectedRebalancingOption, setSelectedRebalancingOption] =
    useState<RebalancingOption>();

  /****************
   *   ANALYTICS  *
   ***************/
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const [categories, setCategories] = useState<Array<Category>>([]);
  const [regions, setRegions] = useState<Array<Region>>([]);
  const [metrics, setMetrics] = useState<Array<Metric>>([]);
  const [scores, setScores] = useState<Scores>();
  const [universeConstituentsRanking, setUniverseConstituentsRanking] =
    useState<UniverseConstituentsRanking>();

  //  User Preferences : Analytics
  const [deepdivePreferences, setDeepdivePreferences] =
    useState<CompanyDeepdivePreference>();
  const [universeConstituentsPreferences, setUniverseConstituentsPreferences] =
    useState<UniverseConstituentsPreference>();
  const [stockPerformanceComparisonPreferences, setStockPerformanceComparisonPreferences] =
    useState<StockPerformanceComparisonPreferences>();

  const fetchAnalyticsData = useCallback(
    async function () {
      setLoading(true);
      if (user) {
        setLoading(false);

        const analyticsSnapshot = await get(ref(db, `AUTO100/analytics`));
        const analyticsData = analyticsSnapshot.val();
        if (analyticsSnapshot.exists()) {
          const companies = (
            Object.values(analyticsData?.companies as Array<Company>) ?? []
          ).filter((company) => company.ISIN && company.Name);
          setCompanies(companies);
          setCategories(analyticsData?.categories ?? []);
          setRegions(analyticsData?.regions ?? []);
          setMetrics(analyticsData?.metrics ?? []);

          //  fetch scores
          setScores(analyticsData.scores as Scores);

          //  fetch universe constituents ranking
          setUniverseConstituentsRanking(
            analyticsData.constituents_ranking as UniverseConstituentsRanking
          );
        }
      }
    },
    [user, db]
  );

  useEffect(() => {
    fetchAnalyticsData();
  }, [user, selectedRebalancingOption, fetchAnalyticsData]);

  const fetchUserPreferences = useCallback(
    async function () {
      setLoading(true);
      if (user) {
        setLoading(false);

        const snapshot = await get(
          ref(db, `AUTO100/user_preferences/${user.uid}/`)
        );

        let _selectedRebalancingOption = RebalancingOption.Solactive;
        let _selectedYear = new Date().getFullYear();
        let _selectedQuarter = moment().quarter();

        if (snapshot.exists()) {
          const user_preferences = snapshot.val() as UserPreference;
          //  TODO: Move this under production
          if (user_preferences.rebalancing_option)
            _selectedRebalancingOption = user_preferences.rebalancing_option;
          if (user_preferences.selected_year)
            _selectedYear = user_preferences.selected_year;
          if (user_preferences.selected_quarter)
            _selectedQuarter = user_preferences.selected_quarter;

          if (user_preferences?.analytics?.deepdivePreference) {
            setDeepdivePreferences(
              user_preferences.analytics.deepdivePreference
            );
          }

          if(user_preferences?.analytics?.stockPerformanceComparisonPreferences){
            setStockPerformanceComparisonPreferences(
              user_preferences.analytics.stockPerformanceComparisonPreferences
            )
          }
          if(user_preferences?.analytics?.universeConstituentsPreferences){
            setUniverseConstituentsPreferences(
              user_preferences.analytics.universeConstituentsPreferences
            )
          }
        }

        setSelectedRebalancingOption(_selectedRebalancingOption);
        setSelectedYear(_selectedYear);
        setSelectedQuarter(_selectedQuarter);
      }
    },
    [db, user]
  );

  useEffect(() => {
    fetchUserPreferences();
  }, [fetchUserPreferences]);

  useEffect(() => {
    const unsub = onSnapshot(
      doc(firestore, "auto100_permissions", user?.uid ?? ""),
      (snapshot) => {
        const data = snapshot.data();
        setPermissions(data as Auto100Permissions);
      },
      (error) => {
        //  TODO: Show error notification
        console.error(error);
      }
    );
    return unsub;
  }, [user?.uid]);

  const currentRootPath = React.useMemo(() => {
    const { pathname } = location
    if( pathname.split("/")[0] === "" ){
      return pathname.split("/")[1]
    }
    return pathname.split("/")[0]
  }, [location])

  return (
    <AUTO100Ctx.Provider
      value={{
        isLoading,
        setLoading,
        permissions: permissions,
        selectedRebalancingOption,
        setSelectedRebalancingOption,
        selectedYear: selectedYear,
        setSelectedYear: setSelectedYear,
        selectedQuarter: selectedQuarter,
        setSelectedQuarter: setSelectedQuarter,
        analytics: {
          companies: companies,
          setCompanies: setCompanies,
          categories: categories,
          setCategories: setCategories,
          regions: regions,
          setRegions: setRegions,
          metrics: metrics,
          setMetrics: setMetrics,
          scores: scores,
          timeFrame: getQuarters({startYear: new Date().getFullYear()-1, endQuarter: 3}),
          universeConstituentsRanking: universeConstituentsRanking,
          userPreference: {
            deepdivePreferences,
            setDeepdivePreferences,
            universeConstituentsPreferences,
            setUniverseConstituentsPreferences,
            stockPerformanceComparisonPreferences,
            setStockPerformanceComparisonPreferences,
          },
        },
      }}
    >
      {
        currentRootPath === "playground" ? <>
          <Box
            component="nav"
            sx={{ width: { sm: 240 }, flexShrink: { sm: 0 } }}
            mt={120}
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Sidebar user={user} drawerWidth={240} />
          </Box>
          <Box
            component="main"
            sx={{ 
              background: "#F1F5F9",
              flexGrow: 1, px: 3, width: { sm: `calc(100% - ${240}px)` }, }}
          >
            <NavigationBar user={user} />
            <Toolbar user={user} />
            <Stack sx={{ height: "128px" }} />
            {children}
          </Box>
        </> : <>
          
          <Box
            component="main"
            sx={{ 
              background: "#F1F5F9",
              flexGrow: 1, px: 3, }}
          >
            <NavigationBar user={user} />
            <Stack sx={{ height: "128px" }} />
            {children}
          </Box>
        </>
      }
      
    </AUTO100Ctx.Provider>
  );
};
