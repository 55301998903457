import { FC } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";

const CloseModalButtonSVG: FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.46468 25.6875C1.82723 25.7246 1.20032 25.5127 0.716155 25.0965C-0.238719 24.1359 -0.238719 22.5846 0.716155 21.624L21.6248 0.71533C22.6179 -0.213997 24.1763 -0.162336 25.1057 0.830817C25.946 1.72892 25.995 3.10933 25.2203 4.06469L4.18854 25.0965C3.71062 25.5067 3.09377 25.7182 2.46468 25.6875Z"
      fill="#0D1116"
    />
    <path
      d="M23.3487 25.6876C22.7027 25.6848 22.0835 25.4284 21.6248 24.9734L0.716079 4.06466C-0.168562 3.03161 -0.0482908 1.47692 0.984764 0.592194C1.90679 -0.197398 3.2666 -0.197398 4.18855 0.592194L25.2203 21.5009C26.2133 22.4305 26.2646 23.989 25.335 24.9819C25.298 25.0214 25.2598 25.0596 25.2203 25.0965C24.7054 25.5444 24.0276 25.7584 23.3487 25.6876Z"
      fill="#0D1116"
    />
  </svg>
);

export const CancelButton = styled(Button)({
  "&.MuiButton-root": {
    minWidth: "150px",
    background: "#000000",
    color: "white",
    // fontFamily: "'Poppins', normal",
    textTransform: "none",
    fontSize: "12px",
    width: "auto",
    padding: "10px 50px",
    "&:hover": {
      background: "#181F28",
    },
  },
});

export const ProceedButton = styled(Button)({
  "&.MuiButton-root": {
    minWidth: "150px",
    background: "#4972FE",
    color: "white",
    // fontFamily: "'Poppins', normal",
    textTransform: "none",
    fontSize: "12px",
    width: "auto",
    padding: "10px 50px",
    "&:hover": {
      background: "#4972FE",
    },
  },
});

export const HelperButton = styled(Button)({
  "&.MuiButton-root": {
    background: "#ffb703",
    color: "white",
    // fontFamily: "'Poppins', normal",
    textTransform: "none",
    fontSize: "12px",
    width: "auto",
    padding: "10px 50px",
    "&:hover": {
      background: "#ffb703",
    },
  },
});

interface TISModalProps {
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  title?: string;
  open?: boolean;
  onClose?: () => void;
  cancelButtonText?: string;
  cancelButtonAction?: () => void;
  confirmButtonText?: string;
  confirmButtonAction?: () => void;
  helperButtonText?: string;
  helperButtonAction?: () => void;
  loading?: boolean;
}

export const CustomModal: FC<TISModalProps> = ({
  maxWidth = "xl",
  title,
  open = false,
  onClose,
  cancelButtonText,
  cancelButtonAction,
  confirmButtonText,
  confirmButtonAction,
  helperButtonText,
  helperButtonAction,
  loading,
  children,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={true}
      PaperProps={{ style: { background: "transparent", boxShadow: "none" } }}
    >
      <Paper
        sx={{
          p: 4,
          borderRadius: "15px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          maxWidth: maxWidth,
        }}
      >
        {loading && (
          <Stack
            sx={{
              position: "absolute",
              top: "45px",
              bottom: "20px",
              right: "45px",
              left: "45px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              background: "white",
              zIndex: 10,
            }}
          >
            <CircularProgress
              id={"TISModalCircularProgress"}
              size={70}
              thickness={2}
              sx={{ color: "#DDD" }}
            />
          </Stack>
        )}
        <div
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          <CloseModalButtonSVG />
        </div>
        {title && (
          <Typography
            sx={{
              fontSize: "1.2rem",
              fontFamily: "'Poppins', normal",
              mb: "10px",
            }}
          >
            {title}
          </Typography>
        )}
        {children}
        {cancelButtonText || confirmButtonText || helperButtonText ? (
          <Stack
            direction={"row"}
            spacing={2}
            mt={2}
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            {cancelButtonAction && cancelButtonText && (
              <CancelButton
                id="TISModalCancelButton"
                onClick={() => {
                  if (cancelButtonAction) {
                    cancelButtonAction();
                  }
                }}
              >
                {cancelButtonText}
              </CancelButton>
            )}
            {confirmButtonAction && confirmButtonText && (
              <ProceedButton
                id="TISModalProceedButton"
                onClick={() => {
                  if (confirmButtonAction) {
                    confirmButtonAction();
                  }
                }}
              >
                {confirmButtonText}
              </ProceedButton>
            )}
            {helperButtonAction && helperButtonText && (
              <HelperButton
                id="TISModalHelperButton"
                onClick={() => {
                  if (helperButtonAction) {
                    helperButtonAction();
                  }
                }}
              >
                {helperButtonText}
              </HelperButton>
            )}
          </Stack>
        ) : null}
      </Paper>
    </Dialog>
  );
};

type ConfirmationModalProps = {
  onClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  loading?: boolean;
  open: boolean;
  title?: string;
  description?: string;
  confirmButtonText?: string;
};

export const ConfirmationModal = ({
  onClose,
  onConfirm,
  onCancel,
  loading,
  open,
  title,
  description,
  confirmButtonText,
}: ConfirmationModalProps) => {
  return (
    <CustomModal
      maxWidth="xs"
      open={open}
      onClose={onClose}
      loading={loading}
      cancelButtonText="Cancel"
      cancelButtonAction={onCancel}
      confirmButtonText={confirmButtonText || "Confirm"}
      confirmButtonAction={onConfirm}
    >
      <Stack alignItems={"flex-start"}>
        <Typography sx={{ fontSize: "1.5rem", fontWeight: 500 }}>
          {title || "Are you sure?"}
        </Typography>
        <Typography mt={2}>{!!description && description}</Typography>
      </Stack>
    </CustomModal>
  );
};

export default CustomModal;
