import React, { useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import {
  Container,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Typography,
  Grid,
  TextField,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  SelectChangeEvent,
  SelectProps,
  Chip,
  Checkbox,
  ListItemText,
  Alert,
  InputAdornment,
} from "@mui/material";
import { useCallback, useContext, useState } from "react";
import { styled, withStyles } from "@mui/material/styles";
import {
  AUTO100Ctx,
  Category,
  Company,
  Region,
} from "../../AUTO100DataProvider";
import InfoIcon from "@mui/icons-material/Info";
import { formattedLabels, getQuarters, toTitleCase } from "utils";
import countries from "assets/data/countries.json";
import Section from "components/section/Index";
import { TableSortIcon } from "components/table-sort-icon";

type ISelectInputProps<K> = {
  showChips?: boolean;
  inputLabel: string;
  valueKey: keyof K;
  lableKey: keyof K;
  labelId: string;
  availableValues: Array<K>;
  selectedValues: Array<keyof K>;
} & Exclude<SelectProps, "renderValue">;

function SelectInput<K>({
  showChips = false,
  inputLabel,
  valueKey,
  lableKey,
  labelId,
  availableValues,
  selectedValues,
  ...props
}: ISelectInputProps<K>) {
  const [isFocusd, setFocused] = useState(false);
  return (
    <FormControl fullWidth sx={{ minWidth: "300px" }}>
      <InputLabel
        id={labelId}
        sx={{
          background: "#fff",
          px: "7px",
          top: isFocusd || selectedValues.length ? undefined : "-7px",
        }}
      >
        {inputLabel}
      </InputLabel>
      <Select
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        labelId={labelId}
        value={selectedValues}
        renderValue={(valuesToRender) => (
          <Box sx={{ display: "flex", flexWrap: "no-wrap", gap: 0.5 }}>
            {(valuesToRender as Array<K>).map((valueToRender) => (
              <Chip
                key={`select-value-${valueToRender[valueKey]}`}
                label={valueToRender?.[lableKey]}
              />
            ))}
          </Box>
        )}
        {...props}
      >
        {(availableValues ?? []).map((availableValue) => (
          <MenuItem
            key={`select-menu-${inputLabel}-${availableValue[valueKey]}`}
            value={availableValue[valueKey] as unknown as string | number}
          >
            <Checkbox
              checked={selectedValues.includes(
                availableValue[valueKey] as keyof K
              )}
            />
            <ListItemText primary={availableValue[lableKey]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function BasicSelect({
  selectedRegions,
  setSelectedRegions,
  selectedSegments,
  setSelectedSegments,
}: {
  selectedRegions: Array<Region["name"]>;
  setSelectedRegions: (regionId: string[]) => void;
  selectedSegments: Array<Category["name"]>;
  setSelectedSegments: (segmentId: string[]) => void;
}) {
  const {
    analytics: { categories, regions },
  } = useContext(AUTO100Ctx);

  const handleRegionChange = async (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value: selectedValues },
    } = event;

    setSelectedRegions(
      (selectedValues as Array<Region["name"]>).map((value) =>
        value.toLowerCase()
      )
    );
  };
  const handleCategoryChange = async (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value: selectedValues },
    } = event;

    setSelectedSegments(
      (selectedValues as Array<Category["name"]>).map((value) =>
        value.toLowerCase()
      )
    );
  };

  return (
    <React.Fragment>
      <Stack direction={"row"} spacing={1}>
        <Stack flex={1}>
          <SelectInput
            showChips={true}
            multiple
            inputLabel={"Segment"}
            valueKey={"name"}
            lableKey={"label"}
            labelId="segment-select"
            availableValues={categories}
            size="small"
            selectedValues={
              (selectedSegments as unknown as Array<keyof Category>) ?? []
            }
            renderValue={(selected) =>
              (selected as Array<unknown>).length + " Selected"
            }
            onChange={handleCategoryChange}
          />
        </Stack>
        <Stack flex={1}>
          <SelectInput
            showChips={true}
            multiple
            inputLabel={"Region"}
            valueKey={"name"}
            lableKey={"label"}
            labelId="region-select"
            availableValues={regions}
            size="small"
            selectedValues={
              (selectedRegions as unknown as Array<keyof Region>) ?? []
            }
            renderValue={(selected) =>
              (selected as Array<unknown>).length + " Selected"
            }
            onChange={handleRegionChange}
            inputProps={{
              label: "Label",
              InputLabelProps: {
                shrink: false,
              },
            }}
            sx={{
              "& .MuiFormLabel-root ": {
                top: "-10px",
              },
            }}
          />
        </Stack>
      </Stack>
    </React.Fragment>
  );
}

interface Constituents extends Company {
  Rank_Index: number;
  Rank_Difference: string;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    padding: 20,
    minWidth: 400,
    maxHeight: 500,
  },
}));

export default function UniverseConstituents() {
  const {
    analytics: {
      companies,
      regions,
      categories,
      universeConstituentsRanking,
      userPreference: {
        universeConstituentsPreferences: {
          timeFrame,
          region: selectedRegions,
          segment: selectedSegments,
        } = {
          timeFrame: undefined,
          segment: [],
          region: [],
        },
      },
    },
  } = useContext(AUTO100Ctx);

  // const [selectedQuarter, setSelectedQuarter] = useState<string>();
  const availableQuarters = getQuarters({});

  const [filterText, setFilterText] = useState("");
  // const [segmentFilter, setSegmentFilter] = React.useState<Array<string>>([]);
  // const [regionFilter, setRegionFilter] = React.useState<Array<string>>([]);

  const rankDifference = useCallback(
    (isin: string, rankIndex: number) => {
      const quarterIndex = availableQuarters.findIndex(
        (availableQuarter) => availableQuarter === timeFrame
      );
      if (quarterIndex + 1 < availableQuarters.length) {
        const targetQuarter = availableQuarters[quarterIndex + 1];
        const previousRankIndex =
          universeConstituentsRanking?.[isin]?.[targetQuarter]?.["Rank_Index"];
        if (previousRankIndex) {
          const rankDifference = rankIndex - Number(previousRankIndex);
          if (isNaN(rankDifference)) return "";
          return rankDifference > 0 ? `+${rankDifference}` : rankDifference;
        }
      }
      return "New Entry";
    },
    [availableQuarters, timeFrame, universeConstituentsRanking]
  );

  const constituents: Array<Constituents> | undefined = useMemo(() => {
    if (!universeConstituentsRanking || !timeFrame) return undefined;
    return companies
      .map((company) => {
        const Rank_Index =
          (universeConstituentsRanking?.[company.ISIN]?.[timeFrame] as {
            Rank_Index: number;
          }) ?? {};
        return {
          ...company,
          Rank_Index: Rank_Index["Rank_Index"],
          Rank_Difference: rankDifference(
            company.ISIN,
            Rank_Index["Rank_Index"]
          ) as string,
        };
      })
      .sort((a, b) => {
        const aValue = a["Rank_Index"] ?? 101;
        const bValue = b["Rank_Index"] ?? 101;

        if (aValue < bValue) {
          return -1;
        } else if (aValue > bValue) {
          return 1;
        } else {
          return 1;
        }
      })
      .slice(0, 100);
  }, [companies, rankDifference, timeFrame, universeConstituentsRanking]);

  // // Set the latest quarter with data as the default selected quarter
  // useEffect(() => {
  //   if (!universeConstituentsRanking) return;
  //   let latestQuarterWithData = availableQuarters[0];
  //   availableQuarters.every((quarter) => {
  //     return Object.keys(universeConstituentsRanking ?? {}).every((isin) => {
  //       if (universeConstituentsRanking?.[isin][quarter]) {
  //         latestQuarterWithData = quarter;
  //         return false;
  //       }
  //       return true;
  //     });
  //   });
  //   setSelectedQuarter(latestQuarterWithData);
  // }, [availableQuarters, universeConstituentsRanking]);

  const [sortKey, setSortKey] = useState<keyof Constituents>("Rank_Index");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  // Handle sorting
  const handleSort = (key: keyof Constituents) => {
    if (key === sortKey) {
      // Toggle sort direction if the same key is clicked again
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // Set new sort key and reset sort direction
      setSortKey(key);
      setSortDirection("asc");
    }
  };

  const regionFilter = useMemo(() => {
    return (selectedRegions ?? [])?.map((selectedRegion) => {
      return regions.find((_region) => _region.id === selectedRegion);
    });
  }, [regions, selectedRegions]);
  const categoryFilter = useMemo(() => {
    return (selectedSegments ?? [])?.map((selectedSegment) => {
      return categories.find((_category) => _category.id === selectedSegment);
    });
  }, [categories, selectedSegments]);

  const filteredData = useMemo(() => {
    if (!constituents) return undefined;

    // Apply sorting and filtering to the data
    let filteredData = constituents;
    if (filterText || regionFilter.length > 0 || categoryFilter.length > 0) {
      filteredData = filteredData.filter((row) => {
        if (
          regionFilter.length > 0 &&
          !regionFilter.find(
            (_regionFilter) => _regionFilter?.name === row.Region.toLowerCase()
          )
        )
          return false;
        if (
          (categoryFilter ?? []).length > 0 &&
          !categoryFilter.find(
            (_categoryFilter) =>
              _categoryFilter?.name === row.Category.toLowerCase()
          )
        )
          return false;
        return Object.values(row).some((value) =>
          String(value).toLowerCase().includes(filterText.toLowerCase())
        );
      });
    }
    if (sortKey) {
      filteredData.sort((a, b) => {
        let aValue = (a as any)[sortKey];
        let bValue = (b as any)[sortKey];

        if (sortKey === "Rank_Index") {
          aValue = aValue ?? 101;
          bValue = bValue ?? 101;
        } else if (sortKey === "Rank_Difference") {
          aValue = Number(aValue);
          if (isNaN(aValue)) {
            aValue = "";
          }
          bValue = Number(bValue);
          if (isNaN(bValue)) {
            bValue = "";
          }
        }

        if (aValue < bValue) {
          return sortDirection === "asc" ? -1 : 1;
        } else if (aValue > bValue) {
          return sortDirection === "asc" ? 1 : -1;
        } else {
          return 1;
        }
      });
    }
    return filteredData;
  }, [
    constituents,
    filterText,
    regionFilter,
    categoryFilter,
    sortKey,
    sortDirection,
  ]);

  return (
    <Stack flex={1} pt={3}>
      {/* <PageSection title="Universe Constituents for:">
        <Box sx={{ minWidth: 220, flex: 1, width: "100%" }}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel
              id="quarter-universe-constituents"
              sx={{ background: "#fff", px: "7px" }}
            >
              Quarter
            </InputLabel>
            <Select
              labelId="quarter-universe-constituents"
              value={`${selectedQuarter}`}
              label="Year"
              onChange={(e) => setSelectedQuarter(e.target.value)}
            >
              {getQuarters({}).map((quarter) => (
                <MenuItem value={quarter}>{quarter.replace("_", " ")}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </PageSection> */}

      <Stack mb={3} flex={1}>
        <TextField
          size="small"
          placeholder="Search Companies"
          onChange={(e) => setFilterText(e.target.value)}
          id="outlined-start-adornment"
          InputLabelProps={{
            style: { color: "#fff" },
          }}
          InputProps={{
            style: {
              paddingTop: 0,
            },
            disableUnderline: true,
            startAdornment: (
              <InputAdornment
                id="jsd"
                sx={{
                  adornedStart: {
                    margin: "10px !important",
                  },
                }}
                position="start"
                disableTypography
                disablePointerEvents
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            width: "350px",
            "& .MuiFilledInput-root": {
              background: "#fff",
              borderRadius: "10px",
              border: "1px solid #F1F5F9",
              "& fieldset": {
                paddingTop: 0,
              },
            },
            "& .MuiFilledInput-input": {
              padding: 2,
              pl: "4px",
              color: "#64758B",
              fontWeight: 600,
            },
            "& .MuiInputAdornment-root": {
              margin: "0px !important",
            },
            borderBottom: 0,
          }}
          variant="filled"
        />
      </Stack>

      <Section>
        <Stack justifyContent="flex-start">
          <Typography variant="h6">Top 100</Typography>
        </Stack>
        <Typography
          sx={{
            textAlign: "left",
            color: "#64758B",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "20px",
            letterSpacing: "0.14px",
            mb: "24px",
          }}
        >
          List of over 300 companies regularly evaluated to find the best suited
          companies making it into our index.
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ maxHeight: "calc(100vh - 400px)", mt: 2, borderRadius: "12px", border: "1px solid #D8DEE6" }}
        >
          <Table sx={{ minWidth: 650 }} stickyHeader>
            <TableHead
              sx={{
                position: "sticky",
                top: 0,
                px: "160px",
                py: "8px",
              }}
            >
              {/* <TableRow sx={{

              }}>
                <TableCell colSpan={3} align="left" sx={{ textAlign: "right" }}>
                  <TextField
                    placeholder="Search..."
                    fullWidth
                    onChange={(e) => setFilterText(e.target.value)}
                    size="small"
                  />
                </TableCell>
                <TableCell colSpan={4} align="left" sx={{ textAlign: "right" }}>
                </TableCell>
              </TableRow> */}
              <TableRow
                sx={{
                  backgroundColor: "#F1F5F9",
                  borderRadius: "12px",
                  px: "160px",
                  py: "8px",
                  height: "40px",
                }}
              >
                <TableCell
                  sx={{
                    p: 0,
                    px: "16px",
                    background: "transparent",
                    color: "#64758B",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "24px",
                    borderBottom: "1.5px solid #D8DEE6",
                    cursor: "pointer"
                  }}
                  onClick={() => handleSort("Rank_Index")}
                  align="left"
                >
                    Rank 
                    { sortKey === "Rank_Index" && <TableSortIcon direction={sortDirection} />}
                </TableCell>
                <TableCell
                  sx={{
                    p: 0,
                    px: "16px",
                    pr: "6px",
                    background: "transparent",
                    color: "#64758B",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "24px",
                    borderBottom: "1.5px solid #D8DEE6",
                    cursor: "pointer"
                  }}
                  onClick={() => handleSort("Name")}
                  align="left"
                >
                  Name
                  { sortKey === "Name" && <TableSortIcon direction={sortDirection} />}
                </TableCell>
                <TableCell
                  sx={{
                    p: 0,
                    px: "16px",
                    pr: "6px",
                    background: "transparent",
                    color: "#64758B",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "24px",
                    borderBottom: "1.5px solid #D8DEE6",
                    cursor: "pointer"
                  }}
                  align="left"
                  onClick={() => handleSort("Category")}
                >
                  Segment
                  { sortKey === "Category" && <TableSortIcon direction={sortDirection} />}
                </TableCell>
                <TableCell
                  sx={{
                    p: 0,
                    pr: "6px",
                    px: "16px",
                    background: "transparent",
                    color: "#64758B",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "24px",
                    borderBottom: "1.5px solid #D8DEE6",
                    cursor: "pointer"
                  }}
                  align="left"
                  onClick={() => handleSort("Region")}
                >
                  Region
                  { sortKey === "Region" && <TableSortIcon direction={sortDirection} />}
                </TableCell>

                <TableCell
                  sx={{
                    p: 0,
                    pr: "6px",
                    px: "16px",
                    background: "transparent",
                    color: "#64758B",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "24px",
                    borderBottom: "1.5px solid #D8DEE6",
                    cursor: "pointer",
                  }}
                  align="left"
                  onClick={() => handleSort("Market_Capital")}
                >
                  Market Capital
                  { sortKey === "Market_Capital" && <TableSortIcon direction={sortDirection} />}
                </TableCell>
                <TableCell
                  sx={{
                    p: 0,
                    px: "16px",
                    pr: "16px",
                    background: "transparent",
                    color: "#64758B",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "24px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    borderBottom: "1.5px solid #D8DEE6",
                    cursor: "pointer"
                  }}
                  align="left"
                  onClick={() => handleSort("Rank_Difference")}
                >
                  Rank Difference
                  { sortKey === "Rank_Difference" && <TableSortIcon direction={sortDirection} />}
                </TableCell>
              </TableRow>
            </TableHead>
            {(!constituents || !constituents.length) && (
              <TableBody>
                <TableRow>
                  <TableCell
                    height={300}
                    sx={{ textAlign: "center", minHeight: "300px" }}
                    colSpan={7}
                  >
                    <Typography variant="h5">Nothing to display...</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {filteredData && (
              <TableBody>
                {[...filteredData].map((row, index) => (
                  <TableRow
                    key={`${row.ISIN}-${index}`}
                    id={`${row.ISIN}-${index}`}
                    sx={{
                      "& td": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell
                      style={{ fontWeight: 600, fontSize: "12px", }}
                      align="left"
                    >
                      {row.Rank_Index}
                    </TableCell>
                    <TableCell style={{ paddingRight: 40, fontSize: "12px", }} align="left">
                      <Stack direction="row" alignItems="left">
                        <Typography sx={{ fontWeight: 600, fontSize: "12px", }}>
                          {row.Name} {row.ISIN} ({index})
                        </Typography>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Grid container>
                                <Grid xs={6}>
                                  <Typography variant="body1">Name</Typography>
                                </Grid>
                                <Grid xs={6}>
                                  <Typography variant="body1">
                                    {row.Name}
                                  </Typography>
                                </Grid>
                                <Grid mt={2} xs={6}>
                                  <Typography variant="body1">
                                    Segment
                                  </Typography>
                                </Grid>
                                <Grid mt={2} xs={6}>
                                  <Typography variant="body1">
                                    {formattedLabels[row.Category] ||
                                      toTitleCase(row.Category)}
                                  </Typography>
                                </Grid>
                                <Grid mt={2} xs={6}>
                                  <Typography variant="body1">
                                    Country
                                  </Typography>
                                </Grid>
                                <Grid mt={2} xs={6}>
                                  <Typography variant="body1">
                                    {
                                      (
                                        countries as unknown as {
                                          [key: string]: string;
                                        }
                                      )?.[row.Country]
                                    }
                                  </Typography>
                                </Grid>
                                <Grid mt={2} xs={6}>
                                  <Typography variant="body1">
                                    IPO on
                                  </Typography>
                                </Grid>
                                <Grid mt={2} xs={6}>
                                  <Typography variant="body1">
                                    {row.Founded_In}
                                  </Typography>
                                </Grid>
                                <Grid mt={2} xs={6}>
                                  <Typography variant="body1">
                                    Annual Revenue
                                  </Typography>
                                </Grid>
                                <Grid mt={2} xs={6}>
                                  <Typography variant="body1">
                                    {row.Annual_Revenue}
                                  </Typography>
                                </Grid>
                                <Grid mt={2} xs={6}>
                                  <Typography variant="body1">
                                    Head Count
                                  </Typography>
                                </Grid>
                                <Grid mt={2} xs={6}>
                                  <Typography variant="body1">
                                    {row.Head_Count}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          }
                        >
                          <Stack sx={{ ml: 1, fontSize: 16 }}>
                            <InfoIcon fontSize="inherit" />
                          </Stack>
                        </HtmlTooltip>
                      </Stack>
                    </TableCell>
                    <TableCell sx={{
                      paddingRight: "40px",
                      color: "#000",
                      fontWeight: "500",
                      lineHeight: "16px",
                      letterSpacing: "0.24px",
                      fontSize: "12px",
                    }} align="left">
                      {formattedLabels[row.Category] ||
                        toTitleCase(row.Category)}
                    </TableCell>
                    <TableCell style={{
                      paddingRight: 40,
                      color: "#000",
                      fontWeight: "500",
                      lineHeight: "16px",
                      letterSpacing: "0.24px",
                      fontSize: "12px",
                    }} align="left">
                      {formattedLabels[row.Region] || toTitleCase(row.Region)}
                    </TableCell>
                    <TableCell
                      style={{ paddingRight: 40, fontWeight: 600, fontSize: "12px", }}
                      align="left"
                    >
                      {row.Market_Capital}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 700,
                        fontSize: "12px",
                        color:
                          Number(row.Rank_Difference) > 0
                            ? "#4ABA6B"
                            : Number(row.Rank_Difference) < 0
                            ? "#F73D48"
                            : "black",
                      }}
                      align="left"
                    >
                      {row.Rank_Difference ?? "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Section>
    </Stack>
  );
}
