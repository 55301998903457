import React, { useEffect, useMemo, useState } from "react";
import { Button,
    Checkbox,
    Chip,
    ClickAwayListener,
    FormControlLabel,
    FormGroup,
    IconButton,
    Stack,
    Tooltip,
    TooltipProps,
    Typography, 
    styled,
    tooltipClasses } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckboxChecked from "../../assets/checkbox-checked.png";
import CheckboxUnchecked from "../../assets/checkbox.png";

const options = ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"];

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 1)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        padding: 0,
        borderRadius: "12px",
        border: "1px solid var(--neutrals-l-ight-grey, #D8DEE6)",
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
        minHeight: 200,
        minWidth: 320

    },
}));

type AutocompleteProps = {
    label: string
    options: Array<{id: string, name: string}>
    values: Array<string>
    onChange: (id: Array<string>) => void
}

const Autocomplete = ({label, options, values, onChange} : AutocompleteProps) => {
    const [selection, setSelection] = React.useState<string[]>(values);
    const [isListOpen, setListOpen] = useState(false)

    //  update selected values
    useEffect(() => {
        setSelection(values)
    },[values])

    const displaySelectedOptionsAsChips = useMemo(() => {
        const chips : Array<JSX.Element> = values.slice(0, 2).map((value) => {
            const option = options.find((_option) => _option.id === value)

            return <Chip id={`chip-selection-${option?.id}`} size="small" sx={{ 
                borderRadius: '8px', 
                border: '1px solid #D8DEE6', 
                backgroundColor: '#fff',
                fontWeight: 600,
                mr:"8px",
                textTransform: "capitalize"
            }} label={option?.name} />
        })

        if( values.length > 2 ){
            chips.push(<Chip id={`chip-selection-extra`} size="small" sx={{ 
                borderRadius: '8px', 
                border: '1px solid', 
                borderColor: '#E2E8F0', 
                backgroundColor: '#fff',
                fontWeight: 600,
                mr:"8px"
            }} label={`+${values.length - 2}`} />)
        }
        return chips
    }, [values])
    
    const parsedOptions = useMemo(() => {
        return options.map(option => ({
            ...option,
            checked: selection.includes(option.id)
        }))
    }, [options, selection])

    const handleChange = (id: string) => {
        if( selection.includes(id) ){
            setSelection(selection.filter((value) => value !== id ))
        }else {
            setSelection([...selection, id])
        }
    }
    const handleClearAll = () => {
        setSelection([])
    }
    const handleApply = () => {
        onChange(selection)
        setListOpen(false)
    }

    return (
        <ClickAwayListener onClickAway={() => setListOpen(false)}>
            <Stack sx={{ 
                background: "#F1F5F9",
                p: "8px",
                pl: "16px",
                borderRadius: "12px",
                border: "1px solid #D8DEE6",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "40px",
                mr: 2,
                minWidth: "100px",
            }} flexDirection="row">
                <Typography sx={{ 
                    mr: "16px", 
                    color: 'var(--Primary-Black, #000)',
                    fontVariantNumeric: 'lining-nums tabular-nums',
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '20px',
            }} color="black">{label}</Typography>
                {displaySelectedOptionsAsChips}

                <HtmlTooltip
                    open={isListOpen}
                    title={
                        <React.Fragment>
                            <FormGroup sx={{gap: 1, padding: "8px", height: 200, overflow: "scroll", display: "block" }}>
                                {
                                    parsedOptions.map((option) => (
                                        <FormControlLabel
                                            key={`form-control-label-${option.id}`}
                                            sx={{ 
                                                background: `${option.checked ? '#F1F5F9' : undefined}`,
                                                fontSize: "600",
                                                borderRadius: "8px",
                                                position: "relative",
                                                left: 10,
                                                width: "100%",
                                                mt: 1,
                                            }}
                                            onChange={() => handleChange(option.id)}
                                            control={<Checkbox icon={<img src={CheckboxUnchecked} width={16} height={16} />} checkedIcon={<img src={CheckboxChecked} width={16} height={16}  />} checked={option.checked} />}
                                            label={<Typography sx={{ fontSize: "14px", fontWeight: "600" }} variant="body2">{option.name}</Typography>}
                                        />
                                    ))
                                }
                            </FormGroup>
                            <Stack sx={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                borderTop: "1px solid #D8DEE6",
                                p: "8px",
                                background: "#F1F5F9",
                                borderRadius: "0 0 12px 12px"
                            }}>
                                <Button 
                                    sx={{ 
                                        fontSize: "12px",
                                        textTransform: "none",
                                        color: '#020617',
                                        textAlign: 'center',
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '16px' 
                                    }}
                                    variant="text"
                                    color="inherit"
                                    onClick={handleClearAll}
                                >Clear All</Button>
                                <Button sx={{
                                    width: "43px",
                                    height: "40px",
                                    background: "#fff",
                                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
                                    borderRadius: "10px",
                                    zIndex: 2,
                                    textDecoration: "none",
                                    textTransform: "capitalize",

                                    color: '#020617',
                                    textAlign: 'center',
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '16px'

                                }} variant="contained" color="inherit" onClick={handleApply}>Apply</Button>
                            </Stack>
                        </React.Fragment>
                    }
                >
                    <IconButton sx={{ p:0, width: "14px" }} onClick={() => setListOpen(true)}>
                        <KeyboardArrowDownIcon />
                    </IconButton>
                </HtmlTooltip>


            </Stack>
        </ClickAwayListener>
        // <Autocomplete
        //   multiple
        //   id="tags-filled"
        //   options={options}
        //   value={value}
        //   onChange={(event, newValue) => {
        //     setValue(newValue);
        //   }}
        //   renderInput={(params) => (
        //     <TextField {...params} variant="filled" label="Tags" />
        //   )}
        //   renderTags={(value, getTagProps) =>
        //     value.map((option, index) => (
        //       <Chip
        //         label={option}
        //         {...getTagProps({ index })}
        //         onDelete={() => {
        //           const newValue = [...value];
        //           newValue.splice(index, 1);
        //           setValue(newValue);
        //         }}
        //       />
        //     ))
        //   }
        //   renderOption={(props, option, { selected }) => (
        //     <li {...props}>
        //       {option}
        //     </li>
        //   )}
        //   isOptionEqualToValue={(option, value) =>
        //     option === value || value.includes(option)
        //   }
        //   popupIcon={<></>}
        //   ListboxProps={{
        //     style: { maxHeight: 200, overflowY: "auto" }
        //   }}
        // />
    );
}

export default Autocomplete;
