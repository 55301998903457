import { useEffect, useMemo, useState } from "react"
import LineChart from "./LineChart"
import { getMarketCapital } from 'API';
import { MarketCapital as MarketCapitalData } from "types";
import { Stack, Typography } from "@mui/material";
import Section from "components/section/Index";
import ColorBadge from "components/color-badge";

type IMarketCapitalProps = {
    startDate: string
    endDate: string
    isins: Array<string>
    regions: Array<string>
    categories: Array<string>
}
export const MarketCapital = ({startDate, endDate, isins, regions, categories}: IMarketCapitalProps): JSX.Element => {
    const [marketCapital, setMarketCapital] = useState<{[name: string]: {name: string, market_capital: MarketCapitalData[], color: string}}>()

    const fetchData = async () => {

        const result = await getMarketCapital({ 
            start_date: startDate,
            end_date: endDate,
            isins,
            regions,
            categories,
        })
        setMarketCapital(result.data ?? {})
    }

    useEffect(() => {
        fetchData()
    }, [isins, regions, categories ,startDate, endDate])

    const labels = useMemo(() => {
        return (Object.values(marketCapital ?? {})?.[0]?.market_capital ?? []).map((market_cap) => market_cap.date)
     }, [marketCapital])
     const datasets = useMemo(() => {
         return (Object.keys(marketCapital ?? {}).map( (isin) => {
             return {
                 label: marketCapital?.[isin].name ?? 'N/A',
                 data: (marketCapital?.[isin].market_capital ?? []).map(market_cap => market_cap.change_percentage),
                 fill: false,
                 borderColor: marketCapital?.[isin].color ?? '#000',
                 borderWidth: 2,
                 pointStyle: 'false',
                 pointRadius: 1,
             }
         }))
     }, [marketCapital])

    return (
        <Section>
            <Typography sx={{ textAlign: "left" }} variant="h6">
                Stock Price
            </Typography>
            <LineChart labels={labels} datasets={datasets} />
            <Stack flexDirection="row" flexWrap="wrap" gap={1} mt={1}>
                {
                    datasets.map(dataset => (
                        <ColorBadge color={dataset.borderColor} title={dataset.label} />
                    ))
                }
            </Stack>
            
        </Section>
    )
}