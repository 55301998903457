import { Stack, Typography } from "@mui/material"

type ColorBadge = {
    color: string
    title: string
}
const ColorBadge = ({color, title}: ColorBadge) => {
    return (
        <Stack alignItems="center" flexDirection="row" sx={{
            padding: "6px 14px 6px 14px",
            gap: "10px",
            background: "#F1F5F9",
            borderRadius: "8px"
        }}>
            <div style={{ backgroundColor: color, width: "8px", height: "8px", borderRadius: "2px" }} />
            <Typography sx={{ textTransform: "none", fontSize: "11px", fontWeight: 600 }} variant="body1">{title}</Typography>
        </Stack>
    )
}

export default ColorBadge