import { animated, useSpring } from '@react-spring/web';

const AnimatedText = ({
  children,
  from,
  to,
  orientation,
}: {
  children: JSX.Element;
  from: number;
  to: number;
  orientation: 'X' | 'y';
}) => {
  const props = useSpring({
    to: { opacity: 1, transform: `translate${orientation}(${to}px)` },
    from: { opacity: 0, transform: `translate${orientation}(${from}px)` },
    config: { tension: 250, friction: 25 },
  });

  return <animated.div style={props}>{children}</animated.div>;
};

export default AnimatedText;
