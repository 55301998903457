import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

// MUI
import { Grid, Stack, TextField, TextFieldProps } from "@mui/material";

// Firebase
import { get, getDatabase, ref, set } from "firebase/database";

// Custom
import { UIContext } from "../UIProvider";
import {
  AUTO100Ctx,
  CompanyTypes,
  Weights,
  WeightTypes,
  WorkEnvironment,
} from "../AUTO100DataProvider";
import { WhiteButton } from "./Buttons";
import { ConfirmationModal } from "./CustomModal";
import { WeightOptions } from "./WeightOptions";
import WeightsTable from "./WeightsTable";

export const NumberInput: FC<TextFieldProps> = (props) => {
  const quantityInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const ignoreScroll = (ev: WheelEvent) => {
      ev.preventDefault();
    };
    quantityInputRef.current &&
      quantityInputRef.current.addEventListener("wheel", ignoreScroll);
  }, [quantityInputRef]);

  return <TextField ref={quantityInputRef} type="number" {...props} />;
};

const companyTypes = [
  "supplier",
  "oem",
  "dealer",
  "mobility",
  "infrastructure",
];

const strategy_scores = [
  { id: "St-1", tooltip: "St-1" },
  { id: "St-2", tooltip: "St-2" },
  { id: "St-3", tooltip: "St-3" },
  { id: "St-4", tooltip: "St-4" },
  { id: "St-5", tooltip: "St-5" },
  { id: "St-6", tooltip: "St-6" },
  { id: "St-7", tooltip: "St-7" },
  { id: "St-10", tooltip: "St-10" },
  { id: "St-11", tooltip: "St-11" },
  { id: "St-12", tooltip: "St-12" },
];
const relationship_scores = [
  { id: "R-SO-1", tooltip: "R-SO-1" },
  { id: "R-SO-2", tooltip: "R-SO-2" },
  { id: "R-SO-3", tooltip: "R-SO-3" },
  { id: "R-OR-1", tooltip: "R-OR-1" },
  { id: "R-OR-2", tooltip: "R-OR-2" },
  { id: "R-OR-3", tooltip: "R-OR-3" },
  { id: "R-OM-1", tooltip: "R-OM-1" },
  { id: "R-OM-2", tooltip: "R-OM-2" },
  { id: "R-OI-1", tooltip: "R-OI-1" },
  { id: "R-OI-2", tooltip: "R-OI-2" },
];
const sentiment_scores = [
  { id: "Se-F-1", tooltip: "Sentiment F 1" },
  { id: "Se-L-1", tooltip: "Sentiment L 2" },
];
const subscores = [
  { id: "strategy", tooltip: "Strategy" },
  { id: "relationship", tooltip: "Relationship" },
  { id: "sentiment", tooltip: "Sentiment" },
];

const SubScoreWeightsModule: FC<{ workEnvironment: WorkEnvironment, isFrozen?: boolean }> = ({
  workEnvironment, isFrozen = false,
}) => {
  const { selectedRebalancingOption: rebalancing_option } =
    useContext(AUTO100Ctx);

  const [strategyWeights, setStrategyWeights] =
    useState<Weights[WeightTypes.Strategy]>();
  const [strategyDefaultWeights, setStrategyDefaultWeights] =
    useState<Weights[WeightTypes.Strategy]>();
  const [relationshipWeights, setRelationshipWeights] =
    useState<Weights[WeightTypes.Relationship]>();
  const [relationshipDefaultWeights, setRelationshipDefaultWeights] =
    useState<Weights[WeightTypes.Relationship]>();
  const [sentimentWeights, setSentimentWeights] =
    useState<Weights[WeightTypes.Sentiment]>();
  const [sentimentDefaultWeights, setSentimentDefaultWeights] =
    useState<Weights[WeightTypes.Sentiment]>();
  const [subScoreWeights, setSubScoreWeights] =
    useState<Weights[WeightTypes.Sentiment]>();
  const [subScoreDefaultWeights, setSubScoreDefaultWeights] =
    useState<Weights[WeightTypes.Sentiment]>();

  const { addNotification } = useContext(UIContext);

  const db = getDatabase();

  const fetchData = useCallback(
    async function () {
      const defaultWeightsSnapshot = await get(
        ref(db, `AUTO100/default_weights`)
      );
      const defaultWeightsData = defaultWeightsSnapshot.val();
      if (defaultWeightsSnapshot.exists()) {
        //  set default weights
        const weights = defaultWeightsData as Weights;

        setStrategyDefaultWeights(weights?.strategy);
        setRelationshipDefaultWeights(weights?.relationship);
        setSentimentDefaultWeights(weights?.sentiment);
        setSubScoreDefaultWeights(weights?.subscore);
      }

      let snapshot = await get(
        ref(db, `AUTO100/${workEnvironment}/${rebalancing_option}/weights`)
      );
      let data = snapshot.val();
      if (snapshot.exists()) {
        //  set current weights
        const weights = data as Weights;

        setStrategyWeights(weights?.strategy);
        setRelationshipWeights(weights?.relationship);
        setSentimentWeights(weights?.sentiment);
        setSubScoreWeights(weights?.subscore);
      } else {
        //  if current weight do not exist
        //  set default weights as current weights
        const weights = defaultWeightsData as Weights;

        setStrategyWeights(weights?.strategy);
        setRelationshipWeights(weights?.relationship);
        setSentimentWeights(weights?.sentiment);
        setSubScoreWeights(weights?.subscore);
      }
    },
    [db, workEnvironment, rebalancing_option]
  );

  useEffect(() => {
    fetchData();
  }, [rebalancing_option, fetchData]);

  // Save Weights Confirmation Modal
  const [
    saveWeightsConfirmationModalOpen,
    setSaveWeightsConfirmationModalOpen,
  ] = useState(false);

  const handleWeightChange = (
    weights: Weights[keyof Weights],
    weightTypes: WeightTypes
  ) => {
    if (weightTypes === WeightTypes.Strategy) {
      setStrategyWeights(weights);
    }
    if (weightTypes === WeightTypes.Relationship) {
      setRelationshipWeights(weights);
    }
    if (weightTypes === WeightTypes.Sentiment) {
      setSentimentWeights(weights);
    }
    if (weightTypes === WeightTypes.Subscore) {
      setSubScoreWeights(weights);
    }
  };

  const saveWeightsToRealtimeDatabase = async () => {
    if (strategyWeights) {
      const weightsRef = ref(
        db,
        `AUTO100/${workEnvironment}/${rebalancing_option}/weights/${WeightTypes.Strategy}`
      );
      await set(weightsRef, strategyWeights);
    }
    if (relationshipWeights) {
      const weightsRef = ref(
        db,
        `AUTO100/${workEnvironment}/${rebalancing_option}/weights/${WeightTypes.Relationship}`
      );
      await set(weightsRef, relationshipWeights);
    }
    if (sentimentWeights) {
      const weightsRef = ref(
        db,
        `AUTO100/${workEnvironment}/${rebalancing_option}/weights/${WeightTypes.Sentiment}`
      );
      await set(weightsRef, sentimentWeights);
    }
    if (subScoreWeights) {
      const weightsRef = ref(
        db,
        `AUTO100/${workEnvironment}/${rebalancing_option}/weights/${WeightTypes.Subscore}`
      );
      await set(weightsRef, subScoreWeights);
    }
    if (
      strategyWeights ||
      relationshipWeights ||
      sentimentWeights ||
      subScoreWeights
    ) {
      addNotification({
        id: `WeightsSaveSuccess`,
        type: "success",
        message: `Weights saved successfully!`,
      });
    } else {
      addNotification({
        id: `WeightsSaveError`,
        type: "warning",
        message: `No change detected to be saved.`,
      });
    }
  };

  const downloadJSON = () => {
    const data = {
      relationship_weights: relationshipWeights,
      sentiment_weights: sentimentWeights,
      strategy_weights: strategyWeights,
      subscore_weights: subScoreWeights,
    };
    const fileData = JSON.stringify(data, null, 4);
    const blob = new Blob([fileData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "Auto100_Weights.json";
    link.href = url;
    link.click();
  };
  const uploadJSON = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files ?? [];
    if (files.length) {
      const file = files[0];

      //  read selected file
      const fileReader = new FileReader();
      fileReader.readAsText(file, "UTF-8");
      fileReader.onload = (fr) => {
        const {
          relationship_weights,
          sentiment_weights,
          strategy_weights,
          subscore_weights,
        } = JSON.parse(fr.target?.result + "" ?? "{}");
        //  set data
        setStrategyWeights(strategy_weights);
        setRelationshipWeights(relationship_weights);
        setSentimentWeights(sentiment_weights);
        setSubScoreWeights(subscore_weights);
      };
    }
  };
  const resetWeights = () => {
    const _weights = {
      [WeightTypes.Strategy]: { ...strategyWeights },
      [WeightTypes.Relationship]: { ...relationshipWeights },
      [WeightTypes.Sentiment]: { ...sentimentWeights },
      [WeightTypes.Subscore]: { ...subScoreWeights },
    };
    const _defaultWeights = {
      [WeightTypes.Strategy]: { ...strategyDefaultWeights },
      [WeightTypes.Relationship]: { ...relationshipDefaultWeights },
      [WeightTypes.Sentiment]: { ...sentimentDefaultWeights },
      [WeightTypes.Subscore]: { ...subScoreDefaultWeights },
    };
    const weightKeys = Object.keys(_weights) as Array<WeightTypes>;
    weightKeys.forEach((weightType) => {
      for (const scoreKey of Object.keys(_weights[weightType])) {
        for (const companyType of Object.keys(_weights[weightType][scoreKey])) {
          let weight =
            _weights[weightType][scoreKey][companyType as CompanyTypes];
          let defaultWeight =
            _defaultWeights[weightType][scoreKey][companyType as CompanyTypes];
          if (weight) weight = defaultWeight;
          _weights[weightType][scoreKey][companyType as CompanyTypes] = weight;
        }
      }
    });
    if (_weights[WeightTypes.Strategy])
      handleWeightChange(_weights[WeightTypes.Strategy], WeightTypes.Strategy);
    if (_weights[WeightTypes.Relationship])
      handleWeightChange(
        _weights[WeightTypes.Relationship],
        WeightTypes.Relationship
      );
    if (_weights[WeightTypes.Sentiment])
      handleWeightChange(
        _weights[WeightTypes.Sentiment],
        WeightTypes.Sentiment
      );
    if (_weights[WeightTypes.Subscore])
      handleWeightChange(_weights[WeightTypes.Subscore], WeightTypes.Subscore);
  };
  const distributeWeightsEqually = () => {
    const _weights = {
      [WeightTypes.Strategy]: { ...strategyWeights },
      [WeightTypes.Relationship]: { ...relationshipWeights },
      [WeightTypes.Sentiment]: { ...sentimentWeights },
      [WeightTypes.Subscore]: { ...subScoreWeights },
    };
    const weightKeys = Object.keys(_weights) as Array<WeightTypes>;
    const _companyTypes = companyTypes as Array<CompanyTypes>;
    weightKeys.forEach((weightType) => {
      _companyTypes.forEach((_companyType) => {
        const scoreKeys = Object.keys(_weights[weightType]);
        const filteredSocreKeys: Array<string> = [];
        scoreKeys.forEach((scoreKey) => {
          if (Number(_weights[weightType][scoreKey][_companyType] ?? 0) > 0) {
            filteredSocreKeys.push(scoreKey);
          }
        });

        const equallyDistributedWeight = Number(
          (1 / filteredSocreKeys.length).toFixed(2)
        );
        const estimatedSum =
          equallyDistributedWeight * filteredSocreKeys.length;
        let roundOffToAdd =
          1 - estimatedSum !== 0
            ? Number((1 - estimatedSum).toFixed(2))
            : undefined;

        filteredSocreKeys.forEach((filteredScoreKey) => {
          let weight = _weights[weightType][filteredScoreKey][_companyType];
          if (weight) {
            weight = Number(
              (equallyDistributedWeight + (roundOffToAdd ?? 0)).toFixed(2)
            );
          }
          _weights[weightType][filteredScoreKey][_companyType] = weight;
          if (roundOffToAdd) roundOffToAdd = undefined;
        });
      });
    });

    if (_weights[WeightTypes.Strategy])
      handleWeightChange(_weights[WeightTypes.Strategy], WeightTypes.Strategy);
    if (_weights[WeightTypes.Relationship])
      handleWeightChange(
        _weights[WeightTypes.Relationship],
        WeightTypes.Relationship
      );
    if (_weights[WeightTypes.Sentiment])
      handleWeightChange(
        _weights[WeightTypes.Sentiment],
        WeightTypes.Sentiment
      );
    if (_weights[WeightTypes.Subscore])
      handleWeightChange(_weights[WeightTypes.Subscore], WeightTypes.Subscore);
  };

  const handleSelectedOption = (option: string) => {
    if (option === "download_weights") {
      downloadJSON();
    } else if (option === "upload_weights") {
      document.getElementById("upload-json-file")?.click();
    } else if (option === "reset_weights") {
      resetWeights();
    } else if (option === "distribute_equally") {
      distributeWeightsEqually();
    }
  };

  return (
    <Stack>
      <Stack
        direction="row"
        sx={{ position: "absolute", right: 20, top: 20, borderRadius: 2 }}
      >
        <input
          id="upload-json-file"
          type="file"
          hidden
          accept="application/JSON"
          onChange={(e) => uploadJSON(e)}
        />

        <WeightOptions onOptionSelected={handleSelectedOption} isFrozen={isFrozen} />
      </Stack>

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                         CONFIRMATION MODAL                     //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <ConfirmationModal
        title="Save Weights?"
        description="This action will override the weights currently available in the database. "
        open={saveWeightsConfirmationModalOpen}
        onClose={() => {
          setSaveWeightsConfirmationModalOpen(false);
        }}
        onConfirm={async () => {
          await saveWeightsToRealtimeDatabase();
          setSaveWeightsConfirmationModalOpen(false);
        }}
        onCancel={() => {
          setSaveWeightsConfirmationModalOpen(false);
        }}
      />

      <Grid container spacing={2} marginTop={1}>
        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////                        STRATEGY SCORE WEIGHTS                  //// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <WeightsTable
            title="Strategy"
            weights={strategyWeights}
            scoreKeys={strategy_scores}
            onChange={(weights) =>
              handleWeightChange(weights, WeightTypes.Strategy)
            }
            isFrozen={isFrozen}
          />
        </Grid>

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////                     RELATIONSHIP SCORE WEIGHTS                 //// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <WeightsTable
            title="Relationship"
            weights={relationshipWeights}
            scoreKeys={relationship_scores}
            onChange={(weights) =>
              handleWeightChange(weights, WeightTypes.Relationship)
            }
            isFrozen={isFrozen}
          />
        </Grid>

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////                       SENTIMENT SCORE WEIGHTS                  //// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
          <WeightsTable
            title="Sentiment"
            weights={sentimentWeights}
            scoreKeys={sentiment_scores}
            onChange={(weights) =>
              handleWeightChange(weights, WeightTypes.Sentiment)
            }
            isFrozen={isFrozen}
          />
        </Grid>

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////                         SUB SCORE WEIGHTS                      //// */}
        {/* //////////////////////////////////////////////////////////////////////// */}
        <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
          <WeightsTable
            isSubscore
            title="Sub-Component"
            weights={subScoreWeights}
            scoreKeys={subscores}
            onChange={(weights) =>
              handleWeightChange(weights, WeightTypes.Subscore)
            }
            isFrozen={isFrozen}
          />
        </Grid>
      </Grid>
      <Stack direction={"row"} justifyContent="flex-end" marginTop={2}>
        <WhiteButton disabled={isFrozen} onClick={() => setSaveWeightsConfirmationModalOpen(true)}>
          Save Weights
        </WhiteButton>
      </Stack>
    </Stack>
  );
};

export default SubScoreWeightsModule;
