import { Button, styled } from '@mui/material';

export const WhiteButton = styled(Button)({
  background: 'white',
  backgroundSize: 'contain',
  width: '150px',
  height: '40px',
  cursor: 'pointer',
  display: 'flex',
  color: '#666',
  border: '2px solid #666',
  fontWeight: 600,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'none',
  '&:hover': {
    background: '#F1F1F1',
  },
});
