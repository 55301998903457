import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { getTopGainersAndLosers } from "API"
import { useEffect, useState } from "react"
import CustomizedTables from "./CustomizedTable"
import { TopGainersOrLosers } from "types"
import moment from "moment"

type TopGainersAndLosersProps = {
    startDate: string
    endDate: string
}
export const TopGainersAndLosers = ({ startDate, endDate }: TopGainersAndLosersProps): JSX.Element => {
    const [topGainers, setTopGainers] = useState<Array<TopGainersOrLosers>>()
    const [topLosers, setTopLosers] = useState<Array<TopGainersOrLosers>>()

    const fetchData = async () => {
        const result = (
            await getTopGainersAndLosers({start_date: startDate, end_date: endDate})
        )
        setTopGainers(result?.data?.["top_gainers"] ?? [])
        setTopLosers(result?.data?.["top_losers"] ?? [])
    }

    useEffect(() => {
        fetchData()
    }, [startDate, endDate])

    return (
        <Stack flex={1} flexDirection="row" gap={2}>
            <CustomizedTables performers={topGainers ?? []} title="Top Gainers" />
            <CustomizedTables performers={topLosers ?? []} title="Top Losers" />
        </Stack>
    )
}