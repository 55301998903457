import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Stack, Typography } from '@mui/material';

type TableSortIconProps = {
    direction: "asc" | "desc"
}
export const TableSortIcon = ({direction}: TableSortIconProps) => {
    return (
        <Typography variant="button">
            {
                direction === "asc" ? 
                    <ArrowDropUpIcon sx={{ color: "#64758B", width: "20px", height: "20px", marginBottom: "-5px" }} />
                :
                    <ArrowDropDownIcon sx={{ color: "#64758B", width: "20px", height: "20px", marginBottom: "-5px" }} />
            }
        </Typography>
    )
}