import { Grid, Typography } from "@mui/material";

const PermissionDenied = () => {
  return (
    <Grid
      container
      alignContent={"center"}
      flexDirection="column"
      alignItems={"center"}
      justifyContent="center"
      sx={{ height: "100%" }}
    >
      <Typography variant="h2">
        <b>Error 403</b>
      </Typography>
      <Typography variant="h5">
        You don't have permissions to view this page.
      </Typography>
    </Grid>
  );
};

export default PermissionDenied;
