import React, { FC, useState } from 'react';

// MUI
import { Stack, MenuItem, Menu } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';

interface OptionProps {
  isFrozen?: boolean;
  style?: React.CSSProperties;
  onOptionSelected?: (option: string) => void;
}

export const CustomizedTableOptions: FC<OptionProps> = ({ isFrozen= false, style = {}, onOptionSelected }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelectAndClose = (option: string) => {
    handleClose();
    if (onOptionSelected) {
      onOptionSelected(option);
    }
  };

  return (
    <Stack
      style={{
        ...style,
        zIndex: 25,
      }}
    >
      <Stack id="MoreVertIconStack" justifyContent={'center'}>
        <div id="MoreVertIcon" onClick={handleClick} style={{ display: 'flex' }}>
          <MoreVert sx={{ width: '15px', height: '30px', cursor: 'pointer' }} />
        </div>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem disabled={isFrozen} onClick={() => handleSelectAndClose('download_raw_files')}>Download Raw Files</MenuItem>
      </Menu>
    </Stack>
  );
};
