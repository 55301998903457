const MarketAnalysisSVG = ({fill="none"}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill={`${fill}`} xmlns="http://www.w3.org/2000/svg">
            <path d="M6.67082 4.16895H9.17083V11.6689H6.67082V14.1689H5.00415V11.6689H2.50415V4.16895H5.00415V1.66895H6.67082V4.16895ZM15.0042 8.33559H17.5042V15.8356H15.0042V18.3356H13.3375V15.8356H10.8375V8.33559H13.3375V5.83561H15.0042V8.33559Z" fill={`${fill}`}/>
        </svg>
    )
}

MarketAnalysisSVG.defaultProps = {
    fill: "none"
}

export default MarketAnalysisSVG