import { Box, Checkbox, Chip, Container, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, SelectProps, Stack, Typography } from "@mui/material"
import { AUTO100Ctx, Company, Region, Category, Metric } from "AUTO100DataProvider";
import { AuthCtx } from "AuthProvider";
import Virtualize from "components/Autocomplete";
import BasicBreadcrumbs from "components/BreadCrumbsNavigation"
import { ref as dbRef, getDatabase, set } from "firebase/database";
import React, { useContext, useMemo } from "react";
import LineChart from "./LineChart";
import CustomizedTable from "./CustomizedTable";
import { TopGainersAndLosers } from "./TopGainersAndLosers";
import { RadarChart } from "../RadarChart";
import { FinancialInformation } from "./FinancialInformation";
import { MarketCapital } from "./MarketCapital";
import moment from "moment";

type ISelectInputProps<K> = {
  showChips?: boolean;
  inputLabel: string;
  valueKey: keyof K;
  lableKey: keyof K;
  labelId: string;
  availableValues: Array<K>;
  selectedValues: Array<keyof K>;
} & Exclude<SelectProps, "renderValue">;

function SelectInput<K>({
  showChips = false,
  inputLabel,
  valueKey,
  lableKey,
  labelId,
  availableValues,
  selectedValues,
  ...props
}: ISelectInputProps<K>) {
  return (
    <FormControl fullWidth sx={{ minWidth: "300px" }}>
      <InputLabel
        id={labelId}
        sx={{ background: "#fff", px: "7px", py: "1px" }}
      >
        {inputLabel}
      </InputLabel>
      <Select
        labelId={labelId}
        value={selectedValues}
        renderValue={(valuesToRender) => (
          <Box sx={{ display: "flex", flexWrap: "no-wrap", gap: 0.5 }}>
            {(valuesToRender as Array<K>).map((valueToRender) => (
              <Chip
                key={`select-value-${valueToRender[valueKey]}`}
                label={valueToRender?.[lableKey]}
              />
            ))}
          </Box>
        )}
        {...props}
      >
        {(availableValues ?? []).map((availableValue) => (
          <MenuItem
            key={`select-menu-${inputLabel}-${availableValue[valueKey]}`}
            value={availableValue[valueKey] as unknown as string | number}
          >
            <Checkbox
              checked={selectedValues.includes(
                availableValue[valueKey] as keyof K
              )}
            />
            <ListItemText primary={availableValue[lableKey]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const BasicSelect = () : JSX.Element => {
  const { user } = useContext(AuthCtx);
  const {
    analytics: {
      companies,
      categories,
      regions,
      metrics,
      userPreference: { stockPerformanceComparisonPreferences, setStockPerformanceComparisonPreferences },
    },
  } = useContext(AUTO100Ctx);
  const db = getDatabase();

  const userPreferenceUrl = useMemo(
    () =>
      user
        ? `AUTO100/user_preferences/${user.uid}/analytics/stockPerformanceComparisonPreferences`
        : undefined,
    [user]
  );

  const handleCompaniesChange = async (_companies: Array<Company>) => {
    const userPreferenceRef = dbRef(db, `${userPreferenceUrl}/company`);
    await set(userPreferenceRef, _companies);
    setStockPerformanceComparisonPreferences({
      ...(stockPerformanceComparisonPreferences ?? {}),
      company: _companies,
    });
  };
  const handleRegionChange = async (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value: selectedValues },
    } = event;

    const userPreferenceRef = dbRef(db, `${userPreferenceUrl}/region`);
    await set(userPreferenceRef, selectedValues);
    setStockPerformanceComparisonPreferences({
      ...(stockPerformanceComparisonPreferences ?? {}),
      region: selectedValues as Array<Region["id"]>,
    });
  };
  const handleCategoryChange = async (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value: selectedValues },
    } = event;

    const userPreferenceRef = dbRef(db, `${userPreferenceUrl}/segment`);
    await set(userPreferenceRef, selectedValues);
    setStockPerformanceComparisonPreferences({
      ...(stockPerformanceComparisonPreferences ?? {}),
      segment: selectedValues as Array<Category["id"]>,
    });
  };
  const handleMetricsChange = async (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value: selectedValues },
    } = event;

    const userPreferenceRef = dbRef(db, `${userPreferenceUrl}/metric`);
    await set(userPreferenceRef, selectedValues);
    setStockPerformanceComparisonPreferences({
      ...(stockPerformanceComparisonPreferences ?? {}),
      metric: selectedValues as Array<Metric["id"]>,
    });
  };
  return (
    <Stack direction={"row"} spacing={1}>
      <Stack flex={7}>
        <Virtualize
          options={companies.sort(function (a, b) {
            return a.Name.localeCompare(b.Name);
          })}
          value={stockPerformanceComparisonPreferences?.company ?? []}
          onChange={handleCompaniesChange}
        />
      </Stack>
      <Stack flex={1}>
        <SelectInput
          showChips={true}
          multiple
          inputLabel={"Segment"}
          valueKey={"id"}
          lableKey={"label"}
          labelId="segment-select"
          availableValues={categories}
          selectedValues={
            (stockPerformanceComparisonPreferences?.segment as unknown as Array<
              keyof Region
            >) ?? []
          }
          renderValue={(selected) =>
            (selected as Array<unknown>).length + " Selected"
          }
          onChange={handleCategoryChange}
        />
      </Stack>
      <Stack flex={1}>
        <SelectInput
          showChips={true}
          multiple
          inputLabel={"Region"}
          valueKey={"id"}
          lableKey={"label"}
          labelId="region-select"
          availableValues={regions}
          selectedValues={
            (stockPerformanceComparisonPreferences?.region as unknown as Array<
              keyof Region
            >) ?? []
          }
          renderValue={(selected) =>
            (selected as Array<unknown>).length + " Selected"
          }
          onChange={handleRegionChange}
        />
      </Stack>
      <Stack flex={1}>
        <SelectInput
          disabled
          showChips={true}
          multiple
          inputLabel={"Metrics"}
          valueKey={"id"}
          lableKey={"label"}
          labelId="metric-select"
          availableValues={metrics}
          selectedValues={
            (stockPerformanceComparisonPreferences?.metric as unknown as Array<
              keyof Metric
            >) ?? []
          }
          renderValue={(selected) =>
            (selected as Array<unknown>).length + " Selected"
          }
          onChange={handleMetricsChange}
        />
      </Stack>
    </Stack>
  );
}

const StockPerformanceComparison = () => {


  const {
    analytics: {
      companies,
      categories,
      regions,
      metrics,
      userPreference: { stockPerformanceComparisonPreferences, setStockPerformanceComparisonPreferences },
    },
  } = useContext(AUTO100Ctx);

  const selectedIsins = useMemo(() => (
    (stockPerformanceComparisonPreferences?.company?? []).map((company) => (
      company.ISIN
  ))), [stockPerformanceComparisonPreferences?.company])

  const selectedRegions = useMemo(() => regions
  .filter((region) =>
    (stockPerformanceComparisonPreferences?.region ?? []).includes(region.id)
  )
  .map((region) => region.name.toLowerCase()), [stockPerformanceComparisonPreferences?.region])

  const selectedCategories = useMemo(() => categories
  .filter((category) =>
    (stockPerformanceComparisonPreferences?.segment ?? []).includes(category.id)
  )
  .map((category) => category.name.toLowerCase()), [stockPerformanceComparisonPreferences?.segment])

  const startDate = useMemo(() => {
    return moment().subtract(stockPerformanceComparisonPreferences?.timeFrame, 'days').format('YYYY-MM-DD')
  }, [stockPerformanceComparisonPreferences?.timeFrame])
  const endDate = useMemo(() => {
    return moment().format('YYYY-MM-DD')
  }, [])
  const financialInformationQuarter = useMemo(() => {
    return stockPerformanceComparisonPreferences?.financialQuarter?.split("_").pop()?.replace("Q","") ?? ""
  }, [stockPerformanceComparisonPreferences?.financialQuarter])
  const financialInformationYear = useMemo(() => {
    return stockPerformanceComparisonPreferences?.financialQuarter?.split("_")?.[0] ?? ""
  }, [stockPerformanceComparisonPreferences?.financialQuarter])

  return (
    <Stack py={2}>
      <Stack flex={1} direction={"row"} gap={2} mb={2}>
        <Stack minHeight={500} flex={2}>
          <MarketCapital startDate={startDate} endDate={endDate} isins={selectedIsins} regions={selectedRegions} categories={selectedCategories} />
        </Stack>
        <Stack minHeight={500} flex={1}>
          <FinancialInformation isins={selectedIsins} selectedYear={financialInformationYear} selectedQuarter={financialInformationQuarter} />
        </Stack>
        {/* <FinancialInformation isins={selectedIsins} /> */}
      </Stack>

      <Stack flex={1} direction={"row"}>
        <TopGainersAndLosers startDate={startDate} endDate={endDate} />
      </Stack>
    </Stack>
  )
}

export default StockPerformanceComparison