import {
  FC,
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react";
import {
  Stack,
  Box,
  Typography,
  IconButton,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
} from "@mui/material";
import {
  HourglassBottom as HourglassBottomIcon,
  Upload as UploadIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Dangerous as DangerousIcon,
} from "@mui/icons-material";
import moment from "moment";

//  Firebase
import { ref as dbRef, onValue } from "firebase/database";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { database } from "../Firebase";

//  CUSTOM
import { AuthCtx } from "../AuthProvider";
import {
  FileUploadStatus,
  RebalancingOption,
  ScoreFile,
  WorkEnvironment,
} from "../AUTO100DataProvider";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface FileUploadRowProps {
  year: number;
  quarter: number;
  rebalancingOption: RebalancingOption;
  workEnvironment: WorkEnvironment;
  dependenciesMissing?: boolean;
  onFileSelected?: (file: File) => void;
  onFileValidStatusChange?: (scoreFile: ScoreFile, isValid: boolean) => void;
  scoreFile: ScoreFile;
  isRootFile?: boolean;
  isFrozen?: boolean;
}

const FileUploadRow: FC<FileUploadRowProps> = ({
  year,
  quarter,
  rebalancingOption,
  workEnvironment,
  dependenciesMissing,
  onFileValidStatusChange,
  scoreFile,
  isRootFile = false,
  isFrozen= true,
}) => {
  const [templateDownloadURL, setTemplateDownloadURL] = useState<string>();
  // Create a firebase reference
  const storage = getStorage();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [fileState, setFileState] = useState<FileUploadStatus>();
  const { user } = useContext(AuthCtx);
  const [uploadedTimeAgo, setUploadedTimeAgo] = useState<string>();

  const handleFileSelected = async (event: {
    target: { files: FileList | null; value: string };
  }) => {
    const { files } = event.target;

    if (files && files.length === 1) {
      const file = files[0];
      setSelectedFile(file);
      // if (onFileSelected) {
      // onFileSelected(file);
      // }
    }
    event.target.value = "";
  };

  useEffect(() => {
    //  Upload file to Firebase
    if (selectedFile && user) {
      const filename = scoreFile.fileNameBuilder(year, quarter);
      const path = isRootFile
        ? `AUTO100/raw_files/${filename}`
        : `AUTO100/raw_files/${rebalancingOption}/${year}_Q${quarter}/${filename}`;
      const uploadRef = ref(storage, path);
      try {
        uploadBytes(uploadRef, selectedFile, {
          customMetadata: {
            uid: user.uid,
          },
        })
          .then(async () => {
            setSelectedFile(undefined);

            //  Update Validation Status in Firebase Database
            await setFileState({
              validation_status: "VALIDATION_IN_PROGRESS",
              uploaded: false,
              uploadTimestamp: new Date().getTime(),
              valid: isRootFile || undefined,
            });
            setUploadedTimeAgo("Uploading...");
            // validateFile({
            //   year: !isRootFile ? year : undefined,
            //   quarter: !isRootFile ? quarter : undefined,
            //   work_environment: workEnvironment,
            //   filename,
            // });
          })
          .catch((e) => {
            console.error(e);
            // addNotification({
            //   id: `FileUploadError-${filename}`,
            //   type: 'error',
            //   message: `Unknown error occurred while uploading ${filename}.!`,
            // });
          });

        // //  if production environment. Upload to  playground as well
        // if (workEnvironment === WorkEnvironment.Production) {
        //   const path = isRootFile
        //     ? `AUTO100/${WorkEnvironment.Playground}/${filename}`
        //     : `AUTO100/${WorkEnvironment.Playground}/${year}_Q${quarter}/raw/${filename}`;

        //   const uploadRef = ref(storage, path);

        //   uploadBytes(uploadRef, selectedFile, {
        //     customMetadata: { uid: user.uid },
        //   })
        //     .then(async () => {})
        //     .catch((e) => {
        //       // addNotification({
        //       //   id: `FileUploadError-${filename}`,
        //       //   type: 'error',
        //       //   message: `Unknown error occurred while uploading ${filename}.!`,
        //       // });
        //     });
        // }
      } catch (error) {}
    }
  }, [
    selectedFile,
    workEnvironment,
    isRootFile,
    year,
    quarter,
    scoreFile,
    storage,
    user,
  ]);

  const onTargetClick = async () => {
    if (fileInputRef.current) {
      (fileInputRef.current as any).click();
    }
  };

  //   await set(ref(database, `${auth.currentUser?.uid}/${month}`), matrixItems);
  // };

  // const loadFileState = useCallback(
  //   async function () {
  //     const filename = scoreFile.fileNameBuilder(year, quarter);
  //     const path = `AUTO100/${year}_Q${quarter}/raw/${filename.split('.')[0]}`;
  //     const snapshot = await get(dbRef(database, path));
  //     if (snapshot.exists()) {
  //       const items = snapshot.val();
  //       const status = items as FileUploadStatus;
  //       if (status) {
  //         setFileState(status);
  //         if (onFileValidStatusChange && status.valid !== undefined) {
  //           onFileValidStatusChange(status.valid);
  //         }
  //       } else {
  //         if (onFileValidStatusChange) {
  //           onFileValidStatusChange(false);
  //         }
  //       }
  //     } else {
  //       setFileState(undefined);
  //     }
  //   },
  //   [year, quarter, scoreFile, onFileValidStatusChange],
  // );

  const handleFileValidStatusChange = useCallback(
    (isValid: boolean) => {
      if (onFileValidStatusChange) {
        onFileValidStatusChange(scoreFile, isValid);
      }
    },
    [scoreFile, onFileValidStatusChange]
  );

  useEffect(() => {
    if (year && quarter) {
      const filename = scoreFile.fileNameBuilder(year, quarter);
      const path = isRootFile
        ? `AUTO100/raw_files/${filename.split(".")[0]}`
        : `AUTO100/raw_files/${rebalancingOption}/${year}_Q${quarter}/${filename.split(".")[0]}`;
      // loadFileState();
      const ref = dbRef(database, path);
      const unsubscribe = onValue(ref, (snapshot) => {
        const data = snapshot.val();
        const status = data as FileUploadStatus;
        if (status) {
          setFileState(status);
          if (status.valid !== undefined) {
            handleFileValidStatusChange(status.valid);
          }
        } else {
          setFileState(undefined);
          setUploadedTimeAgo(undefined);
          handleFileValidStatusChange(false);
        }
      });
      return unsubscribe;
    }
  }, [
    year,
    quarter,
    rebalancingOption,
    workEnvironment,
    scoreFile,
    isRootFile,
    handleFileValidStatusChange,
  ]);

  useEffect(() => {
    if (fileState && fileState.uploaded === true) {
      const uploadedTime = moment(new Date(fileState.uploadTimestamp));
      setUploadedTimeAgo(`${uploadedTime.format("MMM Do, YYYY")}`);
      if (
        moment.duration(moment(new Date()).diff(uploadedTime)).asHours() < 6
      ) {
        setUploadedTimeAgo(`${uploadedTime.fromNow()}`);
        const interval = setInterval(() => {
          setUploadedTimeAgo(`${uploadedTime.fromNow()}`);
        }, 60000);
        return () => clearInterval(interval);
      }
    }
  }, [fileState]);

  const handleDownloadTemplate = async () => {
    const _downloadName = "";
    if (templateDownloadURL) {
      const link = document.createElement("a");
      link.download = _downloadName;
      link.href = templateDownloadURL;
      link.click();
    }
  };

  useEffect(() => {
    if (scoreFile) {
      const fetchDownloadURL = async () => {
        try {
          const downloadURL = await getDownloadURL(
            ref(
              storage,
              `AUTO100/templates/${year}_q${quarter}_universe_isin.xlsx`
            )
          );
          setTemplateDownloadURL(downloadURL);
        } catch (error) {
          setTemplateDownloadURL(undefined);
        }
      };
      fetchDownloadURL();
    }
  }, [scoreFile, workEnvironment, year, quarter, storage]);

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row">
        <Stack>
          <Typography style={{ fontSize: 12 }}>
            {scoreFile.friendlyName ?? scoreFile.displayName}
          </Typography>
          <Typography sx={{ fontSize: 10, fontWeight: 100, mb: 1 }}>
            {scoreFile.friendlyName ? scoreFile.displayName : null}
          </Typography>
          <Box
            component={"span"}
            sx={{
              color: "#999",
              fontSize: "0.6rem",
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={handleDownloadTemplate}
          >
            Download Template
          </Box>
        </Stack>
      </StyledTableCell>

      <StyledTableCell align="center" width={"100px"}>
        <Stack>
          <Typography sx={{ fontSize: "0.6rem" }}>Uploaded on:</Typography>
          <Tooltip
            title={"Uploaded by: " + (fileState?.uploadedBy || "unknown")}
          >
            <Typography sx={{ fontSize: "0.6rem" }}>
              {uploadedTimeAgo || "N/A"}
            </Typography>
          </Tooltip>
        </Stack>
      </StyledTableCell>
      {/* <Interval delay={60000}>
		  {() => (
			<StyledTableCell align="right">
			  {fileState && fileState.uploaded === true
				? 'Uploaded ' + moment(new Date(fileState.uploadTimestamp)).fromNow()
				: 'Not uploaded yet'}
			</StyledTableCell>
		  )}
		</Interval> */}
      <StyledTableCell align="center" width={"50px"}>
        {fileState?.validation_status === "UPLOAD_IN_PROGRESS" ? (
          "Uploading..."
        ) : fileState?.validation_status === "VALIDATION_IN_PROGRESS" ||
          fileState?.validation_status === "VALIDATION_PENDING" ? (
          <HourglassBottomIcon sx={{ color: "#FFBF00" }} />
        ) : fileState?.validation_status === "VALIDATION_COMPLETE" ? (
          fileState.valid ? (
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
          ) : (
            <Tooltip
              title={fileState.validation_error || "Error message missing"}
            >
              <DangerousIcon sx={{ color: "crimson" }} />
            </Tooltip>
          )
        ) : (
          "N/A"
        )}
      </StyledTableCell>

      <StyledTableCell align="center" width={"16px"}>
        {isFrozen ? (
          <Tooltip title={"The quarter has been frozen."}>
            <UploadIcon sx={{ color: "#CCC" }} />
          </Tooltip>
        ) : (dependenciesMissing ? (
          <Tooltip title={"Upload file dependencies first."}>
            <UploadIcon sx={{ color: "#CCC" }} />
          </Tooltip>
        ) : (
          <IconButton
            disabled={
              isFrozen ||
              (fileState &&
              fileState?.validation_status !== "VALIDATION_COMPLETE")
            }
            sx={{ p: "2px" }}
            onClick={onTargetClick}
          >
            <UploadIcon />
          </IconButton>
        ))}
        <input
          style={{ display: "none" }}
          onChange={handleFileSelected}
          ref={fileInputRef}
          type="file"
          className="hidden"
        />
      </StyledTableCell>
    </StyledTableRow>
  );
};

interface CustomizedTablesProps {
  year: number;
  quarter: number;
  rebalancingOption: RebalancingOption
  workEnvironment: WorkEnvironment;
  scoreFiles: ScoreFile[];
  onFileValidStatusChange?: (scoreFile: ScoreFile, isValid: boolean) => void;
  availableFiles: string[];
  isFrozen?: boolean;
}

const CustomizedTables: FC<CustomizedTablesProps> = ({
  year,
  quarter,
  rebalancingOption,
  workEnvironment,
  scoreFiles,
  onFileValidStatusChange,
  availableFiles,
  isFrozen=false
}) => {
  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>File Name</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Valid</StyledTableCell>
              <StyledTableCell align="center">Upload</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scoreFiles.map((scoreFile) => {
              let _dependenciesMissing = false;

              if (scoreFile.dependencies.length > 0) {
                for (const dependency of scoreFile.dependencies) {
                  if (availableFiles.indexOf(dependency) === -1) {
                    _dependenciesMissing = true;
                    break;
                  }
                }
              }

              return (
                <FileUploadRow
                  key={`Score-File-${scoreFile.id}`}
                  year={year}
                  quarter={quarter}
                  workEnvironment={workEnvironment}
                  rebalancingOption={rebalancingOption}
                  scoreFile={scoreFile}
                  onFileValidStatusChange={onFileValidStatusChange}
                  dependenciesMissing={_dependenciesMissing}
                  isRootFile={scoreFile.isRootFile}
                  isFrozen={isFrozen}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default CustomizedTables;
