const colors = {
    neutrals: {
        extraLightGray: '#F1F5F9',
        lightGray: '#D8DEE6',
        defaultGray: '#64758B',
        extraDarkGray: '#020617',
    },
    sentiment: {
        defaultRed: '#F73D48'
    },
    primaryWhite: '#fff',
    blue: '#0091FF'
}

export default colors