const CompaniesSVG = ({fill="none"}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4999 15.8333H19.1666V17.5H0.833252V15.8333H2.49992V3.33333C2.49992 2.8731 2.87302 2.5 3.33325 2.5H11.6666C12.1268 2.5 12.4999 2.8731 12.4999 3.33333V15.8333H14.1666V7.5H16.6666C17.1268 7.5 17.4999 7.8731 17.4999 8.33333V15.8333ZM5.83325 9.16667V10.8333H9.16659V9.16667H5.83325ZM5.83325 5.83333V7.5H9.16659V5.83333H5.83325Z" fill={`${fill}`}/>
        </svg>

    )
}

CompaniesSVG.defaultProps = {
    fill: "none"
}

export default CompaniesSVG