import { IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

// CUSTOM
import { AUTO100Ctx, WorkEnvironment } from "../../AUTO100DataProvider";
import AnimatedText from "../../components/Animations/AnimatedText";
import AnimatedBackground from "../../components/Animations/AnimatedBackground";
import MenuButton from "../../components/MenuButton";

export default function AUTO100HomePage() {
  const navigate = useNavigate();

  const { permissions } = useContext(AUTO100Ctx);

  //  HANDLE
  const navigateToHome = () => {
    navigate(`/`);
    // else if (permissions && permissions[path]) {
    //   navigate(`/auto100/${path}`);
    // }
  };
  const handleMenuItemClick = (path: string) => {
    navigate(`/playground/${path}`);
    // else if (permissions && permissions[path]) {
    //   navigate(`/auto100/${path}`);
    // }
  };

  if (!permissions) {
    return <>Loading</>;
  }

  return (
    <Stack sx={{ height: "100%" }} p={0} direction={"row"}>
      <AnimatedBackground />
      <Stack
        justifyContent={"center"}
        alignItems={"flex-start"}
        sx={{
          display: "flex",
          flex: 3,
          p: 10,
          backgroundImage: `linear-gradient(90deg, rgba(11, 41, 64, 0.3), rgba(11, 41, 64, 1)), url("https://www.berylls.com/wp-content/uploads/2023/03/parallax_car-2.jpg")`,
          backgroundSize: "cover",
        }}
      >
        <IconButton
          sx={{
            color: "#fff",
            position: "absolute",
            left: 30,
            top: 30,
            fontSize: 150,
            "& .MuiSvgIcon-root": { fontSize: "50px" },
          }}
          onClick={navigateToHome}
        >
          <HomeIcon color="inherit" fontSize="large" />
        </IconButton>
        <AnimatedText from={-2000} to={0} orientation="X">
          <Typography
            sx={{
              fontSize: "80px",
              color: "#f1f1f1",
              fontWeight: 900,
              fontFamily: "Rajdhani",
            }}
          >
            Welcome to AUTO100
          </Typography>
        </AnimatedText>

        <AnimatedText from={2000} to={0} orientation="X">
          <Typography
            sx={{
              fontSize: "30px",
              color: "#f1f1f1",
              fontWeight: 500,
              fontFamily: "Rajdhani",
            }}
          >
            The BDV Automobility Index (AMX), a real-time picture of the
            automobility supply chain across all core global regions and already
            an industry benchmark (find out more about AMX here).
          </Typography>
        </AnimatedText>
      </Stack>
      <Stack
        sx={{ display: "flex", flex: 2 }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Stack flex={4} justifyContent={"end"}>
          <MenuButton
            isDisabled={!permissions[WorkEnvironment.Playground]}
            subText={
              !permissions[WorkEnvironment.Playground]
                ? "PERMISSION REQUIRED"
                : undefined
            }
            text={"Company Deepdive"}
            onClick={() => handleMenuItemClick("company-deepdive")}
            buttonSx={{
              px: 0,
              py: 2,
              "& .MuiButton-endIcon svg": {
                fontSize: "30px",
              },
            }}
            buttonTextVariant="h5"
          />
          <MenuButton
            isDisabled={!permissions[WorkEnvironment.Production]}
            subText={
              !permissions[WorkEnvironment.Production]
                ? "PERMISSION REQUIRED"
                : undefined
            }
            text={"Universe Constituents"}
            onClick={() => handleMenuItemClick("universe-constituents")}
            buttonSx={{
              px: 0,
              py: 2,
              "& .MuiButton-endIcon svg": {
                fontSize: "30px",
              },
            }}
            buttonTextVariant="h5"
          />
          <MenuButton
            text="Stock Performance Comparison"
            onClick={()=> handleMenuItemClick("stock-performance-comparison")}
            buttonSx={{
              px: 0,
              py: 2,
              "& .MuiButton-endIcon svg": {
                fontSize: "30px",
              },
            }}
            buttonTextVariant="h5"
          />
          <MenuButton
            isDisabled
            text="Sentiment Sensor"
            onClick={function (): void {
              throw new Error("Function not implemented.");
            }}
            buttonSx={{
              px: 0,
              py: 2,
              "& .MuiButton-endIcon svg": {
                fontSize: "30px",
              },
            }}
            containerSx={{ mb: 0 }}
            buttonTextVariant="h5"
          />
          <Typography
            textAlign={"center"}
            sx={{ color: "#fff", fontSize: 12, mb: 9, fontWeight: 900 }}
          >
            COMING SOON
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
