import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

// FIREBASE
// Importing the firebase module will initialize the firebase app client
import "./Firebase";

// MUI
import theme from "./themes";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

// Context
import { AuthProvider } from "./AuthProvider";
import { UIProvider } from "./UIProvider";

// IMPORT PAGES
import SecureLayout from "./SecureLayout";
import SignInPage from "./pages/General/SignInPage";
import NotFoundPage from "./pages/General/NotFoundPage";
import Production from "./pages/Production/Index";
import Playground from "./pages/Playground/Index";
import PermissionManager from "./components/PermissionManager";
import Auto100HomePage from "./pages/Home/Auto100HomePage";
import { AUTO100DataProvider } from "./AUTO100DataProvider";
import CompanyDeepdive from "./pages/Playground/CompanyDeepdive";
import UniverseConstituents from "./pages/Playground/UniverseConstituents";

// STYLES
import "./themes/fonts.css";
import StockPerformanceComparison from "pages/Playground/StockPerformanceComparison/Index";

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <UIProvider>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<SignInPage />} />

              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                        AUTO100 PAGE ROUTE                      //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              <Route
                path="/"
                element={
                  <SecureLayout
                    title="AUTO100"
                    permissionRequired
                    permissionName="AUTO100"
                  >
                    <AUTO100DataProvider>
                      <Auto100HomePage />
                    </AUTO100DataProvider>
                  </SecureLayout>
                }
              />

              <Route
                path="/manage-permissions"
                element={
                  <SecureLayout
                    title="AUTO100"
                    permissionRequired
                    permissionName="AUTO100"
                  >
                    <AUTO100DataProvider>
                      <PermissionManager />
                    </AUTO100DataProvider>
                  </SecureLayout>
                }
              />

              <Route
                path="/playground"
                element={
                  <SecureLayout
                    title="AUTO100"
                    permissionRequired
                    permissionName="AUTO100"
                  >
                    <AUTO100DataProvider>
                      <Playground />
                    </AUTO100DataProvider>
                  </SecureLayout>
                }
              />
              <Route
                path="/playground/company-deepdive"
                element={
                  <SecureLayout
                    title="AUTO100"
                    permissionRequired
                    permissionName="AUTO100"
                  >
                    <AUTO100DataProvider>
                      <CompanyDeepdive />
                    </AUTO100DataProvider>
                  </SecureLayout> 
                }
              />
              <Route
                path="/playground/stock-performance-comparison"
                element={
                  <SecureLayout
                    title="AUTO100"
                    permissionRequired
                    permissionName="AUTO100"
                  >
                    <AUTO100DataProvider>
                      <StockPerformanceComparison />
                    </AUTO100DataProvider>
                  </SecureLayout>
                }
              />
              <Route
                path="/playground/universe-constituents"
                element={
                  <SecureLayout
                    title="AUTO100"
                    permissionRequired
                    permissionName="AUTO100"
                  >
                    <AUTO100DataProvider>
                      <UniverseConstituents />
                    </AUTO100DataProvider>
                  </SecureLayout>
                }
              />
              <Route
                path="/production"
                element={
                  <SecureLayout
                    title="AUTO100"
                    permissionRequired
                    permissionName="AUTO100"
                  >
                    <AUTO100DataProvider>
                      <Production />
                    </AUTO100DataProvider>
                  </SecureLayout>
                }
              />

              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                          NOT FOUND PAGE                        //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        </UIProvider>
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
