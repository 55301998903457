import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import colors from 'themes/colors';
import { ref as dbRef, set, getDatabase } from "firebase/database";

import { Badge, Button, Checkbox, FormControlLabel, InputAdornment, InputBase, OutlinedInput, Stack, TextField, alpha, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useContext, useMemo, useState } from 'react';
import { AUTO100Ctx, Company } from 'AUTO100DataProvider';
import { useLocation } from 'react-router-dom';
import { User } from 'firebase/auth';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));


const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  drawerWidth: number
  window?: () => Window;
  user?: User | null
}

const Sidebar = ({window, user, drawerWidth}: Props) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [filterText, setFilterText] = useState("");

  const location = useLocation();
  const db = getDatabase();

  const {
    analytics: {
      companies,
      userPreference: {
        stockPerformanceComparisonPreferences,
        setStockPerformanceComparisonPreferences,
        deepdivePreferences,
        setDeepdivePreferences,
      },
    } = {
      companies: [],
      userPreference: {
        stockPerformanceComparisonPreferences: {},
        setStockPerformanceComparisonPreferences: () => {},
        deepdivePreferences: {},
        setDeepdivePreferences: () => {}
      }
    },
  } = useContext(AUTO100Ctx);

  const currentPath = React.useMemo(() => {
    const { pathname } = location
    return pathname.split("/").pop()
  }, [location])

  const isDisabled = useMemo(() => {
    return currentPath === 'universe-constituents'
  }, [currentPath])


  const getSelectedCompanies = useMemo(() => {
    if( currentPath === 'company-deepdive' ){
      return (deepdivePreferences as {company: Array<Company>})?.company ?? []
    }
    if( currentPath === 'stock-performance-comparison' ){
      return (stockPerformanceComparisonPreferences as {company: Array<Company>})?.company ?? []
    }
    return []
  }, [deepdivePreferences, stockPerformanceComparisonPreferences, currentPath])

  const getCompaniesAsOptions = useMemo(() => {
    let _companies = [...companies]
    if (filterText) {
      _companies = _companies.filter((company) => {
        return Object.values(company).some((value) =>
          String(value).toLowerCase().includes(filterText.toLowerCase())
        );
      });
    }

    return _companies.map((company) => (
      {
        ...company,
        checked: !!getSelectedCompanies.find(selectedCompany => selectedCompany.ISIN === company.ISIN)
      }
    ))
  }, [companies, filterText, getSelectedCompanies])

  const handleCompaniesChange = async (company: Company, checked: boolean) => {
    let selectedCompanies = [...getSelectedCompanies]
    if( !checked ){
      selectedCompanies = selectedCompanies.filter(selectedCompany => selectedCompany.ISIN !== company.ISIN)
    }else {
      selectedCompanies =  [...selectedCompanies, company]
    }

    updateDatabase(selectedCompanies)
  };
  const updateDatabase = async (selectedCompanies: Array<Company>) => {
    if( !user ) return

    let dbPath = `AUTO100/user_preferences/${user.uid}/analytics/`
    if( currentPath === 'company-deepdive' ) dbPath += `deepdivePreference`
    if( currentPath === 'stock-performance-comparison' ) dbPath += `stockPerformanceComparisonPreferences`
    
    const userPreferenceRef = dbRef(db, `${dbPath}/company`);
    await set(userPreferenceRef, selectedCompanies);
    if( currentPath === 'company-deepdive' ){
      setDeepdivePreferences({
        ...(deepdivePreferences ?? {}),
        company: selectedCompanies,
      });
    }
    if( currentPath === 'stock-performance-comparison' ){
      setStockPerformanceComparisonPreferences({
        ...(stockPerformanceComparisonPreferences ?? {}),
        company: selectedCompanies,
      });
    }
  }

  const drawer = (
    <div style={{ display: "flex", flexDirection: "column", padding: 16, overflowY: isDisabled ? "hidden" : undefined}}>
      {isDisabled && <div style={{ background: "#fff", position: "absolute", left: 0, top: 0, width: "100%", height: "100%", zIndex: 100, opacity: 0.7 }}></div>}
      <Stack>
        <Stack flexDirection="row" justifyContent="space-between" py={2} alignItems="center">
          <Stack flexDirection="row" py={2} alignItems="center">
            <Typography sx={{
                color: "#020617",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "24px",
                mr: 2,
              }}>Companies</Typography>
            <Badge
              badgeContent={`${getSelectedCompanies.length}`}
              variant="standard"
              color="warning"
              sx={{
                '& .MuiBadge-badge': {
                  background: !getSelectedCompanies.length ? "#D8DEE6" : '#0091FF',
                  color: !getSelectedCompanies.length ? "#64758B" : '#FFFFFF',
                  fontSize: "13px",
                  fontWeight: 700
                },
              }}
            />
          </Stack>
          <Button sx={{
            width: "43px",
            height: "40px",
            background: "#fff",
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
            borderRadius: "10px",
            zIndex: 2,
            textDecoration: "none",
            fontWeight: "600",
        }} onClick={() => updateDatabase([])} variant="contained" color="inherit">Clear</Button>
        </Stack>

        <Divider />

        <Stack flexDirection="row" justifyContent="space-between" my={2} alignItems="center">
          
          <TextField
            fullWidth
            placeholder='Search'
            onChange={(e) => setFilterText(e.target.value)}
            id="outlined-start-adornment"
            InputLabelProps={{
              style: { color: '#fff', }, 
            }}
            InputProps={{
              style: {
                paddingTop: 0,
              },
              disableUnderline: true,
              startAdornment: <InputAdornment id="jsd" 
              sx={{ 
                adornedStart: {
                  margin: "10px !important",
                }
              }} position="start"
              disableTypography
              disablePointerEvents
            >
                <SearchIcon />
              </InputAdornment>,
            }}
            sx={{
                "& .MuiFilledInput-root": {
                  background: "#fff",
                  borderRadius: "10px",
                  border: "1px solid #F1F5F9",
                  '& fieldset': {
                    paddingTop: 0,
                  },            
                },
                "& .MuiFilledInput-input": {
                  padding: 2,
                  pl: "4px",
                  color: "#64758B",
                  fontWeight: 600,
                },
                "& .MuiInputAdornment-root": {
                  margin: "0px !important",
                },
                borderBottom: 0
            }}
            variant="filled"
          />
        </Stack>

        <Divider />

      </Stack>
      {(getCompaniesAsOptions).map((company) => (
          <FormControlLabel
            sx={{ 
                background: "#F1F5F9",
                fontSize: "600",
                borderRadius: "8px",
                position: "relative",
                left: 10,
                width: "100%",
                mt: 1,
            }}
            onChange={() => handleCompaniesChange(company, !company.checked)}
            control={<Checkbox checked={company.checked} />}
            label={<Typography sx={{ fontSize: "14px", fontWeight: "600" }} variant="body2">{company.Name}</Typography>}
        />
      ))}
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Drawer
        
        variant="permanent"
        sx={{
            backgroundColor: "red",
        display: { xs: 'none', sm: 'block' },
        '& .MuiDrawer-paper': { 
            boxSizing: 'border-box',
            width: drawerWidth,
            background: "#F1F5F9",
            top: 64*2
        },
        }}
        open
    >
        {drawer}
    </Drawer>
  );
}

export default Sidebar